import { Component, NgZone } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
  selector: 'wlms-toggle-editor',
  template: `<mat-slide-toggle
  [(ngModel)]="mode"
  class="example-margin">
</mat-slide-toggle>`,
  styles: [`
    ::ng-deep.mat-slide-toggle-bar {
    background-color: #dadada !important;
}
::ng-deep.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(55 226 143 / 54%) !important;
}
::ng-deep.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #53f18f;
}
`]
})

export class ToggleEditorComponent implements AgEditorComponent {
  mode: boolean;

  agInit(params: any): void {
    this.mode = params.value;
  }

  getValue(): any {
    return this.mode;
  }
}
