import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public appConfig: any;
  constructor(private http: HttpClient) { }


  load(url: any) {
    return new Promise((resolve: any) => {
      this.http
        .get(url)
        .pipe(map((res) => res))
        .subscribe((config) => {
          this.appConfig = config;
          resolve();
        });
    });
  }

  getAppConfiguration(): any {
    return this.appConfig;
  }
}
