<div class="app-l-agrregate-panel">
  <div *ngIf="tileInfo.icon" class="image-width">
    <img [src]="tileInfo.icon" class="image-item" />
  </div>
  <div class="app-l-text-section">
    <label class="app-l-aggregate-header">{{ tileInfo?.label }}</label>
    <div class="app-l-aggregate-value">
      {{ tileInfo?.value | customCurrency }}
    </div>
  </div>
  <div class="app-l-seperator" *ngIf="tileInfo?.remainingPercentage">
    <span class="progress-span">{{tileInfo.remainingPercentage}}% Left</span>
    <div class="progress progress-bar-vertical">
      <div role="progressbar" [wlmsProgress]="tileInfo.remainingPercentage">
      </div>
    </div>
  </div>
</div>
