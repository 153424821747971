import { CurrencyFormatter, ConditionalEditor, PercentageFormatter } from '@wlms-web/utils';

export const WarehouseLineswetOverridesdetails = [
    {
        "field": "WarehouseLineWetOverridesId",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "WarehouseLineName",
        "headerName": "Warehouse Line",
        "filter": "agTextColumnFilter",
        "width": 150,
    },    
    {
        "field": "LineLimit",
        "headerName": "Line Limit",
        "width": 200,
        "filter": "agNumberColumnFilter",
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "OverrideTypeFirst",        
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "OverRideTypeNameFirst",
        "headerName": "Override Method (First 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 200,
    },
    {
        "field": "OverrideValueFirst",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true        
    },
    {
        "field": "OverridePercentageFirst",
        "headerName": "Override % (First 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "OverrideAmountFirst",
        "headerName": "Override $ (First 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "OverrideTypeMid",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "OverRideTypeNameMid",
        "headerName": "Override Method (Mid Month +/- 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 150,
    },
    {
        "field": "OverrideValueMid",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true        
    },
    {
        "field": "OverridePercentageMid",
        "headerName": "Override % (Mid Month +/- 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "OverrideAmountMid",
        "headerName": "Override $ (Mid Month +/- 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },    
    {
        "field": "OverrideTypeLast",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },   
    {
        "field": "OverRideTypeNameLast",
        "headerName": "Override Method (Last 5 BD)",
        "filter": "agTextColumnFilter",
        "width": 150,
    },
    {
        "field": "OverrideValueLast",
        "filter": "agNumberColumnFilter",
        hide: true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true    
    }, 
    {
        "field": "OverridePercentageLast",
        "headerName": "Override % (Last 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: PercentageFormatter.setPercentageFormat,
        cellStyle: { 'text-align': 'right' },
    },
    {
        "field": "OverrideAmountLast",
        "headerName": "Override $ (Last 5 BD)",
        "width": 200,
        "filter": "agNumberColumnFilter",        
        valueFormatter: CurrencyFormatter.setCurrencyFormat,
        cellStyle: { 'text-align': 'right' },
    },    
    {
        "field": "Comments",
        "headerName": "Comments",
        "filter": "agTextColumnFilter",
        "width": 350,
        tooltipField: 'Comments'
    },
    {
        "field": "action",
        "headerName": "",
        "width": 90,
        "active": true,
        "cellRenderer": "actionColumnRendererComponent",
        "resizable": false,
        "pinned": "right",
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressRowClickSelection": true,
        "lockPinned": true,
        showRowGroup: true,
        "suppressColumnsToolPanel": true,
        "sortable": false,
        "suppressMenu": true
    }
]
