import { Injectable } from '@angular/core';
import { HttpClient,   HttpHeaders } from '@angular/common/http';
import { ConfigService, ApiConfig } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';
import { ApiResponse } from '../loan-assignment/models/ApiResponse';
import { WarehouseLine } from '../loan-assignment/models/WarehouseLine';
@Injectable({
  providedIn: 'root'
})
export class LoanAssignmentService extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  getLoans(los, testMode) {
    return this.get('@fundingService/LoansReadyForFunding?LoanOriginationSystems=' + los + "&TestMode=" + testMode, ApiConfig.DISABLELOANCACHE);
  }
  
  assignLoan(request) {
    return this.put('@fundingService/LoanWarehouseAssignment', request);
  }

  pushBackToFundingQueue(request: any) {
    return this.put('@fundingService/Funding/LoansToPushBack', request);
  }

  markReviewLoan(request: any) {
    return this.put('@fundingService/LoanReviewed', request);
  }
  
  updateLoanException(request: any) {
    return this.put('@fundingService/LoanException', request);
  }
  
  searchLoan(loanNumber: string, los, testMode) {
    return this.get('@fundingService/LoanLookup?LoanOriginationSystem=' + los + '&LoanNumber=' + loanNumber + '&TestMode=' + testMode, ApiConfig.DISABLESEARCHLOANCACHE);
  }
  
  updateAdvanceDate(request: any) {
    return this.put('@fundingService/LoanAdvanceDate', request);
  }

  assignAndExportLoan(request) {
    return this.post('@fundingService/ExportLoansForFunding', request);
  }
 
  reprocessLoanUpdates(request) {
    return this.put('@fundingService/ReprocessFailedLoans', request);
  }

  getWarehouseline(los) {
    return this.get<ApiResponse<WarehouseLine[]>>('@warehouselineServiceUrl/WarehouseLineDetailForPipeline?LoanOriginationSystems=' + los, ApiConfig.DISABLEGETTIMECACHE);
  }

  getColumnMaster(gridName){
    return this.get('@userServiceUrl/ColumnsForGridPreferences?GridName=' + gridName);
  }

  saveUserPreferences(request){
    return this.post('@userServiceUrl/UserPreferencesForGrid', request);
  }

  getUserPreferences(userName, gridName){
    return this.get('@userServiceUrl/UserPreferencesForGrid?UserName='+ userName + '&GridName=' + gridName, ApiConfig.DISABLELOANCACHE);
  }

  ResetUserPreference(request){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    
    return this.delete('@userServiceUrl/UserPreferencesForGrid', options);
  }

  getPstCurrentDate() {
    return this.get('AppCommon/CurrentPstDateTime', ApiConfig.DISABLEGETTIMECACHE);
  }

  getAggregates() {
    return this.get('StaticFiles/Json/getAggregates.json');
  }
}
