<div class="app-l-panel-tab-content">
  <div class="app-l-panel-tab-header d-flex justify-content-between">
    <h4 class="app-l-panel-heading">Details</h4>
  </div>
  <div class="app-l-card-group app-l-card-scroll">
    <div
      class="app-l-data-sections"
      *ngFor="let category of loanDetailCategories"
    >
      <h4 class="app-l-card-group-header">{{ category.name }}</h4>
      <div class="app-l-data-section">
        <div class="app-l-data-entry" *ngFor="let loan of loanDetails">
          <div
            class="app-l-data-content"
            *ngIf="
              loan.headerName && loan.detailCategory == category.categoryId
            "
          >
            <label class="app-l-data-label"> {{ loan.headerName }} </label>
            <p class="app-l-data-value">
              {{ loan?.detailValue | empty: '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
