<div class="app-l-header">
  <div class="app-l-header__top">
    <div class="app-l-side-menu-top">
      <div class="app-c-side-menu-icon-wrapper">
        <label class="app-c-sidemenu-logo" title="W L M S">$</label>
        <label class="app-c-label app-c-menu-item-label app-c-logo-text">W L M S</label>
        <!-- <img class="app-c-sidemenu-logo1" src="../assets/images/WarehouseLineManagementSystem.svg" alt="" /> -->
      </div>

      <div *ngIf="authUtils.doesUserAllowedToPerform(screenActionPrivileges.LoanAssignment)"
        class="app-c-side-menu-icon-wrapper" (click)="loadPage('loanAssignment')">
        <img class="app-c-btn--icon-14px" src="../assets/images/icons_loanassignment.svg" title="Loan Assignment" />
        <label class="app-c-label app-c-menu-item-label">Loan Assignment</label>
      </div>

      <div *ngIf="authUtils.doesUserAllowedToPerform(screenActionPrivileges.ExportHistory)"
        class="app-c-side-menu-icon-wrapper" (click)="loadPage('exporthistory')">
        <img class="app-c-btn--icon-14px" src="../assets/images/history.png" title="Export History" />
        <label class="app-c-label app-c-menu-item-label">Export History</label>
      </div>

      <div *ngIf="authUtils.doesUserAllowedToPerform(screenActionPrivileges.Reports)"
        class="app-c-side-menu-icon-wrapper" (click)="loadPage('reports')">
        <img class="app-c-btn--icon-14px" src="../assets/images/reports_icon.png" title="Reports" />
        <label class="app-c-label app-c-menu-item-label">Reports</label>
      </div>

      <div *ngIf="authUtils.doesUserAllowedToPerform(screenActionPrivileges.Warehouseline)"
        class="app-c-side-menu-icon-wrapper" (click)="loadPage('warehouseLineList')">
        <img class="app-c-btn--icon-14px" src="../assets/images/icons_warehouseline.svg" title="Warehouse Lines" />
        <label class="app-c-label app-c-menu-item-label">Warehouse Lines</label>
      </div>

    </div>

    <div class="app-l-side-menu-bottom">
      <div class="app-c-side-menu-icon-wrapper">
        <img class="app-c-usericon" src="../assets/images/usericon.svg" alt="" [matMenuTriggerFor]="menu"
          title="{{ userDetails?.name }}" />
        <label [matMenuTriggerFor]="menu" class="app-l-header__user nav-item dropdown app-c-label"
          style="padding-left: 20px">{{ userDetails?.name }}</label>

        <mat-menu #menu="matMenu" class="app-l-head-menu">
          <div class="app-l-menu-seperator"></div>
          <button mat-menu-item class="app-l-user-info-wrap" (click)="authUtils.signOut()">
            <div class="app-l-user-avatar">
              <div class="app-l-username">
                <h3>Sign Out</h3>
              </div>
            </div>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>