import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wlms-warehouseline-dropdown',
  templateUrl: './warehouseline-dropdown.component.html',
  styleUrls: ['./warehouseline-dropdown.component.scss']
})
export class WarehouselineDropdownComponent implements OnInit {

  @Input() buttonGroup: any = [];
  @Input() disableButton: any;
  @Input() defaultButton: any = null;
  @Input() buttonLabel: any ;
  @Input() containerClass: any = 'body';
  @Input() changeButtonLabel = false;
  @Input() disableDropdown = false;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onOptionSelect(button: any) {
    this.optionSelected.emit(button);
  }
}
