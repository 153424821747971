import { Component, Input, OnInit } from '@angular/core';
import { ExportFilePathResult, ExportResult } from '../../config/table-structure-csv-generator-result';
@Component({
  selector: 'csv-generator-result-modal',
  templateUrl: './csv-generator-result-modal.component.html',
  styleUrls: ['./csv-generator-result-modal.component.scss']
})
export class CsvGeneratorResultModalComponent implements OnInit {
  @Input() data: any;
  columnDefs = [...ExportResult];
  columnDefsFilePath = [...ExportFilePathResult];
  rowData = [];
  rowDataFilePath = []
  constructor() { }
  ngOnInit(): void {
    this.getExportStatus();
  }
  getExportStatus() {
    let response = this.data.data.map(x => x.Response)[0];
    response.forEach((element, index) => {
      if (element.Status) {
        response[index].Status = "Success";
      }
      else {
        response[index].Status = "Failed";
      }
    });

    this.rowData = response.sort(function (a, b) {
      return (a.Status.toUpperCase() < b.Status.toUpperCase()) ? -1 : (a.Status.toUpperCase() > b.Status.toUpperCase()) ? 1 : 0;
    });

    this.rowDataFilePath = this.data.data.map(obj =>
    ({
      WarehouseLine: this.data.WarehouseLine,
      Path: obj.Path,
      AdditionalPath: obj.AdditionalPath ?? ""
    }));
  }
}
