<wlms-web-sliding-menu></wlms-web-sliding-menu>
<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-100">
  <div class="app-l-header__bottom">
    <div class="app-l-header__inner-wrap app-l-container">
      <div class="app-l-header__left">
        <h3><span class="app-c-label-name">Warehouse Lines</span></h3>
      </div>
    </div>
  </div>

  <div class="app-c-tab-btn-refresh">
    <div *ngIf="tabList[selectedTab].code != 'timeAvailability'">
      <a
        *wlmsIfPrivilege="[screenActionPrivileges.WarehouselineSavePreference]"
        class="nounderscore"
        title="Save Preference"
        (click)="showUserPreferenceModal()"
        [class.disabled]="!sharedService.userPreferenceChanged"
      >
        <i class="icon-save header-icon"></i>
      </a>
      <a
      class="nounderscore"
      *wlmsIfPrivilege="[screenActionPrivileges.WarehouselineSavePreference]"
      title="Reset Preference"
      [class.disabled]="!(sharedService.userPreferenceChanged || sharedService.disableRestButton)"
      (click)="showResetUserPreferenceModal()"
    >
      <i class="icon-reset header-icon"></i>
    </a>
    </div>


  
    <div *ngIf="tabList[this.selectedTab].code == 'timeAvailability'">
      <a
        *wlmsIfPrivilege="[screenActionPrivileges.EditWarehouseline]"
        class="nounderscore"
        title="Update Warehouse Lines"
        (click)="updateWarehouses()"
      >
        <i class="icon-save_icon header-icon f-16"></i>
      </a>
    </div>
  </div>

  <div class="app-l-container vertical-stack app-l-grid-action" >
    <div *wlmsIfPrivilege="[screenActionPrivileges.WarehouselineTile]">
    <div class="d-flex flex-wrap" *ngIf="tileList?.length > 0" >
      <ng-container *ngFor="let tileInfo of tileList">
        <wlms-loan-info-tile
          class="col-xl-2 col-md-4 col-sm-6 mb-2 p-1"
          [tileInfo]="tileInfo"
        >
        </wlms-loan-info-tile>
      </ng-container>
    </div>
    </div>

    <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
      <wlms-tab-set
        [isLabelDisplayed]="true"
        [tabList]="tabList"
      ></wlms-tab-set>

      <wlms-warehouselines
        class="tab-content"
        #warehouseTab
        *ngIf="tabList[this.selectedTab].code == 'warehouses'"
        [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege"
        (warehouseReloaded)="setCapacityTile($event)"
      ></wlms-warehouselines>

      <wlms-grid
        *ngIf="tabList[this.selectedTab].code == 'timeAvailability'"
        #mainGrid
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege"
        [noRowsOverlayFlags]="noRowsOverlayFlags"
        [paginationPageSize]="paginationPageSize"
        [editType]="editType"
        [selectedIndices]="selectedIndices"
        (cellEditStarted)="onCellEditStarted($event)"
        (cellEditStopped)="onCellEditStopped($event)"
        (delete)="delete($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (noOverlay)="noOverlay($event)"
        [suppressFilter]="true"
      >
      </wlms-grid>

      <wlms-daily-caps
        class="tab-content"
        #dailyCapTab
        *ngIf="tabList[this.selectedTab].code == 'dailycapAvailability'"
        [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege"
        (warehouseReloaded)="setCapacityTile($event)"
      ></wlms-daily-caps>

      <wlms-wet-overrides
        class="tab-content"
        #wetOverrideTab
        *ngIf="tabList[this.selectedTab].code == 'wetOverrides'"
        [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege"
        (warehouseReloaded)="setCapacityTile($event)"
      ></wlms-wet-overrides>

      <div
        class="app-c-grid-add-column"
        *ngIf="
          tabList[this.selectedTab].code == 'timeAvailability' &&
          authUtils.doesUserAllowedToPerform(
            screenActionPrivileges.EditTimeAvailabilityTab
          )
        "
      >
        <span title="Manage Time Slot" (click)="manageTimeSlotModal()">
          <a class="nounderscore">
            <i class="icon-settings font-weight-bold">
              <span class="app-c-blue-circle"></span>
            </i>
          </a>
        </span>
      </div>

      <div
        class="app-c-grid-add-column"
        *ngIf="
          authUtils.doesUserAllowedToPerform(screenActionPrivileges.EditWarehouseline) &&
          tabList[this.selectedTab].code == 'warehouses'
        "
      >
        <span title="Add" (click)="addWarehouse()">
          <a class="nounderscore">
            <i class="icon-plus font-weight-bold">
              <span class="app-c-blue-circle"></span>
            </i>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
