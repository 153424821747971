<div class="d-flex flex-align-row mt-2 mb-2 p-0 col-lg-4">
    <div style="width: 100%; min-height: 30px; min-width: 126px; width: 346px">
        <div>
            <label class="pr-2">Contracts</label>
            <div class="d-flex flex-column align-items-start">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Contracts</mat-label>
                    <mat-select [(ngModel)]="data.activeContract.WarehouseLineContractDetailId" name="type">
                        <mat-option *ngFor="let contract of contracts" (click)="contractChanged(contract)" [value]="contract.WarehouseLineContractDetailId">
                            {{ contract?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
       </div>
</div>

<div class="d-flex flex-align-row mt-2 mb-2 p-0 col-lg-4">
    <div style="width: 100%; min-height: 30px; min-width: 126px; width: 346px">
        <div>
            <label class="pr-2">Parent Tranches</label>
            <div class="d-flex flex-column align-items-start">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Parent Tranche</mat-label>
                    <mat-select [(ngModel)]="data.modalData.tranchId" name="type">
                        <mat-option *ngFor="let tranche of tranches" (click)="trancheChanged(tranche)" [value]="tranche.WarehouseLineTrancheId">
                            {{ tranche?.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
 </div>

</div>
<div class="d-flex flex-align-row mt-2 mb-2 p-0 col-lg-4">
    <div>
        <div style="width: 100%; min-height: 30px; min-width: 126px; width: 398px;">
            <label class="pr-2">Tranche Name</label>
            <input #input1 type="text" class="form-control" [(ngModel)]="data.modalData.newTranche"
                (ngModelChange)="tranchNameChanged(data.modalData.newTranche)" />
        </div>
    </div>

</div>