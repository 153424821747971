import { Directive, ElementRef, HostListener, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[wlmsProgress]'
})
export class ProgressDirective implements AfterViewInit {
  @Input() wlmsProgress: string;
  private domElement: HTMLElement;
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.domElement = this.el.nativeElement as HTMLElement;
  }

  ngAfterViewInit() {
    this.setHeight(this.wlmsProgress);
  }
  private setHeight(height: string) {
    this.renderer.setStyle(this.domElement, "height", `${height}%`);
    var value = height ? Number(height) : 0;
    if (value >= 50) {
      this.domElement.classList.add('progress', 'progress-bar', 'progress-green');
    }
    else if (value >= 20 && value <= 50) {
      this.domElement.classList.add('progress', 'progress-bar', 'progress-yellow');
    }
    else {
      this.domElement.classList.add('progress', 'progress-bar', 'progress-red');
    }
  }
}
