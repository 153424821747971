<div class="app-c-time-slot-wrapper">
  <div class="d-flex justify-content-between flex-wrap align-items-center">
    <div class="d-flex flex-column align-items-start">
      <label class="pr-2">Start Date</label>
      <input
        #input1
        type="text"
        placeholder="mm/dd/yyyy"
        class="form-control bs-input"
        [bsConfig]="bsConfig"
        bsDatepicker
        [(ngModel)]="startDate"
        (ngModelChange)="dateChange('start', $event)"
        [daysDisabled]="[6,0]"
      />
    </div>

    <div class="d-flex flex-column align-items-start">
      <label class="pr-2">End Date</label>
      <input
        #input2
        type="text"
        placeholder="mm/dd/yyyy"
        class="form-control bs-input"
        [bsConfig]="bsConfig"
        bsDatepicker
        [(ngModel)]="endDate"
        (ngModelChange)="dateChange('end', $event)"
        [daysDisabled]="[6,0]"
      />
    </div>

    <div class="d-flex flex-column align-items-start">
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Cap Type</mat-label>
        <mat-select [(ngModel)]="data.dailyCapData.CapType" name="type">
          <mat-option *ngFor="let type of capType" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="d-flex flex-column align-items-start">
      <mat-form-field
        class="example-full-width"
        appearance="fill"
        class="form-field"
      >
        <mat-label>Amount</mat-label>
        <input
          matInput
          [(ngModel)]="data.dailyCapData.Value"
          (ngModelChange)="amountChanged($event)"
          (change)="amountValueChanged($event)"
          type="number"
        />
      </mat-form-field>
    </div>

    <div class="d-flex flex-column align-items-start">
      <mat-form-field
        class="mat-textarea whl-text-area form-field"
        appearance="fill"
      >
        <mat-label>Comments</mat-label>
        <textarea
          matInput
          class="comments-text-area"
          [(ngModel)]="data.dailyCapData.Comments"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
