import { Component, Input, OnInit } from '@angular/core';
import { PushBackResult } from '../../config/table-structure-pushback-result';


@Component({
  selector: 'wlms-pushfunding-result-modal',
  templateUrl: './pushfunding-result-modal.component.html',
  styleUrls: ['./pushfunding-result-modal.component.scss']
})
export class PushfundingResultModalComponent implements OnInit {

  @Input() data: any;
  columnDefs = [...PushBackResult];
  rowData = [];
  paginationPageSize = 10;
  constructor() { }

  ngOnInit(): void {
    let res = this.data.responseDetails;
    this.rowData = res;
  }

}
