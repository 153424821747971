export const TimeAvailability = [
  {
    'headerName': '',
    "field": "rowCheck",
    "checkboxSelection": true,
    "headerCheckboxSelection": true,
    "headerCheckboxSelectionFilteredOnly" : true,
    "active": true,
    "width": 40,
    cellStyle: { left: '0px' },
    "pinned": "left",
    suppressSizeToFit:true,
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressFiltersToolPanel": true,
    "suppressColumnsToolPanel": true
  },
  {
    "field": "WarehouseLineName",
    "headerName": "Warehouse Line",
    "filter": "agTextColumnFilter",
  },
  {
    "field": "",
    "headerName": "",
    colId: 'timeSlots',
    "filter": "agNumberColumnFilter",
    editable: false,
    cellEditor: 'numericEditor',
    hide:true,
    "suppressFiltersToolPanel": true,
    "suppressColumnsToolPanel": true
  },
]
