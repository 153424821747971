<div class="app-c-time-slot-wrapper">
    <div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
        <div class="d-flex flex-column align-items-start">
          <div class="d-flex flex-row">
            <label class="pr-2 font-weight-bold">Warehouse:</label
            >{{ data.wetOverridesData.WarehouseLineName }}
          </div>
        </div>
        
        <div class="d-flex flex-column align-items-start">
          <div class="d-flex flex-row">
            <label class="pr-2 font-weight-bold">Line Limit:</label>${{
              data.wetOverridesData.LineLimit
            }}
          </div>
        </div>
      </div>

    <div class="d-flex justify-content-between flex-wrap align-items-center">
        <div class="d-flex flex-column align-items-start">
            <div class="d-flex flex-column align-items-start">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Override Type (First 5 BD)</mat-label>
                    <mat-select [(ngModel)]="data.wetOverridesData.OverrideTypeFirst" name="type">
                        <mat-option *ngFor="let type of OverrideType" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex flex-column align-items-start">
            <mat-form-field class="example-full-width" appearance="fill" class="form-field">
                <mat-label *ngIf="!data.wetOverridesData.OverrideTypeFirst">Select Override Type (First 5 BD)</mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeFirst == 1" >Override % (First 5 BD)</mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeFirst == 2" (focus)="data.wetOverridesData.OverrideTypeFirst == 2">Override $ (First 5 BD)</mat-label>
                <input matInput [disabled] ="!data.wetOverridesData.OverrideTypeFirst" [(ngModel)]="data.wetOverridesData.OverrideValueFirst"
                    (ngModelChange)="amountChanged($event)" (change)="amountValueChanged($event)" type="number" />
            </mat-form-field>
        </div>

        <div class="d-flex flex-column align-items-start">
            <div class="d-flex flex-column align-items-start">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Override Method (Mid Month +/- 5 BD)</mat-label>
                    <mat-select [(ngModel)]="data.wetOverridesData.OverrideTypeMid" name="type">
                        <mat-option *ngFor="let type of OverrideType" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex flex-column align-items-start">
            <mat-form-field class="example-full-width" appearance="fill" class="form-field">
                <mat-label *ngIf="!data.wetOverridesData.OverrideTypeMid">Select Override Type (Mid Month +/- 5 BD)</mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeMid == 1">Override % (Mid Month +/- 5 BD)
                </mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeMid == 2">Override $ (Mid Month +/- 5 BD)
                </mat-label>
                <input matInput [disabled] ="!data.wetOverridesData.OverrideTypeMid" [(ngModel)]="data.wetOverridesData.OverrideValueMid"
                    (ngModelChange)="amountChanged($event)" (change)="amountValueChanged($event)" type="number" />
            </mat-form-field>
        </div>

        <div class="d-flex flex-column align-items-start">
            <div class="d-flex flex-column align-items-start">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Override Method (Last 5 BD)</mat-label>
                    <mat-select [(ngModel)]="data.wetOverridesData.OverrideTypeLast" name="type">
                        <mat-option *ngFor="let type of OverrideType" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex flex-column align-items-start">
            <mat-form-field class="example-full-width" appearance="fill" class="form-field">
                <mat-label *ngIf="!data.wetOverridesData.OverrideTypeLast">Select Override Type (Last 5 BD)</mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeLast == 1">Override % (Last 5 BD)</mat-label>
                <mat-label *ngIf="data.wetOverridesData.OverrideTypeLast == 2">Override $ (Last 5 BD)</mat-label>
                <input matInput [disabled] ="!data.wetOverridesData.OverrideTypeLast" [(ngModel)]="data.wetOverridesData.OverrideValueLast"
                    (ngModelChange)="amountChanged($event)" (change)="amountValueChanged($event)" type="number" />
            </mat-form-field>
        </div>

        <div class="d-flex flex-column align-items-start">
            <mat-form-field class="mat-textarea whl-text-area form-field" appearance="fill">
                <mat-label>Comments</mat-label>
                <textarea matInput class="comments-text-area" [(ngModel)]="data.wetOverridesData.Comments"></textarea>
            </mat-form-field>
        </div>
    </div>
</div>