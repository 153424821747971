<wlms-web-sliding-menu></wlms-web-sliding-menu>
<div class="app-l-layout-wrapper min-h-0 d-flex flex-column">
  <div class="app-l-header__detailscreen">
    <div class="app-l-header__inner-wrap app-l-container">
      <div (click)="goback()" class="goback">
        <i class="icon-arrow-left-single icon-color i-back"></i>
        <h4 class="app-l-page-action-text icon-color mb-0 h4-back wd-left">
          Back
        </h4>
      </div>
    </div>
    <div class="detail-header">
      <div class="loan-header">
        <div class="wd-left warehouseline-logo">
          <img class="warehouse-logo-img" [src]="warehouselinesrc" />
        </div>
        <div class="wd-left">
          <label class="warehouseline-header-text">{{
            warehouselinename
          }}</label>
        </div>
        <div class="wd-left">
          <label class="warehouseline-header-text heared-vertical-text"
            >|</label
          >
        </div>
        <div class="wd-left">
          <label class="warehouseline-header-text">{{
            warehouseLineDetail.contactperson
          }}</label>
        </div>
        <div class="wd-left app-l-banner-icon">
          <a
            href="#"
            popover="{{ warehouseLineDetail.phone | phonePipe }}"
            triggers="mouseenter:mouseleave"
          >
            <i class="icon-phone wh-header-icon"></i
          ></a>
        </div>
        <div class="app-l-banner-icon">
          <a
            href="#"
            popover="{{ warehouseLineDetail.email | emailPipe }}"
            triggers="mouseenter:mouseleave"
          >
            <i class="icon-mail wh-header-icon"></i
          ></a>
        </div>
      </div>
      <div class="loan-detail">
        <div class="ld-align wd-left">
          <div>
            <label class="loandeatail-label wd-left">Available Capacity</label>
          </div>
          <div>
            <label class="loandeatail-value">{{
              warehouseLineDetail.availablecapacity
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5">Allocated</label>
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.allocated
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5">Remaining</label>
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.remaining
            }}</label>
          </div>
        </div>
        <div class="ld-progress ld-left20 wd-left">
          <div class="progress-text wd-left">
            <span class="progress-span"
              >{{ warehouseLineDetail.percentage }}% Left</span
            >
          </div>
          <div class="progress progress-bar-vertical pb-wid">
            <div
              *ngIf="warehouseLineDetail?.percentage"
              role="progressbar"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
              [wlmsProgress]="warehouseLineDetail?.percentage"
            ></div>
            <div
              *ngIf="!warehouseLineDetail?.percentage"
              role="progressbar"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
              [wlmsProgress]="warehouseLineDetail?.percentage | async"
            ></div>
          </div>
        </div>
        <div class="ld-align ld-left30 wd-left">
          <div>
            <label class="loandeatail-label wd-left">Wet Balance</label>
          </div>
          <div>
            <label class="loandeatail-value">{{
              warehouseLineDetail.wetbalance
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5"
              >Total Advance</label
            >
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.totaladvance
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5">Dry Sweeps</label>
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.drysweeps
            }}</label>
          </div>
        </div>
        <div class="ld-align ld-left20 wd-left">
          <div>
            <label class="loandeatail-label wd-left">Funded Advance</label>
          </div>
          <div>
            <label class="loandeatail-value">{{
              warehouseLineDetail.fundedadvance
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5">Loans</label>
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.loans
            }}</label>
          </div>
          <div>
            <label class="loandeatail-label wd-left lbl-top5"
              >Loan Amount</label
            >
          </div>
          <div>
            <label class="loandeatail-value lbl-top5">{{
              warehouseLineDetail.loanamount
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content">
    <div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-100">
      <div class="app-l-container vertical-stack app-l-grid-action">
        <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
          <wlms-tab-set
            [isLabelDisplayed]="true"
            [tabList]="tabList"
          ></wlms-tab-set>
          <div *ngIf="'Summary' === tabList[selectedTab].code">
            <div class="summary-tab ld-left20">
              <div class="summary-tab-header ld-left20">
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Warehouse Line Name</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value wd-left summary-tab-label">{{
                    warehouselinename
                  }}</label>
                </div>
                <div>
                  <label
                    class="loandeatail-label wd-left summary-tab-label ld-left55"
                    >Warehouse Line Code</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouselinename
                  }}</label>
                </div>
              </div>

              <div class="summary-tab-content ld-left20 wd-left">
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Committed Amount</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.committedamount
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Total Line Amount</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.totallineamount
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Renewal</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.renewal
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Custodian Type</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.custodiantype
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Funding Cutoff</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.fundingcutoff
                  }}</label>
                </div>
              </div>

              <div class="summary-tab-content ld-left20 wd-left">
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >UnCommitted Amount</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.uncommittedamount
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Contract Month</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.contractmonth
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Bluge Expiry</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.blugeexpiry
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >Custodian</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.custodian
                  }}</label>
                </div>
                <div>
                  <label class="loandeatail-label wd-left summary-tab-label"
                    >LD Escrow Approved</label
                  >
                </div>
                <div>
                  <label class="loandeatail-value summary-tab-label">{{
                    warehouseLineDetail.summary?.ldescrowapproved
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="'Tranche' === tabList[selectedTab].code"
            style="height: 100%"
          >
            <wlms-tranche
              [suppressRightClickPrivilege]="suppressRightClickPrivilege"
              [suppressActionPrivilege]="suppressActionPrivilege"
              [warehouse]="warehouse"
            >
            </wlms-tranche>
          </div>

          <div *ngIf="'Account' === tabList[selectedTab].code">
            {{ warehouseLineDetail.account }} - Work under progress
          </div>
          <div *ngIf="'Limits' === tabList[selectedTab].code">
            {{ warehouseLineDetail.limits }} - Work under progress
          </div>
          <div *ngIf="'Investors' === tabList[selectedTab].code">
            {{ warehouseLineDetail.investors }} - Work under progress
          </div>
          <div *ngIf="'Products' === tabList[selectedTab].code">
            {{ warehouseLineDetail.products }} - Work under progress
          </div>
          <div *ngIf="'Eligibility' === tabList[selectedTab].code">
            {{ warehouseLineDetail.eligibility }} - Work under progress
          </div>
          <div *ngIf="'Sweeps' === tabList[selectedTab].code">
            {{ warehouseLineDetail.sweeps }} - Work under progress
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
