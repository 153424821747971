import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'wlms-assign-export-modal',
  templateUrl: './assign-export-modal.component.html',
  styleUrls: ['./assign-export-modal.component.scss']
})
export class AssignExportModalComponent implements OnInit {
  @Input() data: any;
  constructor() {
  }
  ngOnInit() {   
  } 
}