import {APP_BOOTSTRAP_LISTENER, Type} from '@angular/core';
import {EffectSources} from '@ngrx/effects';

export abstract class EffectsBootstrapProvider {
  static provide (effects: Type<any>[]) {
    return [
      effects,
      {
        provide: APP_BOOTSTRAP_LISTENER,
        multi: true,
        useFactory: (sources: EffectSources, ..._effects: Type<any>[]) => () => _effects.forEach(effect => sources.addEffects(effect)),
        deps: [ EffectSources, ...effects ]
      }
    ];
  }
}