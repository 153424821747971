import { ConditionalEditor, CurrencyFormatter, PercentageFormatter } from '@wlms-web/utils';
export const ContractDetails = [
  {
    field: 'startDate',
    headerName: 'Start Date',
    cellRenderer: 'formatDateRenderer',
    suppressClickEdit: true,
    width: 120,
    cellEditor: 'dateEditorComponent',
    editable: true,
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    cellRenderer: 'formatDateRenderer',
    suppressClickEdit: true,
    width: 120,
    cellEditor: 'dateEditorComponent',
    editable: true,
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'lineLimitAmount',
    headerName: 'Line Limit Amount',
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    suppressClickEdit: true,
    width: 150,
    cellStyle: { 'text-align': 'right' },
    editable: true,
    cellEditor: 'numericEditor',
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'committedTotal',
    headerName: 'Committed Amount',
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    suppressClickEdit: true,
    width: 150,
    cellStyle: { 'text-align': 'right' },
    editable: ConditionalEditor.setConditionalEditor,
    cellEditor: 'numericEditor',
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'IndexName',
    headerName: 'Index Name',
    width: 150,
    suppressClickEdit: true,
    editable: ConditionalEditor.setConditionalEditor,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [],
    },
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'warehouseLineIndexId',
    headerName: '',
    width: 130,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true
  },
  {
    field: 'floorRate',
    headerName: 'Floor Rate',
    width: 130,
    suppressClickEdit: true,
    editable: ConditionalEditor.setConditionalEditor,
    valueFormatter: PercentageFormatter.setPercentageFormat,
    cellEditor: 'numericEditor',
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'penaltyRate',
    headerName: 'Penalty Rate',
    width: 130,
    suppressClickEdit: true,
    editable: ConditionalEditor.setConditionalEditor,
    valueFormatter: PercentageFormatter.setPercentageFormat,
    cellEditor: 'numericEditor',
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'wetLimitAmount',
    headerName: 'Wet Limit Amt',
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    suppressClickEdit: true,
    width: 150,
    cellStyle: { 'text-align': 'right' },
    editable: ConditionalEditor.setConditionalEditor,
    cellEditor: 'numericEditor',
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
  },
  {
    field: 'action',
    headerName: '',
    width: 115,
    active: true,
    pinned: 'right',
    sortable: false,
    resizable: false,
    lockPinned: true,
    showRowGroup: true,
    suppressMenu: true,
    suppressMovable: true,
    suppressSorting: true,
    suppressColumnsToolPanel: true,
    suppressRowClickSelection: true,
    cellRenderer: 'actionColumnRendererComponent',
  }
];
