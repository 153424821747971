<div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
    <div style="display:flex">
        <!-- Tab section -->
        <div class="col-lg-10 col-sm-9">
            <wlms-tab-set [isLabelDisplayed]="true" [tabList]="tabList" [selectedIndex]="selectedTab"></wlms-tab-set>
        </div>

        <div class="col-lg-2 col-sm-3 no-padding u-text-right" style="bottom: -15px;">
            <button class="app-c-btn-export" (click)="updateFundedBatchDetails()">Update</button>
        </div>
    </div>

    <!-- Grid section -->
    <wlms-grid #fundedBatchGrid [columnDefs]="columnDefs" [rowData]="rowData">
    </wlms-grid>

</div>
<div class="spinneroverlay" *ngIf="showspinner">
    <div class="spinnercenter">
        <mat-progress-spinner diameter=50 mode="indeterminate"></mat-progress-spinner>
    </div>
</div>