import { Component, Input, OnInit } from '@angular/core';
import { LoanResult } from '../../config/table-structure-loanassign-result';

@Component({
  selector: 'wlms-assign-result-modal',
  templateUrl: './assign-result-modal.component.html',
  styleUrls: ['./assign-result-modal.component.scss']
})
export class AssignResultModalComponent implements OnInit {

  @Input() data: any;
  columnDefs = [...LoanResult];
  rowData = [];
  paginationPageSize = 10;
 
  
  constructor() { }

  ngOnInit(): void {
    this.getAssignStatus();    
  }

  getAssignStatus() {
    let res = this.data.responseDetails;
    res.forEach((element, index) => {
      if (element.status) {
        res[index].status = "Success";
      }
      else {
        res[index].status = "Failed";
      }
    });

    this.rowData = res;

    
  }
}
