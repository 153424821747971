import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { isEmpty } from 'lodash';
import { EmptyPipe, CustomCurrencyPipe, Loandetails, LoanDetailCategories, SharedService } from '@wlms-web/utils';

@Component({
  selector: 'wlms-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnChanges {

  loanDetailsData: any;
  icon: any;
  selectedAction: any;
  @Input() selectedLoan: any;
  loanDetails = Loandetails;
  loanDetailCategories = LoanDetailCategories;
  containerClass = 'body';
  subscription$: any;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();

  constructor(public datePipe: DatePipe, public currencyPipe: CurrencyPipe,
    private sharedService: SharedService) {
    this.subscription$ = this.sharedService.datasourceUpdated.subscribe((response: any) => {
      this.updateDataSet();
    });
  }

  ngOnChanges(): void {
    this.updateDataSet();
  }

  updateDataSet(): void {
    this.loanDetailsData = { ...this.selectedLoan };
    this.loanDetails.forEach((element: any, index: any) => {
      this.loanDetails[index] = { ...element, detailValue: this.loanDetailsData[element.field] }
      !this.selectedLoan ? '' : this.dataFormatter(index, this.loanDetails[index], this.loanDetailsData[element.field]);
    })
  }

  dataFormatter(index, data, value) {
    if (this.loanDetails[index].detailValueFormatters) {
      this.loanDetails[index].detailValueFormatters.forEach((element: any) => {
        switch (element) {
          case 'y/n':
            this.loanDetails[index] = { ...data, detailValue: value == 'Y' ? 'Yes' : 'No' }
            break;
          case 't/f':
            this.loanDetails[index] = { ...data, detailValue: value == 'Y' ? 'True' : 'False' }
            break;
          case 'dateTime':
            this.loanDetails[index] = { ...data, detailValue: this.datePipe.transform(value, 'MM/dd/YYYY hh:mm:ss a') }
            break;
          case 'date':
            this.loanDetails[index] = { ...data, detailValue: this.datePipe.transform(value, 'MM/dd/YYYY') }
            break;
          case 'currency':
            this.loanDetails[index] = { ...data, detailValue: this.currencyPipe.transform(value, 'USD') }
            break;
        }
      });
    }
  }

  onWarehouselineSelected(event: any) {
    this.optionSelected.emit({ ...event, loanDetails: [this.loanDetailsData].map((loan: any) => loan.LoanNumber) });
  }
}
