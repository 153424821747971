import { Component, EventEmitter, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { UiHelperService } from '@wlms-web/ui-kit';
import { SnackbarConfig } from '@wlms-web/utils';
import { SharedService, DateFormattor, NoRowsOverlayFlags } from '@wlms-web/utils';
import { WarehouseLinesService } from '../../../services/whl.service';
import { ContractDetails } from './../../../config/table-structure-contracts';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { EventService } from 'ag-grid-community';

@Component({
  selector: 'wlms-manage-warehouseline',
  templateUrl: './manage-warehouseline.component.html',
  styleUrls: ['./manage-warehouseline.component.scss']
})
export class ManageWarehouselineComponent implements OnDestroy, AfterViewInit {
  subscription$: Subscription;
  federalCutOff = new Date();
  @Input() data: any;
  @ViewChild('treeGrid') warehouseGrid: any
  detailCellRendererParams: any;
  editType = 'fullRow';
  isAdd = false;
  dummyId = -1;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  rowData = [];
  initialData = [];
  deletedRecords = [];
  suppressRightClickPrivilege: any = false;
  autoGroupColumnDef: any = {
    headerName: '',
    cellRenderer: 'agGroupCellRenderer',
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    width: 110,
    active: true,
    sortable: false,
    resizable: false,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'iconFormatRendererComponent',
    }
  };
  warehouseData: any = {};
  columnDefs: any = [...ContractDetails];
  selectedValue: any;
  isDummyRow = false;
  currentActiveRow: any;
  showGrid = false;
  cellEditAbolished = false;
  getDataPath: any;
  treeData = true;

  constructor(private sharedService: SharedService,
    private uiHelperService: UiHelperService,
    private warehouseLinesService: WarehouseLinesService
  ) {
    this.subscription$ = this.sharedService.modalClosed.subscribe(response => {
      this.cellEditAbolished = true;
    });
    this.subscription$ = this.sharedService.modalSaved.subscribe(response => {
      if (this.warehouseGrid) {
        this.warehouseGrid.stopEdit();
      }
      this.cellEditAbolished = true;
      this.treeData = false;
      this.getDataPath = false;
    });
    if (!this.cellEditAbolished) {
      this.getDataPath = (data: any) => {
        if (data.hierarchy) {
          return data.hierarchy;
        } else return null;
      };
    }
  }

  ngAfterViewInit() {
    this.sharedService.modalSubmitValid = false;
    if (this.data.isEdit) {
      this.showGrid = true;
      this.getWarehouselineData();
    }
    this.updateIndexList();
  }

  onGridReady() {
    if (!this.data.isEdit) {
      this.warehouseGrid.setColumnDefs(this.columnDefs);
      this.sharedService.loadGrid.next({ isLoad: false });
      this.warehouseGrid.setRowData([]);
      this.warehouseGrid.suppressEditOnCellClick(true);
    }
    if (this.isAdd) {
      this.warehouseGrid.setRowData(this.rowData);
      this.warehouseGrid.gotoLastPage();
      const rowNodes = this.warehouseGrid.gridApi.rowModel.rowsToDisplay;
      const index = rowNodes.findIndex((x) => x.data.warehouseLineContractDetailId == this.dummyId);
      this.startEditing(rowNodes[index], rowNodes[index].rowIndex);
      this.dummyId--;
    }
  }

  getWarehouselineData() {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    this.subscription$ = this.warehouseLinesService.getWarehouselineById(this.data.modalData.WarehouseLineId).subscribe(response => {
      if (response) {
        this.warehouseGrid.suppressEditOnCellClick(true);
        this.warehouseData = response['details'];
        this.pushMasterData();
        this.pushChildData();
        this.warehouseGrid.setRowData(this.rowData);
        this.federalCutOff = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
        parseInt(this.warehouseData.FederalCutoff.split(':')[0]),
        parseInt(this.warehouseData.FederalCutoff.split(':')[1]));
    }
      this.initialData = [...this.rowData];
      this.sharedService.loadGrid.next({ isLoad: false });
      if (this.rowData.length <= 0) {
        this.showGrid = false;
      }
    });
  }

  pushMasterData() {
    this.warehouseData.WarehouseLineContractDetails.forEach((x: any) => {
      const indexes = this.data.indexList.filter(y => y.WarehouseLineIndexId == x.WarehouseLineIndexId)
      const index = indexes.map(y => y.IndexName);
      const row = {
        startDate: x.StartDate,
        endDate: x.ExpiryDate,
        lineLimitAmount: x.LineLimitAmount,
        warehouseLineId: x.WarehouseLineId,
        warehouseLineIndexId: x.WarehouseLineIndexId,
        IndexName: index && index.length > 0 ? index[0] : '',
        warehouseLineContractDetailId: x.WarehouseLineContractDetailId,
        committedTotal: x.CommittedAmount,
        floorRate: x.FloorRate,
        penaltyRate: x.PenaltyRate,
        wetLimitAmount: x.WetLimitAmount,
        isDeleted: x.IsDeleted,
        hierarchy: [x.WarehouseLineContractDetailId],
        isLoansAssigned: x.IsLoansAssigned
      }
      this.rowData.push(row);
    });
  }

  pushChildData() {
    this.warehouseData.WarehouseLineContractAmendments.forEach((x: any) => {
      const row = {
        startDate: x.StartDate,
        endDate: x.ExpiryDate,
        lineLimitAmount: x.LineLimitAmount,
        warehouseLineId: '',
        warehouseLineIndexId: '',
        warehouseLineContractAmendmentId: x.WarehouseLineContractAmendmentId,
        warehouseLineContractDetailId: x.WarehouseLineContractDetailId,
        committedTotal: '',
        floorRate: '',
        penaltyRate: '',
        wetLimitAmount: '',
        isDeleted: x.IsDeleted,
        hierarchy: [x.WarehouseLineContractDetailId, x.WarehouseLineContractAmendmentId],
        isLoansAssigned: x.IsLoansAssigned
      }
      this.rowData.push(row);
    });
  }

  edit(e) {
    this.isDummyRow = false;
    this.setDateBoundary();
    this.startEditing(e, e.node.rowIndex);
    
  }

  startEditing(e, index) {
    this.cellEditAbolished = false;
    this.currentActiveRow = e.rowIndex;
    const x = e;
    this.warehouseGrid.gridApi.forEachNode(node => {
      node.data = { ...node.data, isEditEnabled: false }
    });
    e.data = { ...x.data, isEditEnabled: true }
    e.node ? e.node.data = { ...e.node.data, isEditEnabled: true } : '';
    this.warehouseGrid.refreshActionColumn(e);
    this.warehouseGrid.startEditing(e, index);
  }

  onRowEditStopped(params) {
    if (!this.cellEditAbolished) {
      if (this.validateCells(params)) {
        this.modelUpdated();
        this.resetEdit(params);
      } else {
        this.startEditing(params, params.node.rowIndex);
        // this.uiHelperService.showSnackBar('Line limit amount should be greater than zero', SnackbarConfig.error);
      }
    }
    this.isAdd = false;
  }

  resetEdit(params) {
    const x = params;
    params.data = { ...x.data, isEditEnabled: false }
    params.node ? params.node.data = { ...params.node.data, isEditEnabled: false } : '';
    this.warehouseGrid.refreshActionColumn(params);
    this.isAdd = false
  }

  saveContract(params) {
    params.data = { ...params.data, isEditEnabled: false }
    params.node ? params.node.data = { ...params.node.data, isEditEnabled: false } : '';
    this.warehouseGrid.stopEdit();
  }

  undo(params) {
    this.cellEditAbolished = true;
    if (this.isDummyRow) {
      const index = this.rowData.findIndex(x => (x.warehouseLineContractDetailId == this.dummyId + 1 ||
        x.warehouseLineContractAmendmentId == this.dummyId + 1));
      this.rowData.splice(index, 1);
      this.dummyId++;
    } else {
      const rowDataIndex = this.rowData.findIndex(x => (params.data.hierarchy.length == 1 &&
        x.warehouseLineContractDetailId == params.data.warehouseLineContractDetailId) ||
        (params.data.hierarchy.length == 2 &&
          x.warehouseLineContractAmendmentId == params.data.warehouseLineContractAmendmentId));
      const initialDataIndex = this.initialData.findIndex(x => (params.data.hierarchy.length == 1 &&
        x.warehouseLineContractDetailId == params.data.warehouseLineContractDetailId) ||
        (params.data.hierarchy.length == 2 &&
          x.warehouseLineContractAmendmentId == params.data.warehouseLineContractAmendmentId));
      if (initialDataIndex != -1) {
        this.rowData[rowDataIndex] = { ...this.initialData[initialDataIndex] };
      } else {
        this.rowData.splice(rowDataIndex, 1);
      }
    }
    this.isAdd = false;
    params.data = { ...params.data, isEditEnabled: false }
    params.node ? params.node.data = { ...params.node.data, isEditEnabled: false } : '';
    this.warehouseGrid.stopEdit();
    this.warehouseGrid.setRowData(this.rowData);
    if (this.rowData.length <= 0) {
      this.showGrid = false;
    }
  }

  updateIndexList() {
    const index = this.columnDefs.findIndex(x => x.field == 'IndexName');
    if (index != -1) {
      this.columnDefs[index] = { ...this.columnDefs[index], cellEditorParams: { values: this.data.indexList.map(y => y.IndexName) } }
    }
  }

  onCellEditStopped(event) {
    if (!this.cellEditAbolished) {
      let index;
      if (this.isDummyRow) {
        index = this.rowData.findIndex(x =>
          (x.warehouseLineContractDetailId == this.dummyId + 1 && x.hierarchy.length == 1 && event.data.hierarchy.length == 1) ||
          (x.warehouseLineContractAmendmentId == this.dummyId + 1 && x.hierarchy.length == 2 && event.data.hierarchy.length == 2));
      } else {
        index = this.rowData.findIndex(x =>
          (x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId &&
            x.hierarchy.length == 1 && event.data.hierarchy.length == 1) ||
          (x.warehouseLineContractAmendmentId == event.data.warehouseLineContractAmendmentId &&
            x.hierarchy.length == 2 && event.data.hierarchy.length == 2));
      }
      if (index != -1) {
        this.rowData[index] = event.data;
      }
      this.warehouseGrid.redrawRows();
    }
  }

  validateCells(event) {
    let isValid = true;
    const index = this.data.indexList.findIndex(x => x.IndexName == event.data.IndexName);
    if (index != -1) {
      event.data.warehouseLineIndexId = this.data.indexList[index].WarehouseLineIndexId;
    }
    if (moment(event.data.endDate).startOf('day').isBefore(moment(event.data.startDate).startOf('day'))) {
      this.uiHelperService.showSnackBar('Start date should be less than end date.', SnackbarConfig.error);
      isValid = false;
    }
    else if (event.data.hierarchy.length == 1) {
      isValid = this.doesAmendmentExist(event) &&
        this.doesContractsOverlap(event) &&
        this.isContractWithinNewEntry(event);
    }
    else if (event.data.hierarchy.length == 2) {
      isValid = this.endDateDatePastDate(event) &&
        this.doesAmendmentsOverlap(event) &&
        this.isAmendmentWithinNewEntry(event);
    }
    if (event && event.data && event.data.lineLimitAmount <= 0) {
      this.uiHelperService.showSnackBar('Line limit amount should be greater than zero', SnackbarConfig.error)
      isValid = false;
    }
    return isValid;
  }

  endDateDatePastDate(event) {
    const contracts = this.rowData.filter(x => x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 1);
    if (moment(event.data.endDate).startOf('day').isAfter(moment(contracts[0].endDate).startOf('day'))) {
      this.uiHelperService.showSnackBar('Amendments should be within the contract dates.', SnackbarConfig.error);
      return false;
    } else if (moment(event.data.startDate).startOf('day').isBefore(moment(contracts[0].startDate).startOf('day'))) {
      this.uiHelperService.showSnackBar('Amendments should be within the contract dates.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  doesAmendmentExist(event) {
    const amendments = this.rowData.filter(x => x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 2);
    const startIndex = amendments.findIndex(x => {
      if (moment(event.data.startDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]') ||
        moment(event.data.startDate).startOf('day').isAfter(moment(x.endDate).startOf('day'))) {
        return x;
      }
    });
    const endIndex = amendments.findIndex(x => {
      if (moment(event.data.endDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]') ||
        moment(event.data.endDate).startOf('day').isBefore(moment(x.endDate).startOf('day'))) {
        return x;
      }
    });
    if (startIndex != -1 || endIndex != -1) {
      this.uiHelperService.showSnackBar('There are amendments before or after the selected duration.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  doesContractsOverlap(event) {
    const amendments = this.rowData.filter(x => x.warehouseLineContractDetailId != event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 1);
    const startIndex = amendments.findIndex(x => {
      if (moment(event.data.startDate).isSame(x.startDate, 'day') ||
        moment(event.data.startDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]')) {
        return x;
      }
    });
    const endIndex = amendments.findIndex(x => {
      if (moment(event.data.endDate).isSame(x.endDate, 'day') ||
        moment(event.data.endDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]')) {
        return x;
      }
    });
    if (startIndex != -1 || endIndex != -1) {
      this.uiHelperService.showSnackBar('A contract already exist for this duration.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  doesAmendmentsOverlap(event) {
    const amendments = this.rowData.filter(x => x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 2 && x.warehouseLineContractAmendmentId != event.data.warehouseLineContractAmendmentId);
    const startIndex = amendments.findIndex(x => {
      if (moment(event.data.startDate).isSame(x.startDate, 'day') ||
        moment(event.data.startDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]')) {
        return x;
      }
    });
    const endIndex = amendments.findIndex(x => {
      if (moment(event.data.endDate).isSame(x.endDate, 'day') ||
        moment(event.data.endDate).startOf('day').isBetween(x.startDate, x.endDate, undefined, '[]')) {
        return x;
      }
    });
    if (startIndex != -1 || endIndex != -1) {
      this.uiHelperService.showSnackBar('An amendment already exist for this duration.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  isAmendmentWithinNewEntry(event) {
    const amendments = this.rowData.filter(x => x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 2 && x.warehouseLineContractAmendmentId != event.data.warehouseLineContractAmendmentId);
    const startIndex = amendments.findIndex(x => {
      if (moment(event.data.startDate).isSame(x.startDate, 'day') ||
        moment(x.startDate).isBetween(moment(event.data.startDate).startOf('day'),
          moment(event.data.endDate).startOf('day'), undefined, '[]')) {
        return x;
      }
    });
    const endIndex = amendments.findIndex(x => {
      if (moment(event.data.endDate).isSame(x.endDate, 'day') ||
        moment(x.endDate).isBetween(moment(event.data.startDate).startOf('day'),
          moment(event.data.endDate).startOf('day'), undefined, '[]')) {
        return x;
      }
    });
    if (startIndex != -1 || endIndex != -1) {
      this.uiHelperService.showSnackBar('An amendment already exist for this duration.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  isContractWithinNewEntry(event) {
    const contracts = this.rowData.filter(x => x.warehouseLineContractDetailId != event.data.warehouseLineContractDetailId &&
      x.hierarchy.length == 1);
    const startIndex = contracts.findIndex(x => {
      if (moment(event.data.startDate).isSame(x.startDate, 'day') ||
        moment(x.startDate).isBetween(moment(event.data.startDate).startOf('day'),
          moment(event.data.endDate).startOf('day'), undefined, '[]')) {
        return x;
      }
    });
    const endIndex = contracts.findIndex(x => {
      if (moment(event.data.endDate).isSame(x.endDate, 'day') ||
        moment(x.endDate).isBetween(moment(event.data.startDate).startOf('day'),
          moment(event.data.endDate).startOf('day'), undefined, '[]')) {
        return x;
      }
    });
    if (startIndex != -1 || endIndex != -1) {
      this.uiHelperService.showSnackBar('A contract already exist for this duration.', SnackbarConfig.error);
      return false;
    }
    return true;
  }

  addContract() {
    this.setDateBoundary();
    this.isAdd = true;
    this.isDummyRow = true;
    const row = {
      startDate: new Date(),
      endDate: new Date().setFullYear(new Date().getFullYear() + 1),
      lineLimitAmount: 0,
      warehouseLineId: this.warehouseData.WarehouseLineId,
      warehouseLineIndexId: 0,
      warehouseLineContractDetailId: this.dummyId,
      committedTotal: 0,
      floorRate: 0,
      penaltyRate: 0,
      wetLimitAmount: 0,
      isDeleted: 0,
      hierarchy: [this.dummyId]
    }
    this.rowData.push(row);
    if (this.showGrid) {
      this.warehouseGrid.setRowData(this.rowData);
      this.warehouseGrid.gotoLastPage();
      const rowNodes = this.warehouseGrid.gridApi.rowModel.rowsToDisplay;
      const index = rowNodes.findIndex((x) => x.data.warehouseLineContractDetailId == this.dummyId);
      this.startEditing(rowNodes[index], rowNodes[index].rowIndex);
      this.dummyId--;
    }
    this.showGrid = true;
  }

  addAmendment(e) {
    this.isDummyRow = true;
    this.setDateBoundary();
    const contractIndex = this.rowData.findIndex(x => x.warehouseLineContractDetailId == e.data.warehouseLineContractDetailId
      && x.hierarchy.length == 1)
    const startDate = moment(this.rowData[contractIndex].startDate).isBefore(new Date()) ?
      new Date() : new Date(this.rowData[contractIndex].startDate)
    const row = {
      startDate: startDate,
      endDate: new Date(this.rowData[contractIndex].endDate),
      lineLimitAmount: 0,
      warehouseLineId: this.warehouseData.WarehouseLineId,
      warehouseLineIndexId: '',
      warehouseLineContractAmendmentId: this.dummyId,
      warehouseLineContractDetailId: e.data.warehouseLineContractDetailId,
      committedTotal: '',
      floorRate: '',
      penaltyRate: '',
      wetLimitAmount: '',
      isDeleted: 0,
      hierarchy: [e.data.warehouseLineContractDetailId, this.dummyId]
    }
    this.rowData.push(row);
    this.warehouseGrid.setRowData(this.rowData);
    this.enableChildEdit();
  }

  enableChildEdit() {
    this.warehouseGrid.gridApi.forEachNode(node => {
      node.allLeafChildren.forEach(child => {
        if (child.data.warehouseLineContractAmendmentId == this.dummyId) {
          node.setExpanded(true);
        }
      })
    });
    setTimeout(() => {
      this.warehouseGrid.gridApi.forEachLeafNode(x => {
        if (x.data.warehouseLineContractAmendmentId == this.dummyId) {
          this.startEditing(x, x.rowIndex);
        }
      })
      this.dummyId--;
    }, 100)
  }

  delete(event) {
    this.cellEditAbolished = true;
    this.isAdd = false;
    if ((event.data.hierarchy.length == 1 && event.data.warehouseLineContractDetailId <= 0)) {
      const index = this.rowData.findIndex(x => x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId);
      if (index != -1) {
        this.rowData.splice(index, 1);
      }
    } else if ((event.data.hierarchy.length == 2 && event.data.warehouseLineContractAmendmentId <= 0)) {
      const index = this.rowData.findIndex(x => x.warehouseLineContractAmendmentId == event.data.warehouseLineContractAmendmentId);
      if (index != -1) {
        this.rowData.splice(index, 1);
      }
    }
    const deletedRecords = this.rowData.filter(x => event.data.warehouseLineContractAmendmentId ?
      x.warehouseLineContractAmendmentId == event.data.warehouseLineContractAmendmentId :
      x.warehouseLineContractDetailId == event.data.warehouseLineContractDetailId

    );
    if (deletedRecords.length > 0) {
      this.deletedRecords = this.deletedRecords.concat(deletedRecords);
      this.rowData = this.rowData.filter(item1 => event.data.warehouseLineContractAmendmentId ?
        !deletedRecords.some(item2 => (item2.warehouseLineContractAmendmentId == item1.warehouseLineContractAmendmentId)) :
        !deletedRecords.some(item2 => (item2.warehouseLineContractDetailId == item1.warehouseLineContractDetailId))
      )
    }
    this.warehouseGrid.setRowData(this.rowData);
    this.modelUpdated();
    if (this.rowData.length <= 0) {
      this.showGrid = false;
    }
  }

  getMasterData() {
    const rows = [];
    this.rowData.forEach(x => {
      if (x.hierarchy.length == 1) {
        rows.push(this.setContractValues(x, 0));
      }
    });
    this.deletedRecords.forEach(x => {
      if (x.hierarchy.length == 1) {
        rows.push(this.setContractValues(x, 1));
      }
    });
    return rows;
  }

  setContractValues(x, isDeleted) {
    return {
      StartDate: moment(x.startDate).utc(true).toISOString(),
      ExpiryDate: moment(x.endDate).utc(true).toISOString(),
      LineLimitAmount: x.lineLimitAmount,
      WarehouseLineId: x.warehouseLineId,
      WarehouseLineIndexId: x.warehouseLineIndexId,
      WarehouseLineContractDetailId: x.warehouseLineContractDetailId >= 0 ? x.warehouseLineContractDetailId : 0,
      CommittedAmount: x.committedTotal,
      FloorRate: x.floorRate,
      PenaltyRate: x.penaltyRate,
      WetLimitAmount: x.wetLimitAmount,
      IsDeleted: isDeleted
    }
  }

  getChildData() {
    const rows = [];
    this.rowData.forEach(x => {
      if (x.hierarchy.length == 2) {
        rows.push(this.setAmendmentValues(x, 0));
      }
    });
    this.deletedRecords.forEach(x => {
      if (x.hierarchy.length == 2) {
        rows.push(this.setAmendmentValues(x, 1));
      }
    });
    return rows;
  }

  setAmendmentValues(x, isDeleted) {
    return {
      StartDate: moment(x.startDate).utc(true).toISOString(),
      ExpiryDate: moment(x.endDate).utc(true).toISOString(),
      LineLimitAmount: x.lineLimitAmount,
      WarehouseLineContractAmendmentId: x.warehouseLineContractAmendmentId >= 0 ? x.warehouseLineContractAmendmentId : 0,
      WarehouseLineContractDetailId: x.warehouseLineContractDetailId >= 0 ? x.warehouseLineContractDetailId : 0,
      IsDeleted: isDeleted,
    };
  }

  federalCutoffChange(value?)
  {
    if(value)
    {
    let old = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                  parseInt(this.warehouseData?.FederalCutoff?.split(':')[0]),
                  parseInt(this.warehouseData?.FederalCutoff?.split(':')[1]));
      if(value.getTime() != old.getTime())
      {
        this.modelUpdated();
      }
     
    }
    else{
      this.sharedService.modalSubmitValid = false;
    }
  }

  modelUpdated(value?) {
    this.sharedService.modalSubmitValid = true;
    if (!this.warehouseData.WarehouseLineName || (this.warehouseData.WarehouseLineName && !this.warehouseData.WarehouseLineName.trim())) {
      this.sharedService.modalSubmitValid = false;
    }
    if (this.warehouseData.WarehouseLineId) {
      if (!this.warehouseData.ShortCode && !this.warehouseData.VendorId) {
        this.warehouseData.TableFunding = false;
      }

    }

    value ? this.warehouseData.TableFunding = value.target.checked : '';

    this.data.modalData = {
      ...this.warehouseData,
      WarehouseLineName: this.warehouseData.WarehouseLineName ? this.warehouseData.WarehouseLineName.trim() : '',
      FederalCutoff: new Date(this.federalCutOff).getHours() + ':' + new Date(this.federalCutOff).getMinutes(),
      TableFunding: value ? value.target.checked : this.warehouseData.TableFunding,
      WarehouseLineContractDetails: this.getMasterData(),
      WarehouseLineContractAmendments: this.getChildData(),

    }
  }

  setDateBoundary() {
    this.uiHelperService.dateBoundary = {
      minDate: '',
      maxDate: ''
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
