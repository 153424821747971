<ng-container *ngIf="isLabelDisplayed">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab *ngFor="let label of tabList; let i = index" label="{{ label.viewCount ? label.name +'('+label.viewCount+')' : label.name }}">
        </mat-tab>
    </mat-tab-group>
</ng-container>

<ng-container *ngIf="!isLabelDisplayed">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let tab of tabList; let i = index">
            <ng-template mat-tab-label>
                <div class="app-l-panel-tab-symbol">
                    <i [class]="tab?.icon"></i>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ng-container>