import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SharedService, TimeLimits, ColourCodes, TimeFormattor, SnackbarConfig } from '@wlms-web/utils';
import { UiHelperService } from '@wlms-web/ui-kit';
import * as moment from 'moment';

@Component({
  selector: 'wlms-manage-time-slot',
  templateUrl: './manage-time-slot.component.html',
  styleUrls: ['./manage-time-slot.component.scss']
})
export class ManageTimeSlotComponent implements OnInit {
  @Input() data: any;
  @ViewChild('timeSlotGrid') timeSlotGrid: any;
  editType = '';
  sideBar = '';
  startTime: Date;
  endTime: Date;
  minStartTime: Date = new Date();
  maxStartTime: Date = new Date();
  minEndTime: Date = new Date();
  maxEndTime: Date = new Date();
  showTimeEditorPanel = false;
  applyDisabled = false;
  paginationPageSize = 5;
  
  dummyId: any = parseInt(TimeLimits.dummyId);
  columnDefs = [
    {
      field: 'startTime',
      headerName: 'Start Time',
      minWidth: 250,
      valueFormatter: TimeFormattor.setDateFormattor
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      minWidth: 250,
      valueFormatter: TimeFormattor.setDateFormattor
    },
    {
      field: 'action',
      headerName: '',
      minWidth: 169,
      active: true,
      pinned: 'right',
      sortable: false,
      resizable: false,
      lockPinned: true,
      showRowGroup: true,
      suppressMenu: true,
      suppressMovable: true,
      suppressSorting: true,
      suppressColumnsToolPanel: true,
      suppressRowClickSelection: true,
      cellRenderer: 'actionColumnRendererComponent',
    }
  ]
  rowData = [];

  constructor(private sharedService: SharedService,
    private uiHelperService: UiHelperService) {
    this.configiureDefaults();
  }

  ngOnInit(): void {
    this.rowData = [...this.data];
    if (this.rowData.length == 1) {
      this.rowData[0].isAdd = true;
    }
  }

  showTimeEditor() {
    this.showTimeEditorPanel = true;
  }

  configiureDefaults() {
    this.startTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      parseInt(this.sharedService.dayStart.split(':')[0]),
      parseInt(this.sharedService.dayStart.split(':')[1]));
    this.endTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      parseInt(this.sharedService.dayEnd.split(':')[0]),
      parseInt(this.sharedService.dayEnd.split(':')[1]));
    this.setTimeBoundary();
  }

  setTimeBoundary() {
    const defaultEndTime = this.configureTime(parseInt(this.sharedService.dayEnd.split(':')[0]), parseInt(this.sharedService.dayEnd.split(':')[1]));
    const defaultStartTime = this.configureTime(parseInt(this.sharedService.dayStart.split(':')[0]),
      parseInt(this.sharedService.dayStart.split(':')[1]) - 1);
    this.minStartTime = defaultStartTime;
    this.minEndTime = this.configureTime(moment(this.startTime).add(parseInt(TimeLimits.timeGap) - 1, 'm').toDate().getHours(),
      moment(this.startTime).add(parseInt(TimeLimits.timeGap) - 1, 'm').toDate().getMinutes());
    this.maxStartTime = defaultEndTime;
    this.maxEndTime = defaultEndTime;
  }

  configureTime(hours, mins) {
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(mins);
    time.setSeconds(0);
    return time;
  }

  validateTime() {
    if (this.startTime == null || this.endTime == null) {
      this.uiHelperService.showSnackBar(`Please enter valid time.`, SnackbarConfig.error);
      return false
    } else if (moment(this.startTime).isBefore(moment(this.minStartTime)) ||
      moment(this.endTime).isBefore(moment(this.minEndTime)) ||
      moment(this.startTime).isAfter(moment(this.maxStartTime)) ||
      moment(this.endTime).isAfter(moment(this.maxEndTime))) {
      let endate =  moment(new Date(new Date().getFullYear(), 
                    new Date().getMonth(), new Date().getDate(),
                    parseInt(this.sharedService.dayEnd.split(':')[0]),
                    parseInt(this.sharedService.dayEnd.split(':')[1]))).format("hh:mm:ss");

      this.uiHelperService.showSnackBar(
        `The time should be between ${this.sharedService.dayStart} AM and ${endate} PM
        and start time should be ${TimeLimits.timeGap} mins before end time.`,
        SnackbarConfig.error);
      return false
    }
    return true
  }

  saveTime() {
    if (this.validateTime()) {
      let timeData = [...this.rowData];
      const removeData = [];
      timeData = this.checkOverlaps(timeData, removeData)
      timeData.push({
        timeId: this.dummyId,
        startTime: this.startTime,
        endTime: this.endTime,
        isAdd: false
      });
      this.dummyId--
      timeData = timeData.filter((x: any) => !removeData.map((y) => y.timeId).includes(x.timeId)).sort((a, b) => a.startTime - b.startTime);
      this.checkValidSlots(timeData);
    }
  }

  checkValidSlots(timeData) {
    const index = timeData.findIndex((x: any) => {
      return Math.ceil(moment.duration(moment(x.endTime.setSeconds(0)).
        diff(x.startTime.setSeconds(0))).asMinutes()) < parseInt(TimeLimits.timeGap)
    });
    if (index != -1) {
      this.dummyId++;
      this.uiHelperService.showSnackBar(`Time slots should have a minimum duration of ${TimeLimits.timeGap} minutes.`,
        SnackbarConfig.error);
    } else {
      this.rowData = [...timeData];
      this.rowData.forEach((x: any, index) => {
        x = {
          ...x,
          startTime: x.startTime.getHours() + ':' +
            (x.startTime.getMinutes() < 10 ? '0' : '') + x.startTime.getMinutes(),
          endTime: x.endTime.getHours() + ':' +
            (x.endTime.getMinutes() < 10 ? '0' : '') + x.endTime.getMinutes()
        }
        this.rowData[index] = { ...x };
      });
      if (this.rowData.length == 1) {
        this.rowData[0].isAdd = true;
      }
      this.timeSlotGrid.setRowData(this.rowData);
      this.timeSlotGrid.redrawRows();
      this.sharedService.modalDataLoaded.next(this.rowData);
    }
  }

  checkOverlaps(timeData, removeData) {
    const newEntry = [];
    timeData.forEach((x: any, index: any) => {
      x = {
        ...x, startTime: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
          this.rowData[index].startTime.split(':')[0],
          this.rowData[index].startTime.split(':')[1]),
        endTime: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
          this.rowData[index].endTime.split(':')[0],
          this.rowData[index].endTime.split(':')[1])
      };
      if (moment(x.startTime).isBetween(this.startTime, this.endTime, undefined, '[]') &&
        moment(x.endTime).isBetween(this.startTime, this.endTime, undefined, '[]')) {
        removeData.push(x);
      } else if (moment(x.startTime).isBetween(this.startTime, this.endTime, undefined, '[]')) {
        x = {
          ...x, startTime: this.endTime, isAdd: false
        }
      } else if (moment(x.endTime).isBetween(this.startTime, this.endTime, undefined, '[]')) {
        x = {
          ...x, endTime: this.startTime, isAdd: false
        }
      } else if (moment(this.startTime).isBetween(x.startTime, x.endTime, undefined, '[]') &&
        moment(this.endTime).isBetween(x.startTime, x.endTime, undefined, '[]')) {
        x = {
          ...x, endTime: this.startTime, isAdd: false
        }
        newEntry.push({
          timeId: this.dummyId,
          startTime: this.endTime,
          endTime: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
            index == timeData.length - 1 ? parseInt(this.sharedService.dayEnd.split(':')[0]) : this.rowData[index + 1].startTime.split(':')[0],
            index == timeData.length - 1 ? parseInt(this.sharedService.dayEnd.split(':')[1]) : this.rowData[index + 1].startTime.split(':')[1]),
          isAdd: false
        });
        this.dummyId--;
      }
      timeData[index] = { ...x };
    });
    timeData = timeData.concat(newEntry);
    return timeData;
  }

  deleteTime(event) {
    this.reArrangeSlotsAfterDelete(event);
    this.rowData = this.rowData.filter((x: any) => x.timeId != event.data.timeId);
    if (this.rowData.length == 1) {
      this.rowData[0].isAdd = true;
    }
    this.timeSlotGrid.setRowData(this.rowData);
    this.sharedService.modalDataLoaded.next(this.rowData);
  }

  reArrangeSlotsAfterDelete(event) {
    this.rowData.forEach((x: any, index) => {
      if (x.timeId == event.data.timeId) {
        if (index == 0) {
          if (index === this.rowData.length - 1) {
            this.rowData[1] = {
              ...this.rowData[1], endTime: this.sharedService.dayEnd, startTime: this.sharedService.dayStart, timeId: this.dummyId, isAdd: true
            };
            this.dummyId--;
          } else {
            this.rowData[1] = { ...this.rowData[1], startTime: this.sharedService.dayStart, isAdd: false };
          }
        } else if (index === this.rowData.length - 1) {
          this.rowData[index - 1] = { ...this.rowData[index - 1], endTime: this.sharedService.dayEnd, isAdd: false };
        } else {
          this.rowData[index - 1] = { ...this.rowData[index - 1], endTime: x.endTime, isAdd: false };
        }
      }
    });
  }

  cancelTimePanel() {
    this.showTimeEditorPanel = false;
  }
}
