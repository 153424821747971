import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CapType, SharedService, SnackbarConfig } from '@wlms-web/utils';
import * as moment from 'moment';
import { UiHelperService } from '@wlms-web/ui-kit';

@Component({
  selector: 'wlms-edit-daily-cap',
  templateUrl: './edit-daily-cap.component.html',
  styleUrls: ['./edit-daily-cap.component.scss']
})
export class EditDailyCapComponent implements AfterViewInit {

  @Input() data;
  masterData: any;
  capType = [...CapType];
  minDate = new Date();
  startDate = new Date();
  endDate = new Date();
  bsConfig = { containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' };

  constructor(private sharedService: SharedService,
    private uiHelperService: UiHelperService) { }

  ngAfterViewInit(): void {
    if (this.data && this.data.dailyCapData && this.data.dailyCapData.StartDate == null) {
      this.startDate = new Date();
    } else {
      this.startDate = new Date(this.data.dailyCapData.StartDate);
    }
    if (this.data && this.data.dailyCapData && this.data.dailyCapData.ExpiryDate == null) {
      this.endDate = new Date();
    } else {
      this.endDate = new Date(this.data.dailyCapData.ExpiryDate);
    }
    if (this.data && this.data.dailyCapData && this.data.dailyCapData.CapType <= 0) {
      this.data.dailyCapData.CapType = CapType[0].id;
    }
    if (this.data && this.data.dailyCapData && (!this.data.dailyCapData.Value || this.data.dailyCapData.Value <= 0)) {
      this.sharedService.modalSubmitValid = false;
    } else {
      this.sharedService.modalSubmitValid = true;
    }
  }


  dateChange(ctrl, event) {
    event == null ? '' : event
    if (event && event.getDay() == 6 || event.getDay() == 0) {
      this.uiHelperService.showSnackBar('Selected day should not be a Saturday or Sunday.', SnackbarConfig.error);
      this.sharedService.modalSubmitValid = false;
    } else if (ctrl == 'start' && event && moment(event).startOf('day').isAfter(moment(this.data.dailyCapData.ExpiryDate).startOf('day'))) {
      this.uiHelperService.showSnackBar('Start date should be lesser than end date.', SnackbarConfig.error);
      this.sharedService.modalSubmitValid = false;
    } else if (ctrl != 'start' && event && moment(event).startOf('day').isBefore(moment(this.data.dailyCapData.StartDate).startOf('day'))) {
      this.uiHelperService.showSnackBar('Start date should be lesser than end date.', SnackbarConfig.error);
      this.sharedService.modalSubmitValid = false;
    } else {
      this.sharedService.modalSubmitValid = true;
    }
    if (ctrl == 'start') {
      this.data.dailyCapData.StartDate = moment(event == null ? new Date() : event).startOf('day');
    } else {
      this.data.dailyCapData.ExpiryDate = moment(event == null ? this.data.dailyCapData.StartDate : event).startOf('day');
    }
    if (new Date(this.data.dailyCapData.StartDate).getDay() == 0 || new Date(this.data.dailyCapData.StartDate).getDay() == 6 ||
      new Date(this.data.dailyCapData.ExpiryDate).getDay() == 0 || new Date(this.data.dailyCapData.ExpiryDate).getDay() == 6) {
      this.sharedService.modalSubmitValid = false;
    }
    this.startDate = new Date(this.data.dailyCapData.StartDate);
    this.endDate = new Date(this.data.dailyCapData.ExpiryDate);
  }

  amountChanged(event) {
    if (event > 0) {
      this.sharedService.modalSubmitValid = true;
    } else {
      this.sharedService.modalSubmitValid = false;
    }
  }

  amountValueChanged(event) {
    if (event && event.target.value <= 0) {
      this.uiHelperService.showSnackBar('Amount should be greater than 0.', SnackbarConfig.error);
      this.sharedService.modalSubmitValid = false;
    }
  }
}
