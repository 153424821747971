import { CurrencyFormatter, PercentageFormatter } from '@wlms-web/utils';

export const WarehouseLinesdetails = [
  {
    "field": "WarehouseLineName",
    "headerName": "Warehouse Line",
    "filter": "agTextColumnFilter",
    "width": 150,
  },
  {
    "field": "ContractExpiryDate",
    "headerName": "Line Limit Exp. Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 200,
  },
  {
    "field": "LineLimitAmount",
    "headerName": "Line Limit Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "CommittedAmount",
    "headerName": "Committed Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "UncommittedAmount",
    "headerName": "Uncommitted Amt",
    "width": 200,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "StartingOutStandingAmount",
    "headerName": "Starting Outstanding Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
   {
    "field": "AmendendStartingOutStandingAmount",
    "headerName": 'Amended Starting Outstanding Amt',
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "OutStandingAmount",
    "headerName": "Outstanding Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "AvailableAmount",
    "headerName": "Available Amt",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "AmendedLineLimitAmount",
    "headerName": "Amended Line Limit Amt",
    "width": 220,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "ContractAmendmentExpiryDate",
    "headerName": "Amended Line Amt Exp. Date",
    "cellRenderer": "formatDateRenderer",
    "filter": "agDateColumnFilter",
    "width": 220,
  },
  {
    "field": "IndexName",
    "headerName": "Index Name",
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "FloorRate",
    "headerName": "Floor Rate",
    valueFormatter: PercentageFormatter.setPercentageFormat,
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "TableFunding",
    "headerName": "Table Funding",
    "filter": "agSetColumnFilter",
    "width": 180,
    valueFormatter: (params: any) => { return params.value ? 'Y' : 'N' },
  },
  {
    "field": "PenaltyRate",
    "headerName": "Penalty Rate",
    valueFormatter: PercentageFormatter.setPercentageFormat,
    "filter": "agNumberColumnFilter",
    "width": 150,
  },
  {
    "field": "WetLimitAmount",
    "headerName": "Wet Limit Amt",
    "width": 220,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' }
  },

  {
    "field": "Comments",
    "headerName": "Comments",
    "filter": "agTextColumnFilter",
    "width": 300,
    tooltipField: 'Comments'
  },
  {
    "field": "action",
    "headerName": "",
    "width": 100,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
]
