import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { WarehouselinedetailsComponent } from './whl-details/whl-details.component';
import { UiKitModule } from '@wlms-web/ui-kit';
import { UtilsModule } from '@wlms-web/utils';
import { MaterialModule } from '@wlms-web/material';
import { WarehouselineDetailGridviewComponent } from './whl-summary/whl-summary.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FeaturesPageLayoutModule } from '@wlms-web/features/page-layout';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule } from '@angular/forms';
import { ManageTimeSlotComponent } from './whl-summary/manage-time-slot/manage-time-slot.component';
import { WarehouselinesComponent } from './whl-summary/warehouselines/warehouselines.component';
import { ManageWarehouselineComponent } from './whl-summary/warehouselines/manage-warehouseline/manage-warehouseline.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DailyCapsComponent } from './whl-summary/daily-caps/daily-caps.component';
import { EditDailyCapComponent } from './whl-summary/daily-caps/edit-daily-cap/edit-daily-cap.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TrancheComponent } from './whl-details/tranche/tranche.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ManageTrancheComponent } from './whl-details/tranche/manage-tranche/manage-tranche.component';
import { WetOverridesComponent } from './whl-summary/wet-overrides/wet-overrides.component';
import { EditWetOverridesComponent } from './whl-summary/wet-overrides/edit-wet-overrides/edit-wet-overrides.component';
import { CopyWhlContractComponent } from './whl-summary/warehouselines/copy-whl-contract/copy-whl-contract.component';
import { CopyTrancheComponent } from './whl-details/tranche/copy-tranche/copy-tranche.component';

@NgModule({
  imports: [CommonModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    FormsModule,
    UiKitModule,
    UtilsModule,
    ModalModule.forRoot(),
    FeaturesPageLayoutModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MaterialModule,
    PopoverModule],
  declarations: [
    WarehouselinedetailsComponent,
    WarehouselineDetailGridviewComponent,
    ManageTimeSlotComponent,
    WarehouselinesComponent,
    ManageWarehouselineComponent,
    DailyCapsComponent,
    EditDailyCapComponent,
    TrancheComponent,
    ManageTrancheComponent,
    WetOverridesComponent,
    EditWetOverridesComponent,
    CopyWhlContractComponent,
    CopyTrancheComponent
  ],
  exports: [
    WarehouselinedetailsComponent,
    WarehouselineDetailGridviewComponent
  ],
})
export class WarehouseLinesModule { }
