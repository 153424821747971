import { TimeCalculateValue } from "../constants/data-constants";

export class TimeCalculation {
 static  allocateTimeUnits (time :any) { 
   let checktimeZero = false;
   let minutesToDday = 0;
   let hoursToDday = 0;
   let daysToDday = 0;
    minutesToDday = Math.floor((time) / (TimeCalculateValue.MILLI_SECONDS_IN_A_SECOND * TimeCalculateValue.MINUTES_IN_A_HOUR) % TimeCalculateValue.SECONDS_IN_A_MINUTE);
    hoursToDday = Math.floor((time) / (TimeCalculateValue.MILLI_SECONDS_IN_A_SECOND * TimeCalculateValue.MINUTES_IN_A_HOUR * TimeCalculateValue.SECONDS_IN_A_MINUTE) % TimeCalculateValue.HOURS_IN_A_DAY);
    daysToDday = Math.floor((time) / (TimeCalculateValue.MILLI_SECONDS_IN_A_SECOND * TimeCalculateValue.MINUTES_IN_A_HOUR * TimeCalculateValue.SECONDS_IN_A_MINUTE * TimeCalculateValue.HOURS_IN_A_DAY));
      if(Math.sign(minutesToDday) == -1 && Math.sign(hoursToDday) == -1 && Math.sign(daysToDday) == -1)
      {
         checktimeZero = true
      }
      else if(Math.sign(minutesToDday) == 0 && Math.sign(hoursToDday) == 0 && Math.sign(daysToDday) == 0)
      {
         checktimeZero = true
      } 
      if(checktimeZero) { 
        minutesToDday = 0
        hoursToDday = 0
        daysToDday = 0
      }

      return { minutesToDday: minutesToDday, hoursToDday: hoursToDday, daysToDday: daysToDday, checktimeZero :checktimeZero };
  }
  
};