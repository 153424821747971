import { Component, OnInit,Input } from '@angular/core';
import { UpdateAdvanceDate } from '../../config/table-structure-AdvanceDate-Update';

@Component({
  selector: 'wlms-update-advance-date-modal',
  templateUrl: './update-advance-date-modal.component.html',
  styleUrls: ['./update-advance-date-modal.component.scss']
})
export class UpdateAdvanceDateModalComponent implements OnInit {
  @Input() data: any;
  columnDefs = [...UpdateAdvanceDate];
  rowData = [];
  paginationPageSize = 10;
  constructor() { }
 
  ngOnInit(): void {
    this.rowData = this.data;
  }

}
