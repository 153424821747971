<div class="d-flex flex-row align-items-center app-c-add-wrapper">
  <div class="app-c-btn" (click)="showTimeEditor()">
    <i class="icon-plus"></i>
  </div>
</div>
<div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
  <wlms-grid
    class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1 time-slot-grid"
    #timeSlotGrid
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [paginationPageSize]="paginationPageSize"
    [editType]="editType"
    (delete)="deleteTime($event)"
    [suppressRightClickPrivilege]="true"
    [suppressActionPrivilege]="true"
  >
  </wlms-grid>
</div>

<div class="app-c-time-slot-wrapper" *ngIf="showTimeEditorPanel">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-row align-items-center">
      <label class="pr-2">Start Time:</label>
      <timepicker
        [(ngModel)]="startTime"
        minuteStep="1"
        [showMeridian]="true"
      ></timepicker>
    </div>
    <div class="d-flex flex-row align-items-center">
      <label class="pr-2">End Time:</label>
      <timepicker
        [(ngModel)]="endTime"
        minuteStep="1"
        [showMeridian]="true"
      ></timepicker>
    </div>
    <div class="d-flex flex-row align-items-center button-spacer">
      <button class="icon-add-panel-cancel" (click)="cancelTimePanel()">
        <i class="icon-undo"></i>
      </button>
      <button class="icon-add-panel" (click)="saveTime()">
        <i class="icon-tick"></i>
      </button>
    </div>
  </div>
</div>
