import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { OverrideType, SharedService, SnackbarConfig } from '@wlms-web/utils';
import * as moment from 'moment';
import { UiHelperService } from '@wlms-web/ui-kit';


@Component({
  selector: 'wlms-edit-wet-overrides',
  templateUrl: './edit-wet-overrides.component.html',
  styleUrls: ['./edit-wet-overrides.component.scss']
})
export class EditWetOverridesComponent implements OnInit {
  @Input() data;
  OverrideType = [...OverrideType];
  showValueTypeFirst = false;
  amountType: any = '';
  constructor(private sharedService: SharedService,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    if(this.data.wetOverridesData.WarehouseLineWetOverridesId && this.data.wetOverridesData.WarehouseLineWetOverridesId > 0){
      this.sharedService.modalSubmitValid = true;
    }
    else{
      this.sharedService.modalSubmitValid = false;
    }  
  }

  amountChanged(event) {
    if(this.data.wetOverridesData && this.data.wetOverridesData.OverrideValueFirst >0
       && this.data.wetOverridesData.OverrideValueMid >0
       && this.data.wetOverridesData.OverrideValueLast >0){
      this.sharedService.modalSubmitValid = true;
    }
    else {
      this.sharedService.modalSubmitValid = false;
    }
  }

  amountValueChanged(event) {
    if (event && event.target.value <= 0) {
      this.uiHelperService.showSnackBar('Amount should be greater than 0.', SnackbarConfig.error);
      this.sharedService.modalSubmitValid = false;
    }
  }

}
