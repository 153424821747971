import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {

  constructor() {}

  getLocalStorageValue(property: string): any {
    const item = localStorage.getItem(property);
    if (item) {
      try {
        return JSON.parse(item);
      }
      catch (e) {
        return item;
      }
    }
    return null;
  }

  setLocalStorageValue(property: string, value: any) {
    localStorage.setItem(property, JSON.stringify(value));
  }

  removeItemFromLocalStorage(property: string) {
    localStorage.removeItem(property);
  }
}
