<div class="d-flex flex-align-row mt-2 mb-2 p-0">
  <h3 class="app-c-label pt-2">Contracts:</h3>
  <div class="ml-2">
    <div
      class="btn-group"
      style="width: 100%; min-height: 30px; min-width: 126px; width: 346px"
      dropdown
      container="body"
    >
      <button type="button" class="btn app-c-btn app-c-grid-action-btn-left">
        <label
          class="app-c-label"
          style="min-width: 105px; overflow: hidden; cursor: pointer"
        >
          {{ currentActiveContract ? currentActiveContract.name : '' }}
        </label>
      </button>
      <button
        id="button-basic "
        dropdownToggle
        type="button"
        class="btn app-c-btn dropdown-toggle app-c-popdown-btn app-c-grid-action-btn-right"
        aria-controls="dropdown-basic"
      ></button>
      <ul
        id="dropdown-basic "
        *dropdownMenu
        class="dropdown-menu popdown"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li
          role="menuitem "
          class="app-l-list"
          style="
            border-bottom: 1px solid #f3eded;
            box-shadow: 8px 9px 15px -2px rgba(240, 234, 234, 0.7);
          "
          *ngFor="let contract of contracts"
          (click)="contractChanged(contract)"
        >
          <a
            class="dropdown-item app-c-dropdown-item d-block active-dropdown"
            >{{ contract?.name }}</a
          >
        </li>
      </ul>
    </div>
  </div>

  <div
    class="ml-auto align-self-center"
    *wlmsIfPrivilege="[screenActionPrivileges.TrancheTabSavePreference]"
  >
    <a
      class="nounderscore"
      title="Save Preference"
      (click)="showUserPreferenceModal()"
      [class.disabled]="!sharedService.userPreferenceChanged"
    >
      <i class="icon-save header-icon"></i>
    </a>
  </div>
</div>

<div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
  <wlms-grid
    #tracheGrid
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [treeData]="true"
    [noRowsOverlayFlags]="noRowsOverlayFlags"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [getDataPath]="getDataPath"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [suppressRightClickPrivilege]="suppressRightClickPrivilege"
    [suppressActionPrivilege]="suppressActionPrivilege"
    [paginationPageSize]="paginationPageSize"
    (gridReady)="onGridReady()"
    (noOverlay)="noOverlay($event)"
    (edit)="openEditTranche($event)"
    (delete)="deleteTranche($event)"
    (add)="openAddTranche($event)"
    (copyTranche)="openWlCopyTranche($event)"
  >
  </wlms-grid>

  <div
    class="app-c-grid-add-column"
    *ngIf="authUtils.doesUserAllowedToPerform(screenActionPrivileges.EditTrancheTab)"
  >
    <span title="Add" (click)="addParentTranche()">
      <a class="nounderscore">
        <i class="icon-plus font-weight-bold">
          <span class="app-c-blue-circle"></span>
        </i>
      </a>
    </span>
  </div>
</div>
