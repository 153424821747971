import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { TabSetComponent } from './tab-set/tab-set.component';
import { MaterialModule } from '@wlms-web/material';
import { ActionColumnRendererComponent } from './grid/custom/action-column-renderer/action-column-renderer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  CustomDateRenderer, CustomDateTimeRenderer, ColorCellRenderer,
  IconFormatRendererComponent, ValidationMessageComponent
} from './grid/custom/framework-renderers/framework-renderers.component';
import { Overlay } from './grid/custom/overlay/overlay.component';
import { LoanInfoTileComponent } from './loan-info-tile/loan-info-tile.component';
import { UtilsModule } from '@wlms-web/utils';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { WarehouselineDropdownComponent } from './warehouseline-dropdown/warehouseline-dropdown.component';
import { AlertModalComponent } from './alert-modal/alert-modal-component';
import { FormsModule } from '@angular/forms';
import { NumericEditorComponent } from './grid/custom/cell-editors/numeric-editor.component';
import { DateEditorComponent } from './grid/custom/cell-editors/date-editor.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ToggleEditorComponent } from './grid/custom/cell-editors/toggle-editor.component';
import { ResetPreferenceComponent } from './reset-preference/reset-preference.component';
import { SavePreferenceComponent } from './save-preference/save-preference.component';
@NgModule({
  imports: [CommonModule,
    MaterialModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    UtilsModule,
    AgGridModule.withComponents([
      ActionColumnRendererComponent,
      CustomDateRenderer,
      ValidationMessageComponent,
      CustomDateTimeRenderer,
      Overlay,
      IconFormatRendererComponent,
      AlertModalComponent,
      ColorCellRenderer
    ]),],
  declarations: [GridComponent,
    WarehouselineDropdownComponent,
    TabSetComponent,
    LoanInfoTileComponent,
    ActionColumnRendererComponent, CustomDateRenderer, CustomDateTimeRenderer,
    ValidationMessageComponent,
    ColorCellRenderer,
    Overlay,
    IconFormatRendererComponent,
    ModalWrapperComponent,
    LoanInfoTileComponent,
    AlertModalComponent,
    NumericEditorComponent,
    DateEditorComponent,
    ToggleEditorComponent,
    ResetPreferenceComponent,
    SavePreferenceComponent,
  ],
  exports: [GridComponent,
    WarehouselineDropdownComponent, TabSetComponent, ActionColumnRendererComponent, CustomDateRenderer,
    CustomDateTimeRenderer,
    LoanInfoTileComponent,
    ValidationMessageComponent,
    ColorCellRenderer,
    Overlay,
    ModalWrapperComponent,
    IconFormatRendererComponent,
    AlertModalComponent]
})
export class UiKitModule { }
