import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '@wlms-web/utils';
import { ExportRequestAction } from 'libs/utils/src/lib/constants/data-constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportHistoryService } from '../../services/export-history.service';

@Component({
  selector: 'csv-generator-modal',
  templateUrl: './csv-generator-modal.component.html',
  styleUrls: ['./csv-generator-modal.component.scss']
})
export class CsvGeneratorModalComponent implements OnInit {
  public reExportForm: FormGroup;
  allWarehouseLineData = [];
  warehouseLineId: number;
  showspinner = false;
  selectedWarhouseLineData = [];
  hasCustodianFile = false;
  exportRequestAction: ExportRequestAction;
  @Input() data: any;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  subscription$: any;

  constructor(private exportHistoryService: ExportHistoryService, private modalService: BsModalService,
    private sharedService: SharedService) { }
  ngOnInit(): void {
    let pattern = /^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)?$/;
    this.reExportForm = new FormGroup({
      loanNumber: new FormControl('', [Validators.required, Validators.pattern(pattern)]),
      warehouseLineId: new FormControl('', [Validators.required]),
      skipValidation: new FormControl(),
      includeCustodianFile: new FormControl(),
      // skipEmpowerUpdates: new FormControl()
    });
    this.getWarehouseline();
    this.sharedService.modalSubmitValid = false;
  }
  getWarehouseline() {
    this.subscription$ = this.exportHistoryService.getWarehouseline().subscribe(response => {
      this.allWarehouseLineData = [...response['details']];
      this.allWarehouseLineData.sort(function (a, b) {
        var whlone = a.WarehouseLineName.toUpperCase();
        var whltwo = b.WarehouseLineName.toUpperCase();
        return (whlone < whltwo) ? -1 : (whlone > whltwo) ? 1 : 0;
      });
    });
  }
  enableExport(reExportFormValue) {
    if (reExportFormValue.warehouseLineId != '') {
      this.selectedWarhouseLineData = this.allWarehouseLineData.
        filter(w => w.WarehouseLineId == reExportFormValue.warehouseLineId);
      this.hasCustodianFile = this.selectedWarhouseLineData[0].HasCustodianFile
      if (!this.hasCustodianFile) {
        this.reExportForm.controls.includeCustodianFile.setValue(false);
      }
      if (reExportFormValue.loanNumber != '') {
        this.sharedService.modalSubmitValid = true;
        this.data.loanNumber = reExportFormValue.loanNumber;
        this.data.warehouseLineId = reExportFormValue.warehouseLineId;
        this.data.warhouseLineName = this.selectedWarhouseLineData[0].WarehouseLineName;
        this.data.skipValidation = !reExportFormValue.skipValidation;
        this.data.excludeCustodianFile = !reExportFormValue.includeCustodianFile;
        this.data.skipEmpowerUpdates = true;// !reExportFormValue.skipEmpowerUpdates
        this.data.exportRequestAction = ExportRequestAction.CsvGenerator;
      }
      else {
        this.sharedService.modalSubmitValid = false;
      }
    }
    else {
      this.sharedService.modalSubmitValid = false;
    }
  }
}
