import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidingMenuComponent } from './sliding-menu/sliding-menu.component';
import { AgGridModule } from 'ag-grid-angular';
import {UiKitModule} from '@wlms-web/ui-kit';
import {MaterialModule} from '@wlms-web/material'

@NgModule({
  imports: [CommonModule,
    AgGridModule,
    UiKitModule,
    MaterialModule],
  declarations: [SlidingMenuComponent],
  exports: [SlidingMenuComponent]
})
export class FeaturesPageLayoutModule { }
