import { Injectable } from '@angular/core';
import { ModalWrapperComponent } from './../modal-wrapper/modal-wrapper.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Modal, SnackbarConfig, NumericEditorValidators } from '@wlms-web/utils';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {
  bsModalRef: BsModalRef;
  modal: Modal;
  maxValueForNumericEditor = null;
  numericValidatorType = null;
  acceptableList = [];
  dateBoundary: any = {
    maxDate: 0,
    minDate: new Date()
  };


  constructor(private _snackBar: MatSnackBar, private modalService: BsModalService) {
    this.modal = new Modal();
  }

  showSnackBar(msg, style) {
    this._snackBar.open(msg, 'Dismiss',
      {
        horizontalPosition: SnackbarConfig.horizontalPosition,
        verticalPosition: SnackbarConfig.verticalPosition,
        duration: SnackbarConfig.duration,
        panelClass: [style]
      }
    );
  }

  askConfirmation(component, size, title, data, btnCancel, btnOk, successCallback, errorCallback?) {
    this.modal.title = title;
    this.modal.component = component;
    this.modal.buttonCancel = btnCancel;
    this.modal.buttonSuccess = btnOk
    this.modal.data = data
    const initialState = this.modal;
    this.bsModalRef = this.modalService.show(ModalWrapperComponent,
      {
        backdrop: 'static',
        keyboard: false,
        class: size,
        initialState: { initialState }
      });
    this.bsModalRef.content.clickevent.subscribe(($e) => {
      if ($e && $e.status) {
        successCallback($e.data);
      }
    });
    this.bsModalRef.content.closeEvent.subscribe(($e) => {
    });
  }
  
  updateSuccessButtonText(successBtnText){
    this.modal.buttonSuccess = successBtnText;
  }

  numericValidator(value, event?) {
    if (this.numericValidatorType == NumericEditorValidators.WarehouseLineTabValidator && this.maxValueForNumericEditor) {
      return value && this.maxValueForNumericEditor < parseFloat(value + event.key);
    } else if (this.numericValidatorType == NumericEditorValidators.TimeAvailablityTabValidator && this.maxValueForNumericEditor) {
      return value && ((value + event.key).length > this.maxValueForNumericEditor || event.key == '.');
    } else {
      return false;
    }
  }
}
