<div class="app-c-time-slot-wrapper" *ngIf="warehouseData">
  <div class="d-flex justify-content-between flex-wrap align-items-center">
    <div class="d-flex flex-column align-items-start">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Name</mat-label>
        <input
          matInput
          [(ngModel)]="warehouseData.WarehouseLineName"
          (ngModelChange)="modelUpdated()"
        />
      </mat-form-field>
    </div>

    <div class="d-flex flex-row align-items-center">
      <label class="pr-2">Federal Cut Off:</label>
      <timepicker
        [(ngModel)]="federalCutOff"
        (ngModelChange)="federalCutoffChange(federalCutOff)"
        minuteStep="1"
        [showSpinners]="false"
        ></timepicker>
      
    </div>

    <div class="d-flex flex-row align-items-center">
      <div class="check-list">
        <label class="cat-item d-flex align-items-center">
          <div class="mr-2">Table Funding</div>
          <input
            [disabled]="!data.isEdit || (!warehouseData.ShortCode && !warehouseData.VendorId)"
            type="checkbox"
            [checked]="warehouseData?.TableFunding"
            (click)="modelUpdated($event)"
          />
        </label>
      </div>
    </div>

    <div class="d-flex flex-column align-items-start">
      <mat-form-field class="mat-textarea whl-text-area" appearance="fill">
        <mat-label>Comments</mat-label>
        <textarea
          matInput
          [(ngModel)]="warehouseData.Comments"
          (ngModelChange)="modelUpdated()"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="d-flex flex-row justify-content-space-between">
  <h3 class="app-c-label-contract">Contracts</h3>
  <div
    class="ml-auto d-flex flex-row align-items-center app-c-add-wrapper"
    [class.disabled]="isAdd"
    (click)="addContract()"
  >
    <div class="app-c-btn">
      <i class="icon-plus"></i>
    </div>
  </div>
</div>

<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-100">
  <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
    <wlms-grid
      *ngIf="showGrid"
      class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1 time-slot-grid"
      #treeGrid
      id="treeGrid"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [treeData]="treeData"
      [getDataPath]="getDataPath"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [paginationPageSize]="5"
      [suppressRightClickPrivilege]="true"
      [suppressActionPrivilege]="true"
      [noRowsOverlayFlags]="noRowsOverlayFlags"
      [editType]="editType"
      (edit)="edit($event)"
      (save)="saveContract($event)"
      (undo)="undo($event)"
      (add)="addAmendment($event)"
      (delete)="delete($event)"
      (rowEditStopped)="onRowEditStopped($event)"
      (cellEditStopped)="onCellEditStopped($event)"
      (gridReady)="onGridReady()"
    >
    </wlms-grid>
    <div *ngIf="!showGrid" class="app-l-not-found min-h-200 no-data-found">
      <div class="app-l-not-found__text">
        <p>No Contracts Found!</p>
      </div>
    </div>
  </div>
</div>
