import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wlms-save-preference',
  templateUrl: './save-preference.component.html',
  styleUrls: ['./save-preference.component.scss']
})
export class SavePreferenceComponent implements OnInit {

  @Input() data: any;
  action: any;
  constructor() { }

  ngOnInit(): void {
    this.action = this.data.action
  }


  actionChange(e)
  {   
      this.data.action = this.action; 
    
  }

}
