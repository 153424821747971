<div class="modal-header">
  <label class="pull-left modal-title-h4">{{ data.title }}</label>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div #container></div>
</div>
<div class="modal-footer">
  <div class="modal-footer">
    <button
      *ngIf="data.buttonCancel"
      class="app-c-btn-cancel"
      (click)="closeModal()"
    >
      {{ data.buttonCancel }}
    </button>
    <button
      [class.disabled]="!sharedService.modalSubmitValid"
      *ngIf="data.buttonSuccess"
      class="app-c-btn-export"
      (click)="onSuccess()"
    >
      {{ data.buttonSuccess }}
    </button>
  </div>
</div>
