import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { LoanAssignmentService } from '../services/loan-assignment.service';
import {
  SharedService,
  Loandetails, NoRowsOverlayFlags, SnackbarConfig,
  Modal, LoanDetailTileList, LoanAssignmentActions,
  BrowserStorageService, GridMaster,
  StorageKey, ColourCodes, ScreenActionPrivileges, ExportWarehouseName, LOSList, AuthUtils
} from '@wlms-web/utils';
import { AssignExportModalComponent } from '../loan-assignment/assign-export-modal/assign-export-modal.component'
import { AlertModalComponent, UiHelperService } from '@wlms-web/ui-kit';
import * as _ from 'lodash';
import { TabConstantValue, LoanUpdateStatus, ExportRequestAction } from 'libs/utils/src/lib/constants/data-constants';
import { TimeCalculation } from 'libs/utils/src/lib/common/app-common-utils';
import { ExportResultModalComponent } from './export-result-modal/export-result-modal.component';
import * as moment from 'moment';
import { LoanlookupExceptionComponent } from './loanlookup-exception/loanlookup-exception.component';
import { Router } from '@angular/router';
import { AssignResultModalComponent } from '../loan-assignment/assign-result-modal/assign-result-modal.component';
import { ReviewloanResultModalComponent } from './reviewloan-result-modal/reviewloan-result-modal.component';
import { PushfundingResultModalComponent } from './pushfunding-result-modal/pushfunding-result-modal.component';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';
import { SavePreferenceComponent } from 'libs/ui-kit/src/lib/save-preference/save-preference.component';
import { indexOf } from 'lodash';
import { UpdateAdvanceDateModalComponent } from './update-advance-date-modal/update-advance-date-modal.component';
import { WarehouseLine } from './models/WarehouseLine';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'wlms-loan-assignment',
  templateUrl: './loan-assignment.component.html',
  styleUrls: ['./loan-assignment.component.scss']
})
export class LoanAssignmentComponent implements OnInit, AfterViewInit {
  paginationPageSize = 50;
  showspinner = false;
  rowData = [];
  columnDefs = [...Loandetails];
  allData = [];
  originalData = [];
  allWarehouseLineData: WarehouseLine[] = [];
  tabList: any = [{ name: "All" }];
  cloneTabList: any = [];
  subscription$: Subscription;
  openPanel = false;
  selectedLoan: any;
  selectedTab = 0;
  aggregates: any;
  warehouses = [];
  blankTemplate: any = '';
  selectedLoans: any = [];
  userDetails: any;
  loansReviewed: any;
  checktimeZero = false;
  minutesToDday;
  hoursToDday;
  daysToDday;
  TabConstantAll = TabConstantValue.ALL;
  TabConstantUnassigned = TabConstantValue.UNASSIGNED;
  isUserPreferenceChanged = false;
  tileList = [...LoanDetailTileList];
  loanAssignmentActions = [...LoanAssignmentActions];
  totalWireAmount: any;
  remaining: any;
  isRefresh: boolean = false;
  selectedIndices = [];
  failureMoveLoans: any = [];
  hasFailureLoans = false;
  failureMoveLoansIdx: any = [];
  @ViewChild('loanAssignmentGrid') loanAssignmentGrid: any;
  disablePreference = true;
  preferance: any[] = [];
  columnMaster = [];
  fundingCutoff: any;
  timeDifference: any;
  currentdate: any;
  timerSubscription: any;
  disableResetPreference = false;
  disableReProcessUpdates = false;
  screenActionPrivileges = ScreenActionPrivileges;
  suppressActionPrivilege = false;
  suppressRightClickPrivilege = false;
  noRowsOverlayFlags: any = { ...NoRowsOverlayFlags }.ISLOANASSIGNMENTS;
  filterPreferance: any[] = [];
  filterModel: any;
  WarehouseLines: any[] = [];
  isGridReloaded = false;
  loanstoExport: any;
  showpoup: number = 0;
  ferderaltime: string;
  previousselection: [];
  losList = [...LOSList];
  losSelected: any;
  warehouselineTabIndex: number = 0;
  testMode = false;
  rowPivotData= [];
  columnPivotDefs=[];   
  constructor(private loanAssignmentService: LoanAssignmentService,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private uiHelperService: UiHelperService,
    private browserStorageService: BrowserStorageService,
    public router: Router,
    private authUtils: AuthUtils,
    ) {
    this.suppressRightClickPrivilege = !this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.LoanAssignmentRightMouseClick);
    this.suppressActionPrivilege = !this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.LoanGridAction);
    this.losSelected = this.losList[0];
  }
  
  pivotData(data) {
    // Perform Aggregation
    //const aggregatedData: { [key: string]: { count: number; amount: number } } = {};
    let aggregatedData = {};
    for (const item of data) {
      var warehouse = '';
      if ( item.treasuryFunding.warehouseLine === null )
        warehouse = 'Unassigned';
      else
      {
        var w = this.WarehouseLines.find(a => a.warehouseLineId == item.treasuryFunding.warehouseLine);
        warehouse = w ? w.name : "Unknown";
      }
      
      if (aggregatedData[warehouse]) {
        aggregatedData[warehouse].count += 1;
        aggregatedData[warehouse].amount += item.loanAmount;
      } else {
        aggregatedData[warehouse] = { count: 1, amount: item.loanAmount };
      }
    }

    const formattedAggregatedData = Object.keys(aggregatedData).map((warehouseLine) => ({
      warehouseLine,
      count: aggregatedData[warehouseLine].count,
      amount: formatCurrency(aggregatedData[warehouseLine].amount,'en-US',getCurrencySymbol('USD', 'wide'),'USD')
    })).sort((a, b) => a.warehouseLine.localeCompare(b.warehouseLine));

    // Perform Tranformation
    const keys = Object.keys(formattedAggregatedData[0]);
    const transformedObjectList: any[] = [];

    for (const key of keys) {
      const transformedObject: { [key: string]: any } = {};
      for (const item of formattedAggregatedData) {
        transformedObject[item.warehouseLine] = item[key];
      }
      transformedObjectList.push(transformedObject);
    }

    // Preparing Colum Defs
    this.columnPivotDefs=[];
    const warehouseLineNames = Object.keys(transformedObjectList[0])
    warehouseLineNames.forEach(warehouseLineName => this.columnPivotDefs.push({ field: warehouseLineName, suppressMovable : true, width: 130 }));

    // Removing the first warehouse line element
    transformedObjectList.shift();
    this.rowPivotData = transformedObjectList;
  }  

  rowSelectionEvent(event) {
    this.selectedLoans = event;
    if (event.length > 0) {
      this.previousselection = event;
    }
  }

  onfilterChanged(event) {
    if (!this.isGridReloaded) {
      this.filterModel = event;
      this.filterPreferance = [];
      let getFilterPreference = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
      this.filterPreferance = getFilterPreference ? getFilterPreference : [];
      const idx = this.filterPreferance.findIndex((x: any) => x.tabId == this.selectedTab);
      if (idx >= 0) {
        this.filterPreferance[idx] = { ...this.filterPreferance[idx], filterData: this.filterModel }
      }
      else {
        this.filterPreferance.push({
          tabId: this.selectedTab,
          filterData: this.filterModel
        });
      }
      this.browserStorageService.setLocalStorageValue(StorageKey.FILTERMODEL, this.filterPreferance);
    }
  }

  openAssignExportModal() {
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      const name = this.tabList[this.selectedTab].name;
      let selected = this.getDataToAssign(false);
      const checkWarehouse = selected.find(x => x.warehouseLineId != this.tabList[this.selectedTab].warehouseLineId);
      if (checkWarehouse) {
        this.uiHelperService.showSnackBar('Warning: Export has been disabled. Please ensure loans selected are all for (' + name + ').', SnackbarConfig.warning);
      }
      else {
        this.showAssignExportModal();
      }

    }
    else {
      this.uiHelperService.showSnackBar('Please select loan(s) to assign', SnackbarConfig.error);
    }
  }

  openReviewModal() {
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      this.showReviewModal();
    }
    else {
      this.uiHelperService.showSnackBar('Please select loan(s) to mark as reviewed', SnackbarConfig.error);
    }
  }

  reviewLoans() {
    let request = {
      loanList: this.getDataToReview(true),
      userName: this.userDetails?.email
    }
    this.showspinner = true;
    this.loanAssignmentService.markReviewLoan(request).subscribe(
      (response: any) => {
        this.selectedLoans = [];
        if (response?.details?.length > 0) {
          if (response.details.every(l => l.status === true)) {
            let reviewResponse = response["details"].map(obj => ({ LoanNumber: obj.loanNumber, IsReviewed: true }));
            this.processReviewLoanResponse(reviewResponse);
            reviewResponse = reviewResponse.sort(function (a, b) {
              return a.LoanNumber < b.LoanNumber ? -1 : 1;
            });
            const reviewList = {
              responseDetails: reviewResponse
            };
            setTimeout(() => {
              this.uiHelperService.askConfirmation(ReviewloanResultModalComponent, 'modal-lg', `Mark review Status`,
                reviewList, '', 'Ok', () => {
                  this.uiHelperService.showSnackBar('Loan(s) marked as reviewed successfully', SnackbarConfig.success);
                });
              this.showspinner = false;
            }, 50)
          }
          else {
            this.showspinner = false;
            let message = response.details.find(l => l.statusMessage)?.statusMessage ?? 'Fail to mark loan as reviewed';
            this.uiHelperService.showSnackBar(message, SnackbarConfig.error);
          }
        }
      },
      (error) => {
        this.showspinner = false;
        if (error?.error?.outcomes?.failedValidation) {
          this.uiHelperService.showSnackBar(error?.error?.outcomes?.messages, SnackbarConfig.error);
        } else {
          let message = error?.error?.outcomes?.ErrorDescription ?? error?.message;
          this.uiHelperService.showSnackBar(message, SnackbarConfig.error);
        }
      }
    );
  }

  showReviewModal() {
    const title = this.tabList[this.selectedTab].name + ": Review Loans";
    const data = {
      "data": "You are about to mark " + this.selectedLoans.length + "  loan(s) as reviewed."
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Review', () => {
      this.reviewLoans();
    });
  }

  processReviewLoanResponse(reviewResponse) {
    this.isGridReloaded = true;
    reviewResponse.forEach((response: any) => {
      const index = this.allData.findIndex((x: any) => x.loanNumber == response.LoanNumber);
      if (index != -1) {
        this.allData[index].treasuryFunding.isReviewed = true;
      }
    });
    this.addColorColumn();
    this.rowData = this.getCurrentTabData(this.selectedTab);
    this.persistFilterData();
    this.loansReviewed = this.rowData.filter(a => a.treasuryFunding.isReviewed).length;
  }

  showAssignExportModal() {

    const warehouses: WarehouseLine = this.allWarehouseLineData.find(x => x.warehouseLineId == this.tabList[this.selectedTab].warehouseLineId);
    if (warehouses && warehouses.fundingCutoff && warehouses.federalCutoff) {
      const formattedFundingCutoff = new Date(this.currentdate.toLocaleDateString("en-us") + " " + warehouses.fundingCutoff).getTime();
      const formattedFederalCutoff = new Date(this.currentdate.toLocaleDateString("en-us") + " " + warehouses.federalCutoff).getTime();
      const formattedCurrentdate = this.currentdate.getTime();
      this.ferderaltime = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
        parseInt(warehouses.federalCutoff.split(':')[0]),
        parseInt(warehouses.federalCutoff.split(':')[1]))).format('hh:mm A');

      if (formattedCurrentdate > formattedFundingCutoff && formattedCurrentdate <= formattedFederalCutoff) {
        this.showpoup = 1;

      }
      else {
        this.showpoup = 0;
      }
    }
    const title = warehouses.warehouseLineName + ": Exporting";
    const data = {
      "warehousename": warehouses.warehouseLineName,
      "totalLoans": this.selectedLoans.length,
      "wireAmountTotal": this.calculateWireAmountTotal(),
      "remaining": this.calculateRemaining(),
      "percentage": this.calculateRemaningPercentage(),
      "cutoffMessage": this.showpoup,
      "federalCutoff": this.ferderaltime
    };
    this.uiHelperService.askConfirmation(AssignExportModalComponent, 'modal-lg', title, data, 'Cancel', 'Export', () => {
      this.exportAssignedLoans();
    });
  }

  calculateWireAmountTotal() {
    this.totalWireAmount = this.selectedLoans.map(a => a.wireAmount ? a.wireAmount : 0).reduce((a, b) => { return a + b; }, 0);
    return this.totalWireAmount;
  }

  calculateRemaining() {
    this.remaining = (this.tabList[this.selectedTab].aggregates.availableCapacity - this.totalWireAmount);
    return this.remaining;
  }

  calculateRemaningPercentage() {
    return (this.remaining / this.tabList[this.selectedTab].aggregates.availableCapacity) * 100;
  }

  getDataToAssign(isclear: any) {
    let selected = this.selectedLoans.map((l) => {
      return {
        loanNumber: l.loanNumber
        , warehouseLineId: l.treasuryFunding.warehouseLine
        , loanOriginationSystemName: l.treasuryFunding.loanOriginationSystem
      }
    });

    if (isclear) {
      this.selectedLoans = [];
      this.selectedIndices = [];
    }
    return selected;
  }

  getDataToReview(isclear: any) {
    let selected = this.selectedLoans.map((l) => {
      return {
        loanNumber: l.loanNumber
        , isReviewed: true
        , loanOriginationSystemName: l.treasuryFunding.loanOriginationSystem
      }
    });

    if (isclear) {
      this.selectedLoans = [];
      this.selectedIndices = [];
    }
    return selected;
  }

  getDataToUpdateAdvanceDate(isclear: any) {
    let selected = this.selectedLoans.map((l, index) => {
      return {
        loanNumber: l.loanNumber,
        advanceDate: moment(l.scheduledDate).startOf('day').utc(true).toISOString(),
        loanOriginationSystemName: l.treasuryFunding.loanOriginationSystem
      }
    });
    if (isclear) {
      this.selectedLoans = [];
      this.selectedIndices = [];
    }

    return selected;
  }

  apiAssigntoWarehouselines() {
    let assignLoanRequestData = {
      loanAssignments: this.getDataToAssign(true),
      userName: this.userDetails?.email
    }
    this.showspinner = true;
    this.loanAssignmentService.assignLoan(assignLoanRequestData).subscribe((response: any) => {
      let result = [...response['details']];
      if (result) {

        result = result.sort(function (a, b) {
          return a.Status - b.Status;
        });
        const assignList = {
          responseDetails: result
        };
        setTimeout(() => {
          this.uiHelperService.askConfirmation(AssignResultModalComponent, 'modal-lg', `Loan Assign Status`,
            assignList, '', 'Ok', () => {
            });
          this.showspinner = false;
          this.getLoans(() => {
            this.onLoanDataRetrived();
          });
        }, 50)
      }
      else {
        this.showspinner = false;
        this.uiHelperService.showSnackBar('Failed to Assign Loans', SnackbarConfig.error);
      }
    },
      (error) => {
        this.showspinner = false;
        if (error?.error?.outcomes?.failedValidation) {
          this.uiHelperService.showSnackBar(error?.error?.outcomes?.messages, SnackbarConfig.error);

        } else {
          this.uiHelperService.showSnackBar(error?.error?.outcomes?.ErrorDescription, SnackbarConfig.error);
        }
      });

  }

  exportAssignedLoans() {
    let loans = this.getDataToAssign(true);
    this.showspinner = true;
    let request = {
      loanBatches: [{
        los: this.losSelected.name,
        loanNumbers: loans.map(loan => { return loan.loanNumber }),
      }],
      WarehouseLineId: this.tabList[this.selectedTab].warehouseLineId,
      userName: this.userDetails?.email
    }
    this.loanAssignmentService.assignAndExportLoan(request).subscribe(response => {
      this.loanstoExport = [];
      let result = response['details'];
      if (result && result.exportResult) {
        this.isRefresh = true;
        const data = {
          "data": result.exportResult,
          "WarehouseLine": ExportWarehouseName[this.tabList[this.selectedTab].code]
        };
        setTimeout(() => {
          this.uiHelperService.askConfirmation(ExportResultModalComponent, 'modal-lg', `Export Status - ${this.tabList[this.selectedTab].name}`,
            data, '', 'Ok', () => {
            });
          this.showspinner = false;
          this.getLoans(() => {
            this.onLoanDataRetrived();
          });

        }, 50);
      }
      else {
        this.showspinner = false;
        this.uiHelperService.showSnackBar('Failed to Export Loans', SnackbarConfig.error);
        this.loanstoExport = [];
      }
    });
  }

  ngOnInit(): void {
    this.isRefresh = false;
    this.sharedService.modalSubmitValid = true;
    this.sharedService.userPreferenceChanged = false;
    this.userDetails = this.authUtils.getLoggedInUserDetails();
    this.getColumnMaster();
    this.subscription$ = this.sharedService.tabChange.subscribe((response: any) => {
      this.onTabChange(response)
      this.setPreference();
    });
    this.subscription$ = this.sharedService.slidePanelOpen.subscribe((response: any) => {
      if (response) {
        this.openPanel = true;
        this.selectedLoan = { ...response };
      }
    });

    this.setActions();
    this.getUserPreference();
    this.getWarehouseline();
    this.calculateCutoffTiming();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  onTabChange(response) {
    this.changeTabData(response);
    this.disableResetPreference = this.preferance.map((pref) => pref.tabName).includes(this.tabList[this.selectedTab].name);
    if (this.disableResetPreference) {
      this.columnDefs = [...Loandetails];
    }
    this.persistFilterData();
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    let currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabId === this.selectedTab)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.loanAssignmentGrid.setFilterModel(this.filterModel);
    }
    else {
      this.loanAssignmentGrid.setFilterModel(null);
    }
  }

  changeTabData(response) {
    this.sharedService.userPreferenceChanged = false;
    this.isRefresh = false;
    if (this.previousselection?.length > 0) {
      this.previousselection.forEach((response: any) => {
        const index = this.allData.findIndex((x: any) => x.loanNumber == response.loanNumber);
        const previousdata = this.originalData.find((x: any) => x.loanNumber == response.loanNumber);
        if (index != -1) {
          this.allData[index].treasuryFunding.warehouseLine = previousdata.treasuryFunding.warehouseLine;
        }
      });
    }
    this.previousselection = [];
    this.selectedLoans = [];
    this.selectedIndices = [];
    this.failureMoveLoansIdx = [];
    if (response.isLabelDisplayed) {
      this.selectedTab = response.index;
      this.onGridDataSourceUpdated();
    }
    this.loanAssignmentGrid.userPreferenceChanged = false;
  }

  onGridDataSourceUpdated() {
    this.rowData = this.getCurrentTabData(this.selectedTab);
    this.loansReviewed = this.rowData.filter(a => a.treasuryFunding.isReviewed).length;
    this.calculateCutoffTiming();
    this.setActions();
    this.setTile();
  }

  setActions() {
    let actions = [];
    this.loanAssignmentActions = [...LoanAssignmentActions];
    this.loanAssignmentActions.forEach((item, index) => {
      if (!item.excludeInTabs.includes(this.tabList[this.selectedTab].name) && this.authUtils.doesUserAllowedToPerform(item.privilegeKey)) {
        actions.push(item);
      }
    });
    this.loanAssignmentActions = [...actions];
  }

  setTile() {
    if (this.tabList[this.selectedTab].aggregates) {
      this.tileList.forEach((tile: any, index: any) => {
        switch (tile.label.toLocaleUpperCase()) {
          case 'Line Limit'.toLocaleUpperCase():
            this.tileList[index] = { ...tile, icon: this.tabList[this.selectedTab].icon ? this.tabList[this.selectedTab].icon : null, value: this.tabList[this.selectedTab].aggregates.availableCapacity };
            break;
          case 'Outstanding'.toLocaleUpperCase():
            this.tileList[index] = { ...this.tileList[index], value: this.tabList[this.selectedTab].aggregates.allocated };
            break;
          case 'Available'.toLocaleUpperCase():
            this.tileList[index] = { ...this.tileList[index], value: this.tabList[this.selectedTab].aggregates.remaining, remainingPercentage: this.tabList[this.selectedTab].aggregates.remainingPercentage };
            break;
          case 'Wire Amount Total'.toLocaleUpperCase():
            this.tileList[index] = { ...this.tileList[index], value: this.tabList[this.selectedTab].aggregates.wireAmount };
            break;
        }
      });
      this.tabList[this.selectedTab].viewCount = this.rowData.length;
    }
  }

  isTileExcluded(tile) {
    const tileIndex = tile.excludeInTabs.findIndex((element: any) => {
      return element.toUpperCase() === this.tabList[this.selectedTab].name.toUpperCase();
    });
    if (tileIndex != -1) {
      return true
    }
    return false;
  }

  setWarehouseLines() {
    this.WarehouseLines = this.allWarehouseLineData;
    this.WarehouseLines = this.WarehouseLines.map(obj => ({
      warehouseLineId: obj.warehouseLineId,
      name: obj.warehouseLineName,
      icon: obj.icon,
      code: obj.shortCode
    }));
    this.sharedService.warehouseLines = [...this.WarehouseLines, { name: 'Unassigned', icon: 'icons_slash.svg', code: null }];
    this.tabList = [{ name: "All" }, ...this.WarehouseLines, { name: 'Unassigned', icon: 'icons_slash.svg', code: null}];
    this.warehouselineTabIndex = 0;
    this.selectedTab = 0;
    const basePath = '../assets/images/';
    this.tabList.forEach((element, index) => {
      if (element.icon) {
        this.tabList[index] = { ...element, icon: basePath + element.icon };
      }
    });
    this.warehouses = [...this.tabList];
    this.warehouses.pop();
    this.warehouses.shift();
    this.getLoans(() => {
      this.onLoanDataRetrived();
    });
  }


  rereshLoans() {
    this.sharedService.modalSubmitValid = true;
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      const title = this.tabList[this.selectedTab].name + ": Refresh Loans(s) Confirmation";
      const data = {
        "data": "Loan(s) are currently selected. Please press Cancel if you would like to complete the action on the selected loans. Please press Refresh if you would like to continue."
      };
      this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Refresh', () => {
        this.refresh()
      });
    }
    else {
      this.refresh();
    }
  }

  refresh() {
    this.isRefresh = true;
    const currentFilter = this.loanAssignmentGrid.getGridApi().getFilterModel();
    this.rowData = [];
    this.loanAssignmentGrid.showloadingOverLay();
    this.getLoans(() => {
      this.loanAssignmentGrid.setFilterModel(currentFilter);
      this.uiHelperService.showSnackBar('Loan(s) Refreshed', SnackbarConfig.success);
    })
  }

  getLoans(callback) {
    this.isGridReloaded = true;
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    this.selectedLoans = [];
    this.subscription$ = this.loanAssignmentService.getLoans(this.losSelected.name, this.testMode).subscribe(response => {
      this.allData = [...response['details']]; //.map(obj => ({ ...obj, Variance: parseFloat(obj.Variance.replace('%', '')) }));
	  this.pivotData(this.allData);
      this.originalData = JSON.parse(JSON.stringify(this.allData));
      this.addColorColumn();
      this.rowData = this.getCurrentTabData(this.selectedTab);
      this.getAggregates();
      callback();
      this.sharedService.loadGrid.next({ isLoad: false });
      this.setPreference();
      this.persistFilterData();
      this.isGridReloaded = false;
      this.sharedService.loanData = this.allData;
      this.disableReProcessUpdates = !this.allData.some(loandata => loandata.treasuryFunding.isEligibleForReProcess);
      this.allData.forEach((x: any, index: any) => {
        x.LoanExported = (x.treasuryFunding.isFunded) ? 'YES' : 'NO';
      })
    });
  }

  addColorColumn() {
    this.allData.forEach((x: any, index: any) => {
      const colorSet = [];
      x.treasuryFunding.isReviewed ? colorSet.push('Reviewed') : '';
      x.Exception && x.Exception.toUpperCase() == 'YES' ? colorSet.push('Exception') : '';
      x.manualWireInstructions && x.manualWireInstructions.toUpperCase() == 'YES' ? colorSet.push('Manual Wire Instruction') : '';
      (x.wireAmount > (x.loanAmount + x.loanAmount * 0.1) || x.wireAmount < (x.loanAmount - x.loanAmount * 0.1)) ? colorSet.push('Variance') : '';
      x.doesFurtherCreditExist ? colorSet.push('Further Credit Instruction') : '';
      colorSet.length == 0 ? colorSet.push('Not Marked') : '';
      this.allData[index] = {
        ...x,
        color: colorSet
      };
    })
  }

  onLoanDataRetrived() {
    this.setPreference();
    this.loansReviewed = this.rowData.filter(a => a.treasuryFunding.isReviewed).length;
    this.tabList[this.selectedTab].viewCount = this.rowData.length;
  }


  getWarehouseline() {
    this.subscription$ = this.loanAssignmentService.getWarehouseline(this.losSelected.name).subscribe(response => {
        this.allWarehouseLineData = [...response.details];
        this.setWarehouseLines();
    });
  }

  calculateCutoffTiming() {
    const title = this.tabList[this.selectedTab].name
    if (title.toUpperCase() != this.TabConstantAll.toUpperCase() && title.toUpperCase() != this.TabConstantUnassigned.toUpperCase()) {
      const warehouses: any = this.allWarehouseLineData.find(x => x.warehouseLineId == this.tabList[this.selectedTab].warehouseLineId);
      if (warehouses && warehouses.FundingCutoff) {
        this.fundingCutoff = warehouses.FundingCutoff;
        this.subscription$ = this.loanAssignmentService.getPstCurrentDate().subscribe(response => {
          this.currentdate = new Date(response.details);
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }

          this.timerSubscription = interval(1000).subscribe(x => {
            const formattedFundingCutoff = new Date(this.currentdate.toLocaleDateString("en-us") + " " + this.fundingCutoff);
            this.timeDifference = formattedFundingCutoff.getTime() - this.currentdate.getTime();
            const calculatedValue = TimeCalculation.allocateTimeUnits(this.timeDifference);
            if (calculatedValue) {
              this.hoursToDday = calculatedValue.hoursToDday;
              this.minutesToDday = calculatedValue.minutesToDday;
              this.checktimeZero = calculatedValue.checktimeZero;
              this.daysToDday = calculatedValue.daysToDday;
            }
            this.currentdate.setSeconds(this.currentdate.getSeconds() + 1);
          });
        });
      }
    }
  }

  getAggregates() {
    // this.subscription$ = this.loanAssignmentService.getAggregates().subscribe(response => {
    //   if (response) {
    //     this.updateTabList(response['aggregates']);
    //     this.setTile();
    //   }
    // });

    this.updateTabList([]);
    this.setTile();
  }

  updateTabList(aggregates: any) {
    const allTab = this.tabList.findIndex((ag: any) => {
      return ag.name == 'All';
    });
    if (allTab != -1) {
      this.updateAllTab(aggregates, allTab);
    }
    this.tabList.forEach((tab: any, index: any) => {
      const aggregate = aggregates.filter((ag: any) => {
        return tab.name == ag.name;
      });
      if (this.tabList[index].name != 'All') {
        this.updateWarehouseLineTabs(aggregate, index);
      }
    });
  }

  updateWarehouseLineTabs(aggregate, index) {
    this.tabList[index] = {
      ...this.tabList[index],
      aggregates: {
        availableCapacity: aggregate[0]?.availableCapacity,
        allocated: aggregate[0]?.allocated,
        remaining: aggregate[0]?.remaining,
        wireAmount: aggregate[0]?.wireAmount,
        remainingPercentage: aggregate[0]?.availableCapacity > 0 ? Math.ceil((aggregate[0]?.remaining / aggregate[0]?.availableCapacity) * 100) : 0
      },
      viewCount: this.getCurrentTabData(index).length
    };
  }

  updateAllTab(aggregates, allTab) {
    this.tabList[allTab] = {
      ...this.tabList[allTab],
      aggregates: {
        availableCapacity: aggregates.map(a => a.availableCapacity).reduce((a, b) => { return a + b; }, ''),
        allocated: aggregates.map(a => a.allocated).reduce((a, b) => { return a + b; }, ''),
        wireAmount: aggregates.map(a => a.wireAmount).reduce((a, b) => { return a + b; }, ''),
        remaining: aggregates.map(a => a.remaining).reduce((a, b) => { return a + b; }, '')
      }
    };
    this.tabList[allTab] = {
      ...this.tabList[allTab],
      aggregates: { ...this.tabList[allTab].aggregates, remainingPercentage: Math.floor((this.tabList[allTab].aggregates.remaining / this.tabList[allTab].aggregates.availableCapacity) * 100) },
      viewCount: this.getCurrentTabData(allTab).length
    };
    this.tabList[allTab] = { ...this.tabList[allTab], viewCount: this.getCurrentTabData(allTab).length };
  }

  getCurrentTabData(tabIndex: any) {
    let currentTabData: any = [];
    if (tabIndex == 0) {
      currentTabData = [...this.allData];
    } else if (!this.tabList[tabIndex].warehouseLineId) {
      currentTabData = this.allData.filter((element: any) => {
        return ((element.treasuryFunding.warehouseLine == null || element.treasuryFunding.warehouseLine == '0'));

      });
    } else {
      currentTabData = this.allData.filter((element: any) => {
        return element.treasuryFunding.warehouseLine
          ? element.treasuryFunding.warehouseLine == this.tabList[tabIndex].warehouseLineId
          : this.tabList[tabIndex].warehouseLineId == null;
      });
    }
    return currentTabData;
  }

  onOptionSelected(event) {
    this.sharedService.modalSubmitValid = true;
    if (this.selectedLoans && this.selectedLoans.length <= 0) {
      this.uiHelperService.showSnackBar('No loan number(s) have been selected', SnackbarConfig.error);
    }
    else {
      switch (event.internalName) {
        case 'AssignAndExport': this.openAssignExportModal();
          break;
        case 'PushBackFundingQueue': this.openPushBackToFundingModal();
          break;
        case 'MarkReviewed': this.openReviewModal();
          break;
        case 'Assign': this.confirmReassignment();
          break;
        case 'UpdateAdvanceDate': this.openAdvanceDateModal();
          break;
      }
    }
  }

  openPushBackToFundingModal() {
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      this.showPushBackToFundingModal();
    }
    else {
      this.uiHelperService.showSnackBar('Please select loan(s) to mark as Push Back To Funding Queue', SnackbarConfig.error);
    }
  }
  showPushBackToFundingModal() {
    const title = this.tabList[this.selectedTab].name + ": Push Back To Funding Queue";
    const data = {
      "data": "You are about to mark " + this.selectedLoans.length + "  loan(s) as Push Back To Funding Queue."
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Push To Funding', () => {
      this.pushBackToFundingQueue();
    });
  }

  pushBackToFundingQueue() {
    this.showspinner = true;

    var selectedLoansData = this.getDataToAssign(true);

    const groupedData = _(selectedLoansData)
      .groupBy(selectedLoan => selectedLoan.loanOriginationSystemName)
      .map(function (selectedLoansData, los) { return { los: los, loanNumbers: selectedLoansData.map(loan => { return loan.loanNumber }) }; })
      .value();

    let pushBackLoanListRequestData = {
      pushBackLoanList: groupedData,
      userName: this.userDetails?.email
    }

    this.loanAssignmentService.pushBackToFundingQueue(pushBackLoanListRequestData).subscribe(response => {
      if (response) {
        this.selectedLoans = [];
        this.selectedIndices = [];
        this.failureMoveLoans = [];
        this.failureMoveLoansIdx = [];
        let responseDetails = response.details;
        debugger;
        const failureCount = responseDetails.filter(x => x.status == false).length;
        const successCount = responseDetails.filter(x => x.status == true).length;
        let failureLoans = responseDetails.filter(x => x.status == false);
        this.failureMoveLoans = failureLoans.map(loan => { return loan.loanNumber });
        if (parseInt(failureCount) > 0 && parseInt(successCount) == 0) {
          this.hasFailureLoans = true;

        }
        if (parseInt(failureCount) > 0 && parseInt(successCount) > 0) {
          this.hasFailureLoans = true;
        }
        responseDetails = responseDetails.sort(function (a, b) {
          return (a.status.toUpperCase() < b.status.toUpperCase()) ? -1 : (a.status.toUpperCase() > b.status.toUpperCase()) ? 1 : 0;
        });
        const assignList = {
          responseDetails: responseDetails
        };
        setTimeout(() => {
          this.uiHelperService.askConfirmation(PushfundingResultModalComponent, 'modal-lg', `Push Back To Funding Status`,
            assignList, '', 'Ok', () => {
            });
          this.showspinner = false;
          this.getLoans(() => {
            this.loansReviewed = this.rowData.filter(a => a.treasuryFunding.isReviewed).length;
            this.pushBackToFundingFailureMove();
            this.tabList[this.selectedTab].viewCount = this.rowData.length;
          });

        }, 50)
      }
      else {
        this.showspinner = false;
        this.uiHelperService.showSnackBar('Fail to push back to funding ', SnackbarConfig.success);
      }
    });
  }

  pushBackToFundingFailureMove() {
    if (this.hasFailureLoans) {
      this.failureMoveLoans.forEach((item) => {
        const index = this.rowData.findIndex((x: any) => x.LoanNumber == item);
        this.failureMoveLoansIdx.push(index)
      });
    }
  }
  onWarehouselineChanged(event) {
    this.selectedIndices = [];
    const columnState = this.loanAssignmentGrid.getColumnState();
    let groupIndex = _.findIndex(columnState, (state: any) => state.rowGroup);
    const loanExist = this.selectedLoans.map((loan) => loan.loanNumber).includes(event.loanDetails[0].loanNumber);
    const selectedNodes = this.loanAssignmentGrid.gridApi.getSelectedNodes();
    this.reCheckSelectedRows(selectedNodes, loanExist, event);
    if (!loanExist) {
      this.newLoanSelection(event, groupIndex);
    }
    this.persistFilterData();
    this.columnDefs = this.loanAssignmentGrid.getGridColumnDefs();
	this.pivotData(this.rowData); 
  }

  reCheckSelectedRows(selectedNodes, loanExist, event) {
    _.forEach(selectedNodes, (node) => {
      this.selectedIndices.push(node.id);
      if (loanExist) {
        node.data.treasuryFunding.warehouseLine = event.warehouseLineId;
      }
    });
  }

  newLoanSelection(event, groupIndex) {
    if (groupIndex != -1) {
      const rowNodes = this.loanAssignmentGrid.gridApi.rowModel.rowsToDisplay;
      rowNodes.forEach(node => {
        this.setSelectedIndices(node, event);
      });
    } else {
      this.loanAssignmentGrid.gridApi.forEachNode(node => {
        this.setSelectedIndices(node, event);
      });
    }
  }

  setSelectedIndices(node, event) {
    if (node.data && event.loanDetails[0].loanNumber == node.data.loanNumber) {
      node.data.treasuryFunding.warehouseLine = event.warehouseLineId;
      this.selectedIndices.push(node.id);
    }
  }

  confirmReassignment() {
    this.isRefresh = false;
    const title = 'Confirm Warehouseline Reassignment'
    const data = {
      data: 'You are about to reassign the warehouse line for ' + this.selectedLoans.length + ' loan(s).'
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Assign', () => {
      this.apiAssigntoWarehouselines();
    });
  }

  collectUserPreferences(tabListForPreferences: string[]) {
    this.preferance = [];
    const columnState = this.loanAssignmentGrid.getColumnState();

    _.forEach(tabListForPreferences, (tabName: any) => {
      _.forEach(columnState, (state: any, index: Number) => {
        let columnMasterId = null;
        const columnIndex = _.findIndex(this.columnDefs, (col) => {
          return col.field == state.colId;
        });
        if (columnIndex != -1) {
          const col = { ...this.columnDefs[columnIndex] };
          columnMasterId = col['ColumnMasterId'];
          if (columnMasterId > 0) {
            this.preferance.push({
              tabName: tabName,
              columnMasterId: columnMasterId,
              columnOrder: index,
              sortOrder: state.sort,
              groupOrder: (state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex) ?? 0, 
              isVisible: !state.hide
            });
          }
        }
      });
    });
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.tabName == this.tabList[this.selectedTab].name;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.columnMasterId == col.ColumnMasterId;
        });
      let column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...Loandetails];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].sortOrder;
      column['ColumnOrder'] = prefColumn[0].columnOrder;
      column['hide'] = !prefColumn[0].isVisible;
      if (prefColumn[0].groupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].groupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  getColumnMaster() {
    this.loanAssignmentService.getColumnMaster(GridMaster.LoanAssignment).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      let masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.columnName?.toLowerCase() == col.field?.toLowerCase();
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].columnMasterId;
      }
    });
  }

  showUserPreferenceModal() {
    this.sharedService.modalSubmitValid = true;
    const title = this.tabList[this.selectedTab].name + ": Save User Preferences";
    const data = {
      "message": "You are about to save " + this.tabList[this.selectedTab].name + "  tab User Preference.",
      action: 0,
    };
    this.uiHelperService.askConfirmation(SavePreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Save Preference', (data: any) => {
      this.saveUserPreferences(data.data.action);
    });
  }

  saveUserPreferences(action) {
    if (!this.isUserPreferenceChanged) {
      var tabListForPreferences = action == 0 ? [this.tabList[this.selectedTab].name] : _.map(this.tabList, 'name'); 
      this.reorderColumnMaster();
      this.collectUserPreferences(tabListForPreferences);
    }

    const request = {
      userName: this.userDetails?.email,
      gridName: GridMaster.LoanAssignment,
      columnPreferencesList: this.preferance,
    };
    this.loanAssignmentService.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.loanAssignmentGrid.userPreferenceChanged = false;
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference(() => this.setPreference);
        this.disableResetPreference = true;
      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getUserPreference(callback?) {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetLoanSavePreference)) {
      this.loanAssignmentService.getUserPreferences(this.userDetails?.email, GridMaster.LoanAssignment).subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.disableResetPreference = this.preferance.map((pref) => pref.tabName).includes(this.tabList[this.selectedTab].name);
          callback ? callback() : '';
        }
      });
    }
  }

  showResetUserPreferenceModal() {
    this.sharedService.modalSubmitValid = true;
    const title = this.tabList[this.selectedTab].name + ": Reset Columns to the default view";

    const data = {
      "message": "You are about to reset " + this.tabList[this.selectedTab].name + "  Columns to the default/userPreference View.",
      enablePreference: this.disableResetPreference,
      action: this.disableResetPreference ? 1 : 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });

  }

  resetUserPreference(action) {

    if (action == 0) {
      const request = {
        userName: this.userDetails?.email,
        gridName: GridMaster.LoanAssignment,
        tabName: this.tabList[this.selectedTab].name
      };
      const currentFilter = this.loanAssignmentGrid.getGridApi().getFilterModel();
      this.loanAssignmentService.ResetUserPreference(request).subscribe((response: any) => {
        let responseDetails = response.details;
        if (responseDetails.status) {
          this.disableResetPreference = false;
          this.sharedService.userPreferenceChanged = false;
          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference(() => {
            this.getLoans(() => {
              this.loanAssignmentGrid.setRowData(this.rowData);
              this.columnDefs = [...Loandetails];
              this.loanAssignmentGrid.setFilterModel(currentFilter);
            })
          });
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else {
      const currentFilter = this.loanAssignmentGrid.getGridApi().getFilterModel();
      this.getUserPreference(() => {
        this.getLoans(() => {
          this.loanAssignmentGrid.setRowData(this.rowData);
          this.columnDefs = [...Loandetails];
          this.loanAssignmentGrid.setFilterModel(currentFilter);
        })
      });

      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }

  }

  noOverlay(event) {
    this.loanAssignmentGrid.grid.gridOptions.noRowsOverlayComponentParams = {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'isLoanAssignments',
        };
      }
    };
  }

  loanLookUp() {
    const data = {
      isEdit: false,
      loanNumber: '',
      losSelected: this.losSelected,
      testMode: this.testMode,
      addException: false
      //indexList: this.indexList
    };
    const title = 'Loan LookUp & Add Exception';
    this.uiHelperService.askConfirmation(LoanlookupExceptionComponent, 'modal-lg', title, data, 'Cancel', 'Add Exception', (data: any) => {
      this.updateLoanException(data.data);
    });
  }

  updateLoanException(data) {
    var request = {
      loanNumber: data.loanNumber,
      loanOriginationSystem: data.losSelected.name,
      enable: data.addException 
    }

    this.loanAssignmentService.updateLoanException(request).subscribe(response => {
      let responseDetails = response.details;
      if (responseDetails?.status) {
        this.isRefresh = true;
        this.uiHelperService.showSnackBar('Loan exception has been updated successfully.', SnackbarConfig.success);

        this.getLoans(() => {
          this.onLoanDataRetrived();
        });
      }
      else {

        this.uiHelperService.showSnackBar(`This loan does meet the criteria for an Exception: ${responseDetails.statusMessage}`, SnackbarConfig.error);
      }
    });
  }

  showReprocessLoanUpdatesModal() {
    const title = "Reprocess Pending Loan Updates";
    const data = {
      "data": "Are you sure to reprocess all pending loans for updates?"
    };
    this.sharedService.modalSubmitValid = true;
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Submit', () => {
      this.reprocessLoanUpdates();
    });
  }

  reprocessLoanUpdates() {
    this.showspinner = true;
    let request = {
      loanOriginationSystem: this.losSelected.name,
      userName: this.userDetails?.email
    };
    this.loanAssignmentService.reprocessLoanUpdates(request).subscribe((response: any) => {
      if (response) {
        this.showspinner = false;
        this.uiHelperService.showSnackBar('Loan updates has been sucessfully pushed to the queue for reprocessing.', SnackbarConfig.success);
      }
    }, (error) => {
      this.showspinner = false;
      this.uiHelperService.showSnackBar('Failed to reprocess loan updates', SnackbarConfig.error);
    });
  }
  onIsRowSelectable(rowNode) {
    return rowNode.data ? !(rowNode.data.treasuryFunding.isFunded && rowNode.data.treasuryFunding.loanFundingUpdateStatus != LoanUpdateStatus.Success) : false;
  }

  onGetRowClass(params) {
    if (params.data) {
      if (params.data.treasuryFunding.isEligibleForReProcess) {
        return 'app-exported-reprocess-loans';
      }
      else if (params.data.treasuryFunding.loanFundingUpdateStatus == LoanUpdateStatus.InProgressWithTreasuryTool) {
        return 'app-treasury-loan-updates';
      }
      else if (params.data.treasuryFunding.loanFundingUpdateStatus == LoanUpdateStatus.InProgress) {
        return 'app-exported-loans';
      }
    }
  };
  openAdvanceDateModal() {
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      let selected = this.getDataToUpdateAdvanceDate(false);
      this.uiHelperService.askConfirmation(UpdateAdvanceDateModalComponent, 'modal-lg', `Update Advance Date`, selected, 'Cancel', 'Update', () => {
        this.apiAdvanceDate();
      });
    }
    else {
      this.uiHelperService.showSnackBar('Please select loan(s) to update advance date', SnackbarConfig.error);
    }
  }

  apiAdvanceDate() {
    let loanDataToUpdateUpdateAdvanceDate = {
      updateLoanAdvanceDateList: this.getDataToUpdateAdvanceDate(true),
      userName: this.userDetails?.email
    }

    this.showspinner = true;
    this.loanAssignmentService.updateAdvanceDate(loanDataToUpdateUpdateAdvanceDate).subscribe((response: any) => {
      let result = [...response['details']];
      if (result) {

        result = result.sort(function (a, b) {
          return a.Status - b.Status;
        });
        const assignList = {
          responseDetails: result
        };
        setTimeout(() => {
          this.uiHelperService.askConfirmation(AssignResultModalComponent, 'modal-lg', `Update Advance Date Status`,
            assignList, '', 'Ok', () => {
            });
          this.showspinner = false;
          this.getLoans(() => {
            this.onLoanDataRetrived();
          });
        }, 50)
      }
      else {
        this.showspinner = false;
        this.uiHelperService.showSnackBar('Failed to Assign Loans', SnackbarConfig.error);
      }
    },
      (error) => {
        this.showspinner = false;
        if (error?.error?.outcomes?.failedValidation) {
          this.uiHelperService.showSnackBar(error?.error?.outcomes?.messages, SnackbarConfig.error);

        } else {
          this.uiHelperService.showSnackBar(error?.error?.outcomes?.ErrorDescription, SnackbarConfig.error);
        }
      });

  }

  onCellEditStopped(event) {

    let statusCheck = true;
    const loanExist = this.selectedLoans.map((loan) => loan.loanNumber).includes(event.data.loanNumber);
    if (event && event.column && event.column.colId && event.column.colId == 'scheduledDate') {

      if (moment(event.data.scheduledDate).startOf('day').utc(true).toISOString() < moment(new Date()).startOf('day').utc(true).toISOString()) {
        this.uiHelperService.showSnackBar("AdvanceDate should to greater than or equal to current date", SnackbarConfig.error);
        statusCheck = false;
        event.data[event.colDef.field] = event.oldValue;
        if (loanExist) {

          this.selectedIndices.splice(Number(event.node.id), 1)
        }
      }
    }

    if (!loanExist && statusCheck) {
      this.selectedIndices.push(event.node.id);
    }

    this.loanAssignmentGrid.setSelected();
    this.loanAssignmentGrid.redrawRows();
  }

  onTestLoanChanged(event) {
    this.testMode = event.target.checked;
    this.getLoans(() => {
      this.onLoanDataRetrived();
    });

    //this.getWarehouseline();
    //this.calculateCutoffTiming();    
  }

  onLosSelected(event) {
    this.sharedService.modalSubmitValid = true;
    if (this.selectedLoans && this.selectedLoans.length > 0) {
      const title = "Change LOS Confirmation";
      const data = {
        "data": "Loan(s) are currently selected. Please press Cancel if you would like to complete the action on the selected loans. Please press Change LOS if you would like to continue."
      };
      this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-lg', title, data, 'Cancel', 'Change LOS', () => {
        this.losSelected = event;
        this.getWarehouseline();
        this.calculateCutoffTiming();
      });
    }
    else {
      this.losSelected = event;
      this.getWarehouseline();
      this.calculateCutoffTiming();
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
