import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, EventMessage, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Subject, interval } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { StorageKey, AuthUtils, BrowserStorageService, ScreenActionPrivileges, SnackbarConfig } from '@wlms-web/utils';
import { UiHelperService } from '@wlms-web/ui-kit';

@Component({
  selector: 'wlms-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private authUtils: AuthUtils,
    private browserStorageService: BrowserStorageService,
    private locationObj: Location,
    private route: ActivatedRoute,
    private uiHelperService: UiHelperService,
    ) { }

  @HostListener('window:storage')
  onStorageChange() {
    location.reload();
  }
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.checkAndSetActiveAccount();
      });


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                                      || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                                      || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);

        this.browserStorageService.setLocalStorageValue(StorageKey.AUTHTOKEN, payload.idToken)
        this.setTimer();
        this.getUserPrivileges();
      });

      const activeAccount = this.authService.instance.getActiveAccount();
      if (activeAccount ) {
        this.setTimer();
        this.getUserPrivileges();
      }
  }

  getUserPrivileges(){
    this.authUtils.getUserPrivileges().subscribe(response => {
      if (response) {
        this.browserStorageService.setLocalStorageValue(StorageKey.PRIVILEGES, JSON.stringify(response.details))
      }

      this.handleLandlePage();
    },
    (error) => {
      if (error?.error?.outcomes?.failedValidation) {
        this.uiHelperService.showSnackBar(error?.error?.outcomes?.messages, SnackbarConfig.error);

      } else {
        this.uiHelperService.showSnackBar(error?.error?.outcomes?.ErrorDescription, SnackbarConfig.error);
      }
    });
  }

  handleLandlePage() {
    if (this.locationObj.path() == '') {
      if (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.LoanAssignment)) {
        this.router.navigate(['/loan-assignment']);
      } else if (this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.Warehouseline)) {
        this.router.navigate(['/warehouselines']);
      }
    }
    else {
      const returnUrl = this.route.snapshot.queryParams['returnUrl'];

      if (returnUrl) {
        this.router.navigate([returnUrl]);
      }
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

setTimer(){
  interval(10 * 1000).subscribe(() => {
    const activeAccount = this.authService.instance.getActiveAccount();

    const exp = activeAccount?.idTokenClaims?.exp;
    const expTime = moment(moment.unix(exp));
    const currTime = moment();

    const timeDiff = moment(expTime).diff(moment(currTime), 'seconds')

    const isExpired = timeDiff <= 0;

    if(isExpired){
      this.getSilentToken();
    }
  });
}

  getSilentToken() {
    const request = {
      scopes: ["User.Read"],
    };

    this.authService.instance.acquireTokenSilent(request).then(tokenResponse => {
      // Do something with the tokenResponse
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.authService.instance.acquireTokenRedirect(request)
      }

      // handle other errors
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}