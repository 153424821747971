import * as moment from "moment";

export const UpdateAdvanceDate = [
    {
        "field": "loanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 400,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "advanceDate",
        "headerName": "Advance Date",
        "width": 400,      
        detailValueFormatters: ['date'],
        detailCategory: 3,
        "filter": "agDateColumnFilter",
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true,
        cellRenderer: (data) => {
            return moment(data.value.split('T')[0]).format('MM/DD/YYYY');
           }
      },
]



