export const LoanResult = [
    {
        "field": "loanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 150,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
        
        },
    {
        "field": "status",
        "headerName": "Status",
        "filter": "agSetColumnFilter",
        "width": 150,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "statusMessage",
        "headerName": "Response",
        "filter": "agTextColumnFilter",
        "width": 400,
        wrapText: true,
        autoHeight: true,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    }
]