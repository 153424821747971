import { Component, Input, OnInit } from '@angular/core';
import { UiHelperService } from '@wlms-web/ui-kit';
import { SharedService, SnackbarConfig } from '@wlms-web/utils';
import * as moment from 'moment';
import { WarehouseLinesService } from '../../../services/whl.service';
@Component({
  selector: 'wlms-copy-whl-contract',
  templateUrl: './copy-whl-contract.component.html',
  styleUrls: ['./copy-whl-contract.component.scss']
})
export class CopyWhlContractComponent implements OnInit {
  @Input() data;
  currentActiveContract: any;
  contracts = [];
  minDate = new Date();
  startDate = new Date();
  endDate = new Date();
  bsConfig = { containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' };
  constructor(private warehouseLinesService: WarehouseLinesService,
    public sharedService: SharedService,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    this.getContracts();
  }

  // Get contracts
  getContracts() {
    this.warehouseLinesService.getContractsPerWarehouse(this.data.modalData.WarehouseLineId).subscribe(response => {
      if (response) {
        this.contracts = response['details'];
        this.setActiveContract();
      }
    });
  }

  setActiveContract() {
    this.contracts.forEach((x, index) => {
      this.contracts[index] = {
        ...x, name: x.WarehouseLineName + '  ' + moment(new Date(x.StartDate)).format('MM/DD/YYYY') + ' - ' +
          moment(new Date(x.ExpiryDate)).format('MM/DD/YYYY')
      }
    });
    const index = this.contracts.findIndex(x => {
      if (moment(new Date()).isSame(x.StartDate, 'day') ||
        moment(new Date()).isSame(x.ExpiryDate, 'day') ||
        moment(new Date()).startOf('day').isBetween(x.StartDate, x.ExpiryDate, undefined, '[]')) {
        return x;
      }
    });
    if (index != -1) {
      this.currentActiveContract = { ...this.contracts[index] };
      this.data.modalData.contractId = this.currentActiveContract.WarehouseLineContractDetailId;
      this.setStarDateandEndDate(this.currentActiveContract.ExpiryDate);
    } else {
      this.currentActiveContract = null;
    }
  }

  setStarDateandEndDate(date) {
    this.data.modalData.StartDate = moment(moment(date).add(1, 'd')).toDate();
    this.data.modalData.ExpiryDate = moment(moment(date).add(1, 'y')).toDate();
  }
  // On contract changed
  contractChanged(contract) {
    this.currentActiveContract = { ...contract };
    this.data.modalData.contractId = this.currentActiveContract.WarehouseLineContractDetailId;
    this.setStarDateandEndDate(this.currentActiveContract.ExpiryDate);
  }

  dateChange(ctrl, event) {
    event == null ? '' : event;
    if (event == null)
      this.sharedService.modalSubmitValid = false;
    else {
      this.sharedService.modalSubmitValid = true;
      if (ctrl == 'start' && event && moment(event).startOf('day').isAfter(moment(this.data.modalData.ExpiryDate).startOf('day'))) {
        this.uiHelperService.showSnackBar('Start date should be lesser than end date.', SnackbarConfig.error);
        this.sharedService.modalSubmitValid = false;
        return;
      } 
      else if (ctrl != 'start' && event && moment(event).startOf('day').isBefore(moment(this.data.modalData.StartDate).startOf('day'))) {
        this.uiHelperService.showSnackBar('Start date should be lesser than end date.', SnackbarConfig.error);
        this.sharedService.modalSubmitValid = false;
        return;
      } 
      else {
        this.sharedService.modalSubmitValid = true;
      }
    }
  }
}
