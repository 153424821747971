<!-- <p>daily-caps works!</p> -->
<wlms-grid
  #dailyCapsGrid
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [paginationPageSize]="paginationPageSize"
  [suppressRightClickPrivilege]="suppressRightClickPrivilege"
  [suppressActionPrivilege]="suppressActionPrivilege"
  [noRowsOverlayFlags]="noRowsOverlayFlags"
  [treeData]="false"
  (edit)="openEditDailyCaps($event)"
  (noOverlay)="noOverlay($event)"
>
</wlms-grid>
