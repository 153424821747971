import { CurrencyFormatter, DateFormattor } from '../gridutils/grid-utils';
export const ExportHistory = [
  {
    'headerName': '',
    "field": "rowCheck",
    "checkboxSelection": true,
    "headerCheckboxSelection": true,
    "headerCheckboxSelectionFilteredOnly": true,
    "active": true,
    "width": 40,
    cellStyle: { left: '0px', 'z-index': '0' },
    "pinned": "left",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressFiltersToolPanel": true,
    "suppressColumnsToolPanel": true,
  },
  {
    "field": "loanNumber",
    "headerName": "Loan Number",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "loanExportedDate",
    "headerName": "Loan Exported Date",
    "cellRenderer": "formatDateRenderer",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "loanAmount",
    "headerName": "Loan Amount",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    detailValueFormatters: ['currency'],
    detailCategory: 1,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "warehouseLine",
    "headerName": "Warehouse Line",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "batchNumber",
    "headerName": "Batch Number",
    "width": 200,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "action",
    "headerName": "",
    "width": 330,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    cellRendererParams: {
      grid: "exportHistory"
    },
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
];
