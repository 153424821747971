import {
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { AgEditorComponent } from 'ag-grid-angular';
import { UiHelperService } from './../../../services/ui-helper.service';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
  selector: 'wlms-numeric-editor',
  template: `
  <div class="col-xs-12 col-12 col-md-12 form-group" style="width: 100%; min-height: 30px; min-width: 126px; width: 190px; margin-top:4px;">
  <input #input
  class="form-control"
  style="border-color:rgb(181, 181, 181)"
   (keydown)="onKeyDown($event)"
    [(ngModel)]="value"/>
</div>`
})

export class NumericEditorComponent implements AgEditorComponent {
  private params: any;
  public value: any;
  public highlightAllOnFocus: boolean = true;
  private cancelBeforeStart: boolean = false;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  constructor(private uiHelperService: UiHelperService) { }

  agInit(params: any): void {
    this.params = params;
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      params.charPress && '1234567890.'.indexOf(params.charPress) < 0;
  }

  setInitialState(params: any) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (params.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (params.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue(): any {
    return this.value ? parseFloat(this.value) : 0;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }


  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }
    if (
      !this.finishedEditingPressed(event) &&
      !this.isKeyPressedNumeric(event)
    ) {
      if (event.preventDefault) event.preventDefault();
    }
    if (this.uiHelperService.numericValidator(this.value ? this.value : '0', event)) {
      event.preventDefault();
    }
  }

  private getCharCodeFromEvent(event: any): any {
    event = event || window.event;
    return typeof event.which == 'undefined' ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/^[0-9]*(\.\d*)?$/.test(charStr);
  }

  private isKeyPressedNumeric(event: any): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  private deleteOrBackspace(event: any) {
    return (
      [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1
    );
  }

  private isLeftOrRight(event: any) {
    return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
  }

  private finishedEditingPressed(event: any) {
    const charCode = this.getCharCodeFromEvent(event);
    return charCode === KEY_ENTER || charCode === KEY_TAB;
  }
}
