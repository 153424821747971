<wlms-web-sliding-menu></wlms-web-sliding-menu>

<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-90">

  <div class="app-l-header__bottom">
    <div class="app-l-header__inner-wrap app-l-container">
      <div class="app-l-header__left">
        <h3><span class="app-c-label-name">Export History</span></h3>
      </div>
    </div>
  </div>

  <!-- Icon section -->
  <div class="app-c-tab-btn-refresh">
    <div class="d-flex justify-content-between flex-wrap align-items-center">
      <div class="d-flex flex-column align-items-start">
        <label class="pr-2">Start Date</label>
        <input
          #input1
          type="text"
          placeholder="mm/dd/yyyy"
          class="form-control bs-input"
          [bsConfig]="bsConfig"
          bsDatepicker
          [(ngModel)]="startDate"
          (ngModelChange)="onStartEndDatesChange()"
        />
      </div>
  
      <div class="d-flex flex-column align-items-start">
        <label class="pr-2">End Date</label>
        <input
          #input2
          type="text"
          placeholder="mm/dd/yyyy"
          class="form-control bs-input"
          [bsConfig]="bsConfig"
          bsDatepicker
          [(ngModel)]="endDate"
          (ngModelChange)="onStartEndDatesChange()"
        />
      </div>
      <wlms-warehouseline-dropdown [buttonGroup]="losList" [disableButton]="false"[buttonLabel]="losSelected?.name" (optionSelected)="onLosSelected($event)">
      </wlms-warehouseline-dropdown>
    </div>
    <div>
      <a title="Generate DataTape" (click)="showGenerateDataTapeModal()"><i class="icon-file header-icon"></i></a>
    </div>
    <div *wlmsIfPrivilege="[screenActionPrivileges.LoanSavePreference]">
      <a
      class="nounderscore"
        title="Save Preference"
        [class.disabled]="!sharedService.userPreferenceChanged"
        (click)="showUserPreferenceModal()"
      >
        <i class="icon-save header-icon"></i>
      </a>
    </div>
    <div *wlmsIfPrivilege="[screenActionPrivileges.LoanSavePreference]">
      <a
      class="nounderscore"
        title="Reset Preference"
      [class.disabled]="!(sharedService.userPreferenceChanged || this.disableResetPreference)"
        (click)="showResetUserPreferenceModal()"
      >
        <i class="icon-reset header-icon"></i>
      </a>
    </div>
  </div>

  <div class="app-l-container vertical-stack app-l-grid-action">
    <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">

      <!-- Grid section -->
      <wlms-grid #exporthistoryGrid [rowData]="rowData" [isRefresh]="isRefresh" [columnDefs]="columnDefs"
        [paginationPageSize]="paginationPageSize" [noRowsOverlayFlags]="noRowsOverlayFlags"
        (rowSelectionEvent)="rowSelectionEvent($event)" (filterChangeEvent)="onfilterChanged($event)"
        [selectedIndices]="selectedIndices" [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege" (noOverlay)="noOverlay($event)"
        (viewFundingFile)="viewFundingFile($event)" [isRowSelectable]="onIsRowSelectable">
      </wlms-grid>

    </div>
  </div>
</div>

<!-- Sliding panel on the right -->
<div class="spinneroverlay" *ngIf="showspinner">
  <div class="spinnercenter">
    <mat-progress-spinner diameter=50 mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
