import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TimeLimits } from './../constants/data-constants';

@Injectable()
export class SharedService {
  tabChange = new Subject<any>();
  gridDataloaded = new Subject<any>();
  slidePanelOpen = new Subject<any>();
  datasourceUpdated = new Subject<any>();
  loadGrid = new Subject<any>();
  showDetailPage = new Subject<any>();
  modalDataLoaded = new Subject<any>();
  modalClosed = new Subject<any>();
  modalSaved = new Subject<any>();
  modalSubmitValid = true;
  dayStart:any = TimeLimits.dayStart;
  dayEnd : any;
  userPreferenceChanged = false;
  disableRestButton = false;
  loanData =[];
  warehouseLines = [];
  constructor() {}
}
