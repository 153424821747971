import { Component, Input, OnInit } from '@angular/core';
import { ReviewResult } from '../../config/table-structure-reviewloan-result';

@Component({
  selector: 'wlms-reviewloan-result-modal',
  templateUrl: './reviewloan-result-modal.component.html',
  styleUrls: ['./reviewloan-result-modal.component.scss']
})
export class ReviewloanResultModalComponent implements OnInit {

  @Input() data: any;
  columnDefs = [...ReviewResult];
  rowData = [];
  paginationPageSize = 10;
 
  constructor() { }

  ngOnInit(): void {
    this.getreviewStatus(); 
  }

  getreviewStatus() {
    let res = this.data.responseDetails;
    res.forEach((element, index) => {
      if (element.IsReviewed) {
        res[index].IsReviewed = "Success";
        
      }
      else {
        res[index].IsReviewed = "Failed";
      }
    });

    this.rowData = res;

    
  }
}
