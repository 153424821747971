export class WarehouseLineDetail {
  availablecapacity: string;
  allocated: string;
  remaining: string;
  percentage: string;
  wetbalance: string;
  totaladvance: string;
  drysweeps: string;
  fundedadvance: string;
  loans: string;
  loanamount: string;
  contactperson: string;
  phone: string;
  email: string;
  summary: SummaryInfo;
  tranche: TrancheDetail;
  account: string;
  limits: string;
  investors: string;
  products: string;
  eligibility: string;
  sweeps: string;
}

export class SummaryInfo {
  committedamount: string;
	uncommittedamount: string;
	totallineamount: string;
	contractmonth: string;
	renewal: string;
	blugeexpiry: string;
	custodiantype: string;
	custodian: string;
	fundingcutoff: string;
	ldescrowapproved: string;
}

export class TrancheDetail {
  tranche: any;
  limit: string;
  limitpercentage: string;
  rate: string;
  advancerate: string;
}