import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, ApiConfig } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';


@Injectable({
  providedIn: 'root'
})
export class WarehouseLinesService extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  GetWarehouseLineDetailsForLoanQueue() {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForLoanQueue', ApiConfig.DISABLEGETTIMECACHE);
  }

  getWarehouseLines() {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForGrid', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getIndex() {
    return this.get('WarehouseLine/GetWarehouseLineIndexes', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getTimeAvailability() {
    return this.get('WarehouseLineTimeAvailability/GetAllWarehouseLineTimeAvailability', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getDailyCapAvailability() {
    return this.get('WarehouseLineDailyCaps/GetAllWarehouseLineDailyCaps', ApiConfig.DISABLESEARCHLOANCACHE);
  }

  SaveEditDailyCapAvailability(data) {
    return this.put('WarehouseLineDailyCaps/UpdateWarehouseLineDailyCap', data);
  }

  getWarehouseLineDetailsByWarehouseName(title: string) {
    return this.get('StaticFiles/Json/warehouselineData.json');
  }

  getWarehouseLinesCapacityDetails() {
    return this.get('StaticFiles/Json/WarehouselineCapacityDetails.json');
  }

  editWarehouseLine(data) {
    return this.put('WarehouseLine/UpdateWarehouseLineDetails', data);
  }

  editTimeAvailablity(data) {
    return this.put('WarehouseLineTimeAvailability/UpsertWarehouseLineTimeAvailability', data);
  }

  getWarehouselineById(id) {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForEdit?warehouselineId=' + id, ApiConfig.DISABLESEARCHLOANCACHE);
  }

  addOrEditWarehouselineById(data) {
    return this.put('WarehouseLine/UpsertWarehouseLineDetails', data);
  }

  getTranches(id) {
    return this.get('WarehouseLineTranche/GetWarehouseLineTranchesByContract?warehouselineContractId=' + id,
      ApiConfig.DISABLESEARCHLOANCACHE);
  }

  getContractsPerWarehouse(id) {
    return this.get('WarehouseLine/GetWarehouseLineContracts?warehouselineId=' + id, ApiConfig.DISABLESEARCHLOANCACHE);
  }

  saveTranche(data) {
    return this.put('WarehouseLineTranche/UpsertWarehouseLineTranche', data);
  }

  getAllWetOverrideDetails() {
    return this.get('WarehouseLineWetOverrides/GetWarehouseLineWetOverrideList', ApiConfig.DISABLESEARCHLOANCACHE);
  }
  UpdateWhWetOverride(data) {
    return this.put('WarehouseLineWetOverrides/UpdateWarehouseLineWetOverrideList', data);
  }

  copyContract(data) {
    return this.post('WarehouseLine/CopyContract', data);
  }

  saveUserPreferences(request) {
    return this.post('user/SaveUserPreference', request);
  }

  getUserPreferences() {
    return this.get('user/GetPreference/', ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(id) {
    return this.get('user/GetUserPrefernceColumnMaster/' + id);
  }

  ResetUserPreference(request) {
    return this.post('user/ResetUserPreference', request);
  }

  copyTanche(data) {
    return this.put('WarehouseLineTranche/CopyTranche', data);
  }
}
