import { Component, OnInit, OnChanges, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { SharedService, LoanDetailsPanelTabs } from '@wlms-web/utils';

@Component({
  selector: 'wlms-view-loan-panel',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class ViewLoanPanelComponent implements OnInit, OnChanges, OnDestroy {
  slidePanelOpen: boolean;
  selectedTab = 0;
  loadLoanData: boolean;
  @Input() openPanel: boolean;
  @Input() selectedLoan: string;
  @Input() selectedPanelTab: number;
  tabList = [...LoanDetailsPanelTabs];
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdRef: ChangeDetectorRef,
    public sharedService: SharedService
  ) { }

  ngOnChanges() {
    if (this.openPanel && !this.slidePanelOpen) {
      this.selectedTab = this.selectedPanelTab;
      this.loadLoanData = true;
    }
  }

  ngOnInit() {
    this.sharedService.slidePanelOpen.subscribe((response: any) => {
      if (response) {
        this.slidePanelOpen = true;
        this.loanDataLoaded();
      }
    });
    this.sharedService.tabChange.subscribe((response: any) => {
      if (!response.isLabelDisplayed) {
        this.selectedTab = response.index;
      }
    });
  }

  onSelectedIndexChange(event: any) {
    this.selectedPanelTab = event;
    this.selectedTab = event;
  }

  onClose() {
    this.slidePanelOpen = false;
    this.loadLoanData = false;
  }

  loanDataLoaded() {
    if (this.openPanel && !this.slidePanelOpen) {
      this.slidePanelOpen = true;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.slidePanelOpen = false;
  }

  onWarehouselineSelected(event: any) {
    this.optionSelected.emit(event);
  }

}
