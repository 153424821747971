import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'wlms-reset-preference',
  templateUrl: './reset-preference.component.html',
  styleUrls: ['./reset-preference.component.scss']
})
export class ResetPreferenceComponent implements OnInit {
  @Input() data: any;
  action: any;
  constructor() { }

  ngOnInit(): void {
    this.action = this.data.action
  }


  actionChange(e)
  {   
      this.data.action = this.action; 
    
  }

}
