import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '@wlms-web/utils';

@Component({
  selector: 'wlms-tab-set',
  templateUrl: './tab-set.component.html',
  styleUrls: ['./tab-set.component.scss']
})
export class TabSetComponent implements OnInit {

  currentTabIndex: number = 0;
  @Input() tabList: any;
  @Input() isLabelDisplayed: boolean;
  @Input() selectedIndex: number;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  onTabChange(event) {
    this.sharedService.tabChange.next({ index: event.index, isLabelDisplayed: this.isLabelDisplayed });
  }

}
