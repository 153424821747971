<div class="d-flex flex-wrap justify-content-between mb-4">
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Warehouse:</label
      >{{ data.modalData.WarehouseLineName }}
    </div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Contract:</label
      >{{ data.modalData.Contract }}
    </div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Line Limit:</label>${{
        data.modalData.LineLimitAmount
      }}
    </div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Starting Outstanding Amount:</label
      >${{ data.modalData.StartingOutStandingAmount }}
    </div>
  </div>
</div>

<div class="d-flex flex-wrap justify-content-between">
  <div class="d-flex flex-column align-items-start width-lg">
    <mat-form-field appearance="fill" class="form-field w-100">
      <mat-label>Tranche</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.Name"
        type="text"
        (ngModelChange)="validate('Name')"
      />
    </mat-form-field>
  </div>

  <div class="d-flex flex-column align-items-start width-lg">
    <mat-form-field appearance="fill" class="form-field w-100">
      <mat-label>Parent Tranche</mat-label>
      <mat-select [(ngModel)]="data.modalData.ParentTrancheId" name="type">
        <mat-option
          *ngFor="let type of data.modalData.tranches"
          [value]="type.WarehouseLineTrancheId"
        >
          {{ type.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column align-items-start">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Account Number</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.AccountNumber"
        type="text"
        disabled
      />
    </mat-form-field>
  </div>
</div>

<div class="d-flex flex-wrap justify-content-between">
  <div class="d-flex flex-column align-items-start">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Drive From</mat-label>
      <mat-select
        [(ngModel)]="data.modalData.DriveFrom"
        name="type"
        (ngModelChange)="validate('DriveFrom')"
      >
        <mat-option *ngFor="let type of driveFrom" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex flex-column align-items-start">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label *ngIf="driveFromConfig.Limit == data.modalData.DriveFrom"
        >Sublimit Driver %
      </mat-label>
      <mat-label *ngIf="driveFromConfig.Amount == data.modalData.DriveFrom"
        >Sublimit Driver $</mat-label
      >
      <mat-label *ngIf="data.modalData.DriveFrom == 0"
        >Sublimit Driver
      </mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.DriverValue"
        type="number"
        (ngModelChange)="validate('DriverValue')"
        [attr.disabled]="data.modalData.DriveFrom == 0 ? '' : null"
      />
    </mat-form-field>
  </div>

  <div class="d-flex flex-column align-items-end w-28 mt-2">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Sublimit Amount:</label>${{
        subLimitAmount
      }}
    </div>
    <!-- <div class="d-flex flex-row" *ngIf="data.modalData.DriveFrom == 0">
      <label class="pr-2 font-weight-bold">Sublimit Amount:</label>$0
    </div> -->
  </div>
</div>

<div class="d-flex flex-wrap justify-content-between">
  <div class="d-flex flex-column align-items-start">
    <mat-form-field
      class="example-full-width"
      appearance="fill"
      class="form-field"
    >
      <mat-label>Pricing Margin</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.PricingMargin"
        (ngModelChange)="validate('PricingMargin')"
        type="number"
      />
    </mat-form-field>
  </div>
  <div class="d-flex flex-column align-items-start mt-2">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Index Rate:</label
      >{{ data.modalData.IndexRate }}%
    </div>
  </div>
  <div class="d-flex flex-column align-items-start mt-2">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Floor Rate:</label
      >{{ data.modalData.FloorRate }}%
    </div>
  </div>
  <div class="d-flex flex-column align-items-start mt-2">
    <div class="d-flex flex-row">
      <label class="pr-2 font-weight-bold">Pricing Rate:</label
      >{{ data.modalData.PricingRate }}%
    </div>
  </div>
</div>

<div class="d-flex flex-wrap justify-content-between mb-1">
  <div class="d-flex flex-column align-items-start">
    <mat-form-field
      class="example-full-width"
      appearance="fill"
      class="form-field"
    >
      <mat-label>Advance Rate</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.AdvanceRate"
        (ngModelChange)="validate('AdvanceRate')"
        type="number"
      />
    </mat-form-field>
  </div>

  <div class="d-flex flex-column align-items-start">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Wet Amended Start Out Amt</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.AmendedWetStartingOutstandingAmount"
        (ngModelChange)="validate('AmendedWetStartingOutstandingAmount')"
        type="number"
      />
    </mat-form-field>
  </div>
  <div class="d-flex flex-column align-items-start">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Amended Start Out Amt</mat-label>
      <input
        matInput
        [(ngModel)]="data.modalData.AmendedStartOutAmt"
        (ngModelChange)="validate('AmendedStartOutAmt')"
        type="number"
      />
    </mat-form-field>
  </div>
</div>

<div class="d-flex flex-wrap justify-content-between">
  <div class="d-flex flex-column align-items-start">
    <mat-form-field
      class="mat-textarea whl-text-area form-field"
      appearance="fill"
    >
      <mat-label>Comments</mat-label>
      <textarea
        matInput
        class="comments-text-area"
        [(ngModel)]="data.modalData.Comments"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="d-flex flex-column align-items-start mt-2 w-37">
    <div class="check-list">
      <label class="cat-item d-flex align-items-center">
        <div class="mr-2">Table Funding</div>
        <input
          type="checkbox"
          [(ngModel)]="data.modalData.IsAvailableForFunding"
          [checked]="data.modalData.IsAvailableForFunding"
        />
      </label>
    </div>
  </div>
</div>
