<!-- <div class="d-flex flex-align-row mb-2 p-0 col-lg-4">
    <div>
        <label class="pr-2">Contracts</label>
        <div class="btn-group" style="width: 100%; min-height: 30px; min-width: 126px; width: 346px" dropdown
            container="body">
            <button type="button" class="btn app-c-btn app-c-grid-action-btn-left">
                <label class="app-c-label" style="min-width: 105px; overflow: hidden; cursor: pointer">
                    {{ currentActiveContract ? currentActiveContract.name : '' }}
                </label>
            </button>
            <button id="button-basic " dropdownToggle type="button"
                class="btn app-c-btn dropdown-toggle app-c-popdown-btn app-c-grid-action-btn-right"
                aria-controls="dropdown-basic"></button>
            <ul id="dropdown-basic " *dropdownMenu class="dropdown-menu popdown" role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem " class="app-l-list" style="
              border-bottom: 1px solid #f3eded;
              box-shadow: 8px 9px 15px -2px rgba(240, 234, 234, 0.7);
            " *ngFor="let contract of contracts" (click)="contractChanged(contract)">
                    <a class="dropdown-item app-c-dropdown-item d-block active-dropdown">{{ contract?.name }}</a>
                </li>
            </ul>
        </div>
    </div>

</div> -->

<div class="d-flex flex-align-row mt-2 mb-2 p-0 col-lg-4">
    <div>
        <div style="width: 100%; min-height: 30px; min-width: 126px; width: 353px">
            <div>
                <label class="pr-2">Contracts</label>
                <div class="d-flex flex-column align-items-start">
                    <mat-form-field appearance="fill" class="form-field">
                        <mat-label>Contracts</mat-label>
                        <mat-select [(ngModel)]="data.modalData.contractId" name="type">
                            <mat-option *ngFor="let contract of contracts" (click)="contractChanged(contract)"
                                [value]="contract.WarehouseLineContractDetailId">
                                {{ contract?.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
    <div class="d-flex flex-column align-items-start">
        <label class="pr-2">Start Date</label>
        <input #input1 type="text" placeholder="mm/dd/yyyy" class="form-control bs-input" [bsConfig]="bsConfig"
            bsDatepicker [(ngModel)]="data.modalData.StartDate" (ngModelChange)="dateChange('start', $event)"
            />
    </div>
    <div class="d-flex flex-column align-items-start">
        <label class="pr-2">End Date</label>
        <input #input2 type="text" placeholder="mm/dd/yyyy" class="form-control bs-input" [bsConfig]="bsConfig"
            bsDatepicker [(ngModel)]="data.modalData.ExpiryDate" (ngModelChange)="dateChange('end', $event)"
            />
    </div>
</div>