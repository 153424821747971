<wlms-grid
  #warehouseGrid
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [paginationPageSize]="paginationPageSize"
  [suppressRightClickPrivilege]="suppressRightClickPrivilege"
  [suppressActionPrivilege]="suppressActionPrivilege"
  [noRowsOverlayFlags]="noRowsOverlayFlags"
  [treeData]="false"
  (edit)="openEditWarehouseLine($event)"
  (noOverlay)="noOverlay($event)"
  (copyContract) = "openWlCopyContact($event)"
  (openWarehouseDetail)="openWarehouseDetail($event)"
>
</wlms-grid>
