<div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
  <!-- Grid section -->
  <wlms-grid #viewReportGrid [columnDefs]="columnDefs" [rowData]="rowData" [noRowsOverlayFlags]="noRowsOverlayFlags"
    (noOverlay)="noOverlay($event)">
  </wlms-grid>

</div>
<div class="spinneroverlay" *ngIf="showspinner">
  <div class="spinnercenter">
    <mat-progress-spinner diameter=50 mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
