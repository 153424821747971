import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharedService } from '@wlms-web/utils';

@Component({
  selector: 'wlms-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @Input() initialState: any;
  @Output() clickevent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<string>();
  data: any;
  private componentRef: ComponentRef<{}>;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    public sharedService: SharedService,
    private modalService: BsModalService) {
    this.sharedService.modalClosed.subscribe(response => {
      this.closeEvent.emit('close');
      this.modalService.hide();
    });
  }
  ngOnInit(): void {
    this.data = this.initialState;
    this.data.buttonCancel = ((this.data.buttonCancel && this.data.buttonCancel != '') ? this.data.buttonCancel : null)
    this.data.buttonSuccess = ((this.data.buttonSuccess && this.data.buttonSuccess != '') ? this.data.buttonSuccess : null)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.initialState.component
    );
    const componentRef = this.container.createComponent<any>(componentFactory);
    componentRef.instance.data = this.initialState.data;
    this.sharedService.modalDataLoaded.subscribe((receivedEntry) => {
      this.data.data = receivedEntry;
    })
  }
  closeModal() {    
    this.sharedService.modalClosed.next('closed');

  }
  onSuccess() {  
    this.modalService.hide();
    this.sharedService.modalSaved.next();
    this.clickevent.emit({ status: 'success', data: this.data });   
  }
}
