import { DateFormattor } from '@wlms-web/utils';
export const ReportGridCols = [
  {
    "field": "vendor",
    "headerName": "Warehouse Line",
    "width": 200,
    "filter": "agTextColumnFilter",
  },
  {
    "field": "reportName",
    "headerName": "Report Name",
    "width": 250,
    "filter": "agTextColumnFilter",
  },
  {
    "field": "fileName",
    "headerName": "File Name",
    "width": 250,
    "filter": "agTextColumnFilter",
  },
  {
    "field": "reportDate",
    "headerName": "Report Date",
    "cellRenderer": "formatDateRenderer",
    "width": 180,
    detailValueFormatters: ['date'],
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "action",
    "headerName": "",
    "width": 330,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    cellRendererParams: {
      grid: "reports"
    },
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
];
