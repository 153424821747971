<div >
    <form>
      <div style="margin-bottom:10px;">{{data.message}}</div>      
      <div class="d-flex flex-row align-items-center">        
        <div class="search-box">          
          <div class="app-c-radio-group">
            <div class="app-c-radio-stnd-wrapper">
              <input type="radio" name="action" [value]="0"  [(ngModel)]="action" (change)="actionChange($event)" />
              <label class="app-c-radio-stnd">Local Only</label>
            </div>
            <div class="app-c-radio-custm-wrapper"  >
              <input type="radio" name="action" [value]="1"  [(ngModel)]="action" (change)="actionChange($event)" />
              <label class="app-c-radio-custm">All Tabs</label>
            </div>
          </div>               
        </div>    
      </div>
    </form>
  </div>
