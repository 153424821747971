import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { ConfigService, StorageKey, BrowserStorageService } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthUtils extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient, private authService: MsalService, private browserStorageService: BrowserStorageService) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  signOut() {
    this.browserStorageService.removeItemFromLocalStorage(StorageKey.AUTHTOKEN)
    this.authService.logoutRedirect({
      account: this.authService.instance.getActiveAccount(),
    });
  }

  getLoggedInUserDetails() {
    const activeAccount = this.authService.instance.getActiveAccount();
    return {
      token: activeAccount?.idToken,
      userId: activeAccount?.idTokenClaims?.oid,
      email: activeAccount?.idTokenClaims?.preferred_username,
      name: activeAccount?.idTokenClaims?.name
    }
  }

  doesUserAllowedToPerform(param) {
    const privileges = JSON.parse(this.browserStorageService.getLocalStorageValue(StorageKey.PRIVILEGES));
    return privileges && privileges.indexOf(param) != -1;
  }

  getUserPrivileges() {
    return this.get('@userServiceUrl/UserPrivileges');
  }
}
