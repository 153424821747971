import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { ViewLoanPanelComponent } from './summary/summary.component';
import { UiKitModule } from '@wlms-web/ui-kit';
import { UtilsModule } from '@wlms-web/utils';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [CommonModule,
    UtilsModule,
    BsDropdownModule.forRoot(),
    UiKitModule],
  declarations: [LoanDetailsComponent, ViewLoanPanelComponent],
  exports: [ViewLoanPanelComponent],
  providers:[DatePipe]
})
export class FeaturesLoanDetailsPanelModule { }

