import { Component, Input, OnInit } from '@angular/core';
import { UiHelperService } from '@wlms-web/ui-kit';
import { SharedService } from '@wlms-web/utils';
import { WarehouseLinesService } from '../../../services/whl.service';
import * as moment from 'moment';
@Component({
  selector: 'wlms-copy-tranche',
  templateUrl: './copy-tranche.component.html',
  styleUrls: ['./copy-tranche.component.scss']
})
export class CopyTrancheComponent implements OnInit {
  @Input() data;
  currentActiveContract: any;
  currentActiveTranche: any;
  contracts = [];
  tranches = [];
  constructor(private warehouseLinesService: WarehouseLinesService,
    public sharedService: SharedService,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    this.sharedService.modalSubmitValid = false;
    this.contracts = this.data.contracts;
    this.data.modalData.contractId = this.data.activeContract.WarehouseLineContractDetailId;
    this.currentActiveContract = this.data.activeContract;
    this.data.currentAvtiveContractId = this.data.activeContract.WarehouseLineContractDetailId;
    this.getTranches(this.currentActiveContract.WarehouseLineContractDetailId);
    this.data.modalData.newTranche = '';
  }

  contractChanged(contract) {
    this.currentActiveContract = { ...contract };
    this.getTranches(contract.WarehouseLineContractDetailId);
    this.data.modalData.contractId = contract.WarehouseLineContractDetailId;
  }

  trancheChanged(tranche) {
    this.data.modalData.tranchId = tranche.WarehouseLineTrancheId;
  }

  getTranches(contractId) {
    this.warehouseLinesService.getTranches(contractId).subscribe(response => {
      if (response) {
        this.tranches = [{ WarehouseLineTrancheId: 0, Name: 'None' }].
          concat(response['details'].map(({ WarehouseLineTrancheId, Name }) => ({ WarehouseLineTrancheId, Name })));
        this.data.modalData.tranchId = this.data.ParentTrancheId;
      }
    })
  }

  tranchNameChanged(e) {
    if (e && e.trim() != '')
      this.sharedService.modalSubmitValid = true;
    else
      this.sharedService.modalSubmitValid = false;
  }

}
