import { Component, Input } from '@angular/core';

@Component({
    template: `<label class="modal-body-label mb-0">{{data.data}}</label>`,
  })
  export class AlertModalComponent {
  
    constructor() { }
    @Input() data: any;
    agInit(params: any){
      
    }
  }