import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitModule } from '@wlms-web/ui-kit';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UtilsModule } from '@wlms-web/utils';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeaturesPageLayoutModule } from '@wlms-web/features/page-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@wlms-web/material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ReportsComponent } from './reports/reports.component';
import { ViewReportModalComponentComponent } from './view-report-modal-component/view-report-modal-component.component';

@NgModule({
  imports: [CommonModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    UtilsModule,
    UiKitModule,
    FeaturesPageLayoutModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [
    ReportsComponent,
    ViewReportModalComponentComponent
  ],
  exports: [
    ReportsComponent
  ],
})
export class FeaturesReportsModule { }
