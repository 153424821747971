<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-100">
  <div class="d-flex flex-column">
    <div class="min-h-0 d-flex flex-row mt-1 pt-2">
      <h3 class="app-c-label-export">Funding File:</h3>
      <h3 class="elipsis pl-1">{{ rowDataFilePath[0]?.Path }}</h3>
    </div>
    <div class="min-h-0 d-flex flex-row mt-1 pt-2"
      *ngIf="rowDataFilePath?.length > 0 && rowDataFilePath[0]?.AdditionalPath!=''">
      <h3 class="app-c-label-export">Additional File:</h3>
      <h3 class="elipsis pl-1">{{ rowDataFilePath[0]?.AdditionalPath }}</h3>
    </div>
    <div class="min-h-0 d-flex flex-row mt-1 pt-2" *ngIf="rowDataFilePath?.length > 1">
      <h3 class="app-c-label-export">Custodian File:</h3>
      <h3 class="app-c-label pl-2">{{ rowDataFilePath[1]?.Path }}</h3>
    </div>
  </div>

  <div class="d-flex flex-row justify-content-space-between pt-4">
    <h3 class="app-c-label-export">Loan Satus</h3>
  </div>

  <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
    <wlms-grid class="resultGrid" #loanExportRsultGrid [rowData]="rowData" [columnDefs]="columnDefs"
      [suppressActionPrivilege]="false" [suppressRightClickPrivilege]="false" [suppressFilter]="true">
    </wlms-grid>
  </div>
</div>