import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesPageLayoutModule } from '@wlms-web/features/page-layout';
import { UtilsModule } from '@wlms-web/utils';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from 'libs/utils/src/lib/interceptors/http.interceptor';


@NgModule({
  imports: [CommonModule,
    UtilsModule,
    FeaturesPageLayoutModule],
  exports: [FeaturesPageLayoutModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }]
})
export class CoreModule { }

