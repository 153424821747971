<div >
  <form
    [formGroup]="addExceptionForm"
    (ngSubmit)="loanlookUp(addExceptionForm?.value)"
    novalidate
    (keyup)="clearMessage(addExceptionForm?.value)"
  >
    <div class="d-flex flex-row align-items-center">
      <wlms-warehouseline-dropdown
        class="right-spacer"
        [buttonGroup]="losList"
        [buttonLabel]="losSelected?.name"
        [disableButton]="false"
        (optionSelected)="onLosSelected($event)"
        >
      </wlms-warehouseline-dropdown>
      <div class="search-box">
        <mat-form-field appearance="fill" class="input-content">
          <input
            matInput
            type="text"
            formControlName="loanNumber"
            id="loanNumber"
            [maxLength]="fieldLength"
            [pattern]="this.validationPattern"
          />
          <mat-label>Loan Number</mat-label>
        </mat-form-field>
      </div>
      <div>
        <button
          type="submit"
          class="buttonSearch ng-star-inserted"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>

    <div *ngIf="this.showErrorMessage" class="app-c-label-text text-danger">     
        Enter the Valid Loan Number    
    </div>
    <div class="app-l-search-main-body">
      <div class="app-l-search-content-wrapper">
        <div class="app-l-search-content-left">
          <div
            class="app-c-label-text search-loan"
            [innerHTML]="searchResults"
          ></div>
          <div *ngIf="errorMessage" class="app-c-label-text text-danger">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
   
  </form>
</div>
