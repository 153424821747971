<div >
    <form>
      <div style="margin-bottom:10px;">{{data.message}}</div>      
      <div class="d-flex flex-row align-items-center">        
        <div class="search-box">          
          <div class="app-c-radio-group">
            <div class="app-c-radio-stnd-wrapper">
              <input type="radio" name="action" [value]="0"  [(ngModel)]="action" (change)="actionChange($event)" />
              <label class="app-c-radio-stnd">System Default</label>
            </div>
            <div class="app-c-radio-custm-wrapper"  [class.disabled] ="!(data.enablePreference)" >
              <input type="radio" name="action" [value]="1"  [(ngModel)]="action" (change)="actionChange($event)" [class.disabled] ="!(data.enablePreference)"/>
              <label class="app-c-radio-custm">Last Saved User Preferences</label>
            </div>
          </div>               
        </div>    
      </div>
    </form>
  </div>
  