<div class="app-l-not-found min-h-100" *ngIf="blankTemplateFlag !== message.noRecords">
  <img src="../assets/images/sofa.svg" alt="" />
  <div class="app-l-not-found__text">
    <p>
      Currently, there are no
      {{
      blankTemplateFlag == message.isLoanAssignments
      ? 'loans'
      : blankTemplateFlag == message.warehouses
      ? 'warehouses'
      : blankTemplateFlag == message.EXCEPTIONS
      ? 'exceptions'
      : blankTemplateFlag == message.tranches
      ? 'tranches'
      : blankTemplateFlag == message.reports
      ? 'Reports'
      : ''
      }}
      available.
    </p>
  </div>
</div>

<div class="app-l-not-found min-h-200" *ngIf="blankTemplateFlag === message.noRecords">
  <div class="app-l-not-found__text">
    <p>No Records Found.</p>
  </div>
</div>