import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ExportHistoryService } from '../services/export-history.service';
import { SharedService, AuthUtils, SnackbarConfig } from '@wlms-web/utils';
import { UiHelperService } from '@wlms-web/ui-kit';

@Component({
  selector: 'wlms-funding-batch-detail-modal',
  templateUrl: './funding-batch-detail-modal.component.html',
  styleUrls: ['./funding-batch-detail-modal.component.scss']
})
export class FundingBatchDetailModalComponent implements OnInit {
  @Input() data: any;
  @ViewChild('fundedBatchGrid') fundedBatchGrid: any;

  showspinner = false;

  fundedBatchDetails = [];
  tabList = [];
  selectedTab = 0;
  columnDefs = [];
  rowData = [];
  userDetails: any;

  constructor(private exportHistoryService: ExportHistoryService,
    private authUtils: AuthUtils,
    private sharedService: SharedService,
    private uiHelperService: UiHelperService
  ) { }

  ngOnInit(): void {
    this.userDetails = this.authUtils.getLoggedInUserDetails();

    this.sharedService.tabChange.subscribe((response: any) => {
      this.selectedTab = response.index;
      this.setGridData()
    });

    this.getFundedBatchDetails();
  }

  getFundedBatchDetails() {
    this.showspinner = true;
    this.exportHistoryService.getFundedBatchDetails(this.data.batchNumber).subscribe(response => {
      this.fundedBatchDetails = [...response['details']];

      this.tabList = this.fundedBatchDetails.map(function (fundedBatchDetail) {
        return { name: fundedBatchDetail.configurationName }
      });

      this.setGridData();

      this.showspinner = false;
    });
  }

  setGridData() {
    if (this.fundedBatchDetails) {
      const colDefsForTab = [];
      const keys = Object.keys(JSON.parse(this.fundedBatchDetails[this.selectedTab].convertedDataFileContent)[0]);
      keys.forEach(key => colDefsForTab.push({ field: key, editable: true, filter: 'agTextColumnFilter' }));

      this.columnDefs = colDefsForTab;
      this.rowData = JSON.parse(this.fundedBatchDetails[this.selectedTab].convertedDataFileContent);
    }
  }

  updateFundedBatchDetails() {
    const updatedFundingBatchDetails = [];
    this.fundedBatchGrid.gridApi.forEachNode((node) => updatedFundingBatchDetails.push(node.data));

    const req = this.fundedBatchDetails[this.selectedTab];
    req.userName = this.userDetails?.email;
    req.convertedDataFileContent = JSON.stringify(updatedFundingBatchDetails);

    this.showspinner = true;
    this.exportHistoryService.updateFundedBatchDetails(req).subscribe(response => {

      if (response['details']['success']) {
        this.uiHelperService.showSnackBar('Batch details are updated successfully.', SnackbarConfig.success) 
        this.getFundedBatchDetails();
      } else {
        this.uiHelperService.showSnackBar('Update of funded batch details is failed.', SnackbarConfig.error) 
      }

      this.showspinner = false;
    });
  }
}
