import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanAssignmentComponent } from './loan-assignment/loan-assignment.component';
import { UiKitModule } from '@wlms-web/ui-kit';
import { HttpClientModule } from '@angular/common/http';
import { FeaturesLoanDetailsPanelModule } from '@wlms-web/features/loan-details-panel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UtilsModule } from '@wlms-web/utils';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AssignExportModalComponent } from './loan-assignment/assign-export-modal/assign-export-modal.component';
import { FeaturesPageLayoutModule } from '@wlms-web/features/page-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@wlms-web/material';
import { LoanlookupExceptionComponent } from './loan-assignment/loanlookup-exception/loanlookup-exception.component';
import { ExportResultModalComponent } from './loan-assignment/export-result-modal/export-result-modal.component';
import { AssignResultModalComponent } from './loan-assignment/assign-result-modal/assign-result-modal.component';
import { ReviewloanResultModalComponent } from './loan-assignment/reviewloan-result-modal/reviewloan-result-modal.component';
import { PushfundingResultModalComponent } from './loan-assignment/pushfunding-result-modal/pushfunding-result-modal.component';
import { UpdateAdvanceDateModalComponent } from './loan-assignment/update-advance-date-modal/update-advance-date-modal.component';
@NgModule({
  imports: [CommonModule,
    HttpClientModule,
    FeaturesLoanDetailsPanelModule,
    BsDropdownModule.forRoot(),
    UtilsModule,
    UiKitModule,
    FeaturesPageLayoutModule,
    ModalModule.forRoot(),
    MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [LoanAssignmentComponent, AssignExportModalComponent,LoanlookupExceptionComponent, ExportResultModalComponent, AssignResultModalComponent, ReviewloanResultModalComponent, PushfundingResultModalComponent, UpdateAdvanceDateModalComponent],
  exports: [LoanAssignmentComponent, AssignExportModalComponent,LoanlookupExceptionComponent,ExportResultModalComponent,ReviewloanResultModalComponent,PushfundingResultModalComponent],
  entryComponents: []
})
export class LoanassignmentModule { }
