<wlms-web-sliding-menu></wlms-web-sliding-menu>

<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-90">

    <div class="app-l-header__bottom">
        <div class="app-l-header__inner-wrap app-l-container">
            <div class="app-l-header__left">
                <h3><span class="app-c-label-name">Reports</span></h3>
            </div>
        </div>
    </div>

    <!-- Icon section -->
    <div class="app-c-tab-btn-refresh">
        <div class="container" style="margin-left:85px !important">
            <div class="row">

                <div class="col-2">
                    <label class="pr-2">Vendor</label>
                    <select class="custom-select" [(ngModel)]="vendor">
                        <option value="" selected>All</option>
                        <option value="BAML">Bank of America</option>
                        <option value="CHASE">Chase</option>
                    </select>
                </div>

                <div>
                    <label class="pr-2">Report</label>
                    <select class="custom-select" [(ngModel)]="reportType">
                        <option value="" selected>All</option>
                        <option value="DailyActivity">DailyActivity</option>
                        <option value="IncomingFunds">IncomingFunds</option>
                        <option value="AdvanceDetailRpt">AdvanceDetailRpt</option>
                        <option value="OverUnderActivity">OverUnderActivity</option>
                        <option value="OutstandingAdvances">OutstandingAdvances</option>
                        <option value="InterestDetailOutstanding">InterestDetailOutstanding</option>
                        <option value="InterestDetailSettled">InterestDetailSettled</option>
                        <option value="FedRef">FedRef</option>
                    </select>
                </div>

                <div class="col-2">
                    <label class="pr-2">Start Date</label>
                    <input #input1 type="text" placeholder="mm/dd/yyyy" class="form-control bs-input" [bsConfig]="bsConfig"
                        bsDatepicker [(ngModel)]="startDate"/>
                </div>

                <div>
                    <label class="pr-2">End Date</label>
                    <input #input2 type="text" placeholder="mm/dd/yyyy" class="form-control bs-input" [bsConfig]="bsConfig"
                        bsDatepicker [(ngModel)]="endDate"/>
                </div>

                <div class="col-2">
                    <button class="app-c-btn-export" (click)="onFilterClick()" style="margin-top: 17px;">Filter</button>
                </div>

            </div>
        </div>
        <div *wlmsIfPrivilege="[screenActionPrivileges.ReportsSavePreference]">
            <a class="nounderscore" title="Save Preference" [class.disabled]="!sharedService.userPreferenceChanged"
                (click)="showUserPreferenceModal()">
                <i class="icon-save header-icon"></i>
            </a>
        </div>
        <div *wlmsIfPrivilege="[screenActionPrivileges.ReportsSavePreference]">
            <a class="nounderscore" title="Reset Preference"
                [class.disabled]="!(sharedService.userPreferenceChanged || this.disableResetPreference)"
                (click)="showResetUserPreferenceModal()">
                <i class="icon-reset header-icon"></i>
            </a>
        </div>
    </div>

    <div class="app-l-container vertical-stack app-l-grid-action">
        <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">

            <!-- Grid section -->
            <wlms-grid #reportsGrid [rowData]="rowData" [isRefresh]="isRefresh" [columnDefs]="columnDefs"
                [paginationPageSize]="paginationPageSize" [noRowsOverlayFlags]="noRowsOverlayFlags"
                (filterChangeEvent)="onfilterChanged($event)"
                [suppressRightClickPrivilege]="suppressRightClickPrivilege"
                [suppressActionPrivilege]="suppressActionPrivilege" (noOverlay)="noOverlay($event)"
                (viewReport)="viewReport($event)">
            </wlms-grid>

        </div>
    </div>
</div>

<!-- Sliding panel on the right -->
<div class="spinneroverlay" *ngIf="showspinner">
    <div class="spinnercenter">
        <mat-progress-spinner diameter=50 mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
