import { Component, Input, OnInit } from '@angular/core';
import { ExportFilePathResult, ExportResult } from '../../config/table-structure-export-result';

@Component({
  selector: 'wlms-export-result-modal',
  templateUrl: './export-result-modal.component.html',
  styleUrls: ['./export-result-modal.component.scss']
})
export class ExportResultModalComponent implements OnInit {
  @Input() data: any;
  columnDefs = [...ExportResult];
  columnDefsFilePath = [...ExportFilePathResult];
  rowData = [];
  rowDataFilePath = []
  constructor() { }
  ngOnInit(): void {
    this.getExportStatus();
  }
  getExportStatus() {
    let response = this.data.data.map(x => x.response)[0];
    response.forEach((element, index) => {
      if (element.status) {
        response[index].status = "Success";
      }
      else {
        response[index].status = "Failed";
      }
    });

    this.rowData = response.sort(function (a, b) {
      return (a.status.toUpperCase() < b.status.toUpperCase()) ? -1 : (a.status.toUpperCase() > b.status.toUpperCase()) ? 1 : 0;
    });

    this.rowDataFilePath = this.data.data.map(obj =>
    ({
      WarehouseLine: this.data.WarehouseLine,
      Path: obj.path,
      AdditionalPath: obj.additionalPath ?? ""
    }));
  }
}
