export const ExportResult = [
    {
        "field": "loanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 150,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "status",
        "headerName": "Status",
        "filter": "agTextColumnFilter",
        "width": 150,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "statusMessage",
        "headerName": "Message",
        "filter": "agTextColumnFilter",
        "width": 400,
        wrapText: true,
        autoHeight: true,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true

    }
]

export const ExportFilePathResult = [
    {
        "field": "WarehouseLine",
        "headerName": "Warehouse Line",
        "filter": "agTextColumnFilter",
        "width": 200,
    },
    {
        "field": "Path",
        "headerName": "Path",
        "filter": "agTextColumnFilter",
        "width": 500,
    },
]
export const AdvanceDateUpdateResult = [
    {
        "field": "LoanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 150,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "",
        "headerName": "Status",
        "filter": "agTextColumnFilter",
        "width": 500,
    },
]
