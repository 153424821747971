import { CurrencyFormatter, PercentageFormatter } from '@wlms-web/utils';

export const TrancheDetail = [
  {
    "field": "Name",
    "headerName": "Tranches",
    "filter": "agTextColumnFilter",
    "width": 150,
  },
  {
    "field": "Limit",
    "headerName": "Sub-limit Amount",
    width: 150,
    filter: 'agNumberColumnFilter',
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "DriverPercentage",
    "headerName": "Limit Percentage",
    "width": 143,
    "filter": "agNumberColumnFilter",
    valueFormatter: PercentageFormatter.setPercentageFormat,
  },
  {
    "field": "PricingRate",
    "headerName": "Pricing Rate",
    "width": 110,
    "filter": "agNumberColumnFilter",
    valueFormatter: PercentageFormatter.setPercentageFormat,
  },
  {
    "field": "AdvanceRate",
    "headerName": "Advance Rate",
    "width": 120,
    "filter": "agNumberColumnFilter",
    valueFormatter: PercentageFormatter.setPercentageFormat,
  },
  {
    "field": "IsAvailableForFunding",
    "headerName": "Table Funding",
    "width": 125,
    valueFormatter: (params) => {
      if (params.value)
        return 'Y';
      else
        return 'N';
    }
  },
  {
    "field": "StartingOutstandingAmount",
    "headerName": "Start Out Amt (WET + DRY)",
    "width": 210,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "WetStartingOutstandingAmount",
    "headerName": "Start Out Amt (WET)",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "AmendedStartingOutstandingAmount",
    "headerName": "Amended Start Out Amt (WET + DRY)",
    "width": 260,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "AmendedWetStartingOutstandingAmount",
    "headerName": "Amended Start Out Amt (WET)",
    "width": 250,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "AvailableAmount",
    "headerName": "Available Amt (WET)",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    cellStyle: { 'text-align': 'right' },
  },
  {
    "field": "Comments",
    "headerName": "Comments",
    "filter": "agTextColumnFilter",
    "width": 300,
    tooltipField: 'Comments'
  },
  {
    "field": "action",
    "headerName": "",
    "width": 120,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
]
