
  <div>
    <label class="modal-body-label">You are about to export all loans for {{data.warehousename}}. All export and custodian files
    will be created.</label>
    <div *ngIf="data?.cutoffMessage == 1" class="alert alert-warning" role="alert" >
    <h3>
      Loans are funding past cutoff time for the Warehouse Line 
      {{data.federalCutoff}} PST. 
         Select EXPORT to fund today or select CANCEL to quit without funding.
    </h3>
    </div>

  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="modal-tile">
      <label>Loans</label>
      <label class="app-c-number">{{data.totalLoans}}</label>
    </div>
    <div class="modal-tile">
      <label>Wire Amount Total</label>
      <label class="app-c-number">{{data.wireAmountTotal | customCurrency}} </label>
    </div>
    <div class="modal-tile-third" style="display: none;">
      <div class="modal-tile-third-subtitle">
        <label>Remaining after Assigning</label>
        <label class="app-c-number">{{data.remaining | customCurrency}}</label>
      </div>
      <div class="modal-tile-fourth-subtitle">
        <span class="progress-span">{{data.percentage}}% Left</span>
        <div class="progress progress-bar-vertical">
          <div role="progressbar" [wlmsProgress]="data.percentage">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

