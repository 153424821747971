<div
  class="app-l-panel-closer"
  (click)="onClose()"
  *ngIf="slidePanelOpen"
></div>
<div class="app-l-slide-panel" [class.open-panel]="slidePanelOpen">
  <div class="app-l-slide-panel-content">
    <div class="app-l-slide-panel-close" (click)="onClose()">
      <i class="icon-arrow-right-double"></i>
    </div>

    <div class="app-l-panel-tabs">
      <wlms-tab-set
        [isLabelDisplayed]="false"
        [tabList]="tabList"
      ></wlms-tab-set>
      <wlms-loan-details
        *ngIf="tabList[selectedTab].name == 'info'"
        [selectedLoan]="selectedLoan" (optionSelected)="onWarehouselineSelected($event)"
      >
      </wlms-loan-details>
    </div>
  </div>
</div>
