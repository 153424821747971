import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@ldsm/utils';
import { ConfigService, ApiConfig } from '@wlms-web/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportService  extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  saveUserPreferences(request) {
    return this.post('@userServiceUrl/UserPreferencesForGrid', request);
  }

  getUserPreferences(userName, gridName) {
    return this.get('@userServiceUrl/UserPreferencesForGrid?UserName='+ userName + '&GridName=' + gridName, ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(gridName) {
    return this.get('@userServiceUrl/ColumnsForGridPreferences?GridName=' + gridName);
  }

  ResetUserPreference(request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };

    return this.delete('@userServiceUrl/UserPreferencesForGrid', options);
  }

  getJobReports(vendor, reportName, minReportDate, maxReportDate) {
    let url = '@fundingService/JobReport?IncludeFileContent=false'
    if(vendor){
      url += ('&Vendor=' + vendor);
    }

    if(reportName){
      url += ('&ReportName=' + reportName);
    }

    if(minReportDate){
      url += ('&MinReportDate=' + encodeURIComponent(minReportDate));
    }

    if(maxReportDate){
      url += ('&MaxReportDate=' + encodeURIComponent(maxReportDate));
    }
    return this.get(url, ApiConfig.DISABLELOANCACHE);
  }

  getReport(containerName, fileName){
    const url = '@fundingService/JobReport?ContainerName=' + containerName + '&FileName=' + fileName;
    return this.get(url, ApiConfig.DISABLELOANCACHE);
  }
}
