import { Component, OnInit, Input } from '@angular/core';
import { CustomCurrencyPipe } from '@wlms-web/utils';

@Component({
  selector: 'wlms-loan-info-tile',
  templateUrl: './loan-info-tile.component.html',
  styleUrls: ['./loan-info-tile.component.scss']
})
export class LoanInfoTileComponent implements OnInit {

  @Input() tileInfo: any;

  constructor() { }

  ngOnInit(): void { }

}
