<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-100">
    <div class="d-flex flex-row justify-content-space-between pt-4">
      <h3 class="app-c-label-export"></h3>
    </div>
  
    <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
      <wlms-grid
      #resultGrid
      class="resultGrid"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [paginationPageSize]="paginationPageSize" 
        [suppressActionPrivilege]="false"
        [suppressRightClickPrivilege]="false"
        [suppressFilter]="true"
      >
      </wlms-grid>
    </div>
  </div>
