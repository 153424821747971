import { Component, OnInit, Input } from '@angular/core';
import { DriveFrom, SnackbarConfig, SharedService } from '@wlms-web/utils';
import { UiHelperService } from '@wlms-web/ui-kit';

@Component({
  selector: 'wlms-manage-tranche',
  templateUrl: './manage-tranche.component.html',
  styleUrls: ['./manage-tranche.component.scss']
})
export class ManageTrancheComponent implements OnInit {

  driveFrom = [{ id: 0, name: 'None' }].concat([...Object.keys(DriveFrom).filter(value => isNaN(Number(value)) === false).
    map(key => { return { name: DriveFrom[key], id: parseInt(key) }; })]);
  driveFromConfig = DriveFrom;
  @Input() data: any;
  subLimitAmount: any;

  constructor(private uiHelperService: UiHelperService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.subLimitAmount = this.data.modalData.Limit;
    this.sharedService.modalSubmitValid = !this.isModalValid();
  }

  validate(ctrl) {
    if (ctrl == 'PricingMargin') {
      this.data.modalData.PricingMargin > 100 ?
        this.uiHelperService.showSnackBar('Pricing Margin should not be above 100%.', SnackbarConfig.error) :
        this.data.modalData.PricingRate = (Math.max(this.data.modalData.IndexRate ? this.data.modalData.IndexRate : 0,
          this.data.modalData.FloorRate ? this.data.modalData.FloorRate : 0) +
          (this.data.modalData.PricingMargin ? this.data.modalData.PricingMargin : 0)).toFixed(2);
    } else if (ctrl == 'AdvanceRate' && this.data.modalData.AdvanceRate > 100) {
      this.uiHelperService.showSnackBar('Advance Rate should not be above 100%.', SnackbarConfig.error);
    } else if (ctrl == 'DriverValue' && this.data.modalData.DriveFrom == DriveFrom.Limit && this.data.modalData.DriverValue > 100) {
      this.uiHelperService.showSnackBar('Sublimit Driver % should not be above 100%.', SnackbarConfig.error);
    } else if (ctrl == 'DriverValue' && this.data.modalData.DriveFrom == DriveFrom.Amount && this.data.modalData.DriverValue < 0) {
      this.uiHelperService.showSnackBar('Sublimit Driver Amount should not be below 0.', SnackbarConfig.error);
    } else if (ctrl == 'DriveFrom') {
      this.data.modalData.DriverValue = 0;
      this.resetSublimtValue();
    } else if (ctrl == 'Name' && this.data.modalData.Name == '') {
      this.uiHelperService.showSnackBar('Tranche Name is mandatory.', SnackbarConfig.error);
    } else if (ctrl == 'AmendedWetStartingOutstandingAmount') {
      this.setAmendments(ctrl);
    } else if (ctrl == 'AmendedStartOutAmt') {
      this.setAmendments(ctrl);
    } else if (ctrl == 'DriverValue') {
      this.resetSublimtValue();
    }

    this.sharedService.modalSubmitValid = !this.isModalValid();
  }

  setAmendments(ctrl) {
    if (ctrl == 'AmendedWetStartingOutstandingAmount') {
      this.data.modalData.AmendedStartOutAmt = this.data.modalData.AmendedStartOutAmt ? this.data.modalData.AmendedStartOutAmt :
        this.data.modalData.StartingOutStandingAmount;
      if (this.data.modalData.AmendedWetStartingOutstandingAmount < 0) {
        this.uiHelperService.showSnackBar('Wet Amended Starting Outstanding Amount should not be below 0.', SnackbarConfig.error);
      } else if (this.data.modalData.AmendedWetStartingOutstandingAmount > this.data.modalData.AmendedStartOutAmt) {
        this.uiHelperService.
          showSnackBar('Wet Amended Starting Outstanding Amount should be lesser than Amended Starting Outstanding Amount.',
            SnackbarConfig.error);
      }
    } else if (ctrl == 'AmendedStartOutAmt') {
      if (this.data.modalData.AmendedStartOutAmt < 0) {
        this.uiHelperService.showSnackBar('Amended Starting Outstanding Amount should not be below 0.', SnackbarConfig.error);
      } else if (this.data.modalData.AmendedWetStartingOutstandingAmount > this.data.modalData.AmendedStartOutAmt) {
        this.uiHelperService.
          showSnackBar('Wet Amended Starting Outstanding Amount should be lesser than Amended Starting Outstanding Amount.',
            SnackbarConfig.error);
      }
    }
  }

  isModalValid() {
    return (this.data.modalData.PricingMargin > 100 || this.data.modalData.AdvanceRate > 100 || this.data.modalData.Name == '' ||
      (this.data.modalData.DriveFrom == DriveFrom.Limit && this.data.modalData.DriverValue > 100) ||
      (this.data.modalData.DriveFrom == DriveFrom.Amount && this.data.modalData.DriverValue < 0) ||
      this.data.modalData.AmendedWetStartingOutstandingAmount < 0 || this.data.modalData.AmendedStartOutAmt < 0 ||
      this.data.modalData.AmendedWetStartingOutstandingAmount > this.data.modalData.AmendedStartOutAmt);
  }

  resetSublimtValue() {
    this.data.modalData.DriveFrom == DriveFrom.Amount ?
      this.subLimitAmount = this.data.modalData.DriverValue :
      this.data.modalData.DriveFrom == DriveFrom.Limit ?
        this.subLimitAmount = this.data.modalData.LineLimitAmount ?
          ((this.data.modalData.DriverValue / 100) * this.data.modalData.LineLimitAmount).toFixed(2) : 0 :
        this.subLimitAmount = 0;
  }

}

