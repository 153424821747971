<div>
    <form [formGroup]="reExportForm" novalidate>
        <div class="d-flex flex-row align-items-center ">
            <div class="d-flex flex-column align-items-start mt-2 w-50">
                <mat-form-field appearance="fill" class="form-field" [style.width.%]="90">
                    <mat-label>Warehouse Line</mat-label>
                    <mat-select formControlName="warehouseLineId" name="type"
                        (selectionChange)="enableExport(reExportForm?.value)">
                        <mat-option *ngFor="let warehouseLine of allWarehouseLineData"
                            [value]="warehouseLine.WarehouseLineId">
                            {{ warehouseLine?.WarehouseLineName }}
                        </mat-option>
                    </mat-select>
                    <mat-error>You must select a warehouse line</mat-error>
                </mat-form-field>
            </div>
            <div class="d-flex flex-column align-items-start mt-2 w-25">
                <div class="check-list">
                    <label class="cat-item d-flex align-items-center">
                        <mat-checkbox [disabled]="!hasCustodianFile" formControlName="includeCustodianFile"
                            (change)="enableExport(reExportForm?.value)">Include Custodian</mat-checkbox>
                    </label>
                </div>
            </div>
            <!-- <div class="d-flex flex-column align-items-start mt-2 w-25">
                <div class="check-list">
                    <label class="cat-item d-flex align-items-center">
                        <mat-checkbox formControlName="skipValidation" (change)="enableExport(reExportForm?.value)">
                            Validate DataTape</mat-checkbox>
                    </label>
                </div>
            </div> -->
        </div>

        <div class="d-flex flex-column align-items-start mt-2">
            <mat-form-field appearance="fill" class="w-100">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5" formControlName="loanNumber" id="loanNumber"
                    (change)="enableExport(reExportForm?.value)"></textarea>
                <mat-label>Loan Number</mat-label>
                <mat-error>Invalid input</mat-error>
                <mat-hint>Add loan numbers separated by comma</mat-hint>
            </mat-form-field>


        </div>


        <!-- <div class="d-flex flex-column align-items-start mt-2">
            <div class="check-list">
                <label class="cat-item d-flex align-items-center">
                    <mat-checkbox formControlName="skipEmpowerUpdates" (change)="enableExport(reExportForm?.value)">Empower updates</mat-checkbox>
                </label>
            </div>
        </div> -->
    </form>
</div>